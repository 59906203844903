import Select, {createFilter} from "react-select";
import {useState} from "react";
import Employees from "../../../classes/Employees";
import {filterConfig} from "../../utilities/FilterConfig";


const CrewFilter = ({crews, employees, setEmployees, selectedEmployeesEdit, setSelectedEmployeesEdit, phase, scheduleDate}) => {

    const [selectedCrew, setSelectedCrew] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState('')

    const handleCrewChanged = async (e) => {
        setSelectedEmployee('')
        setSelectedCrew(e)
        handleAddEmployee(e, '')
    }
    const handleEmployeeSelected = async (e) => {
        setSelectedEmployee(e)
        setSelectedCrew('')
        handleAddEmployee('', e)
    }
    const handleAddEmployee = async (crew, employee) => {
        let current = [...selectedEmployeesEdit]
        let existing
        if (crew !== null && crew !== '' && employee === '') {
            const firstValue = -1;
            const secondValue = -firstValue;
            crew.members.sort((m1, m2) => {
                return m1.position < m2.position ? firstValue : secondValue;
            })
            for (let m = 0; m < crew.members.length; m++) {
                let emp = employees.find(e => e.id.toString() === crew.members[m].employee.id.toString())
                if(emp.scheduled_off === true || emp.scheduled_off === 1){
                    //Do nothing, may decide to show this some other way depending on how this goes
                }
                else {
                    existing = current.filter(x => x.id.toString() === emp.id.toString())
                    if (existing === undefined || existing.length === 0) {
                        emp.approved = true
                        current.push(emp)
                    }
                }
            }
            setSelectedEmployeesEdit(current)
            if ((crew !== undefined && crew.members.length < 2)) {
                setSelectedCrew('')
                await handleLoadEmployees()
            }
        } else {
            if (employee !== null && employee !== '') {
                if(employee.scheduled_off === true || employee.scheduled_off === 1){
                    //Do nothing, may decide to show this some other way depending on how this goes
                }
                else {
                    existing = current.filter(x => x.id.toString() === employee.id.toString())
                    if (existing === undefined || existing.length === 0) {
                        employee.approved = true
                        current.push(employee)
                        setSelectedEmployeesEdit(current)
                    } else {
                        for (let c = 0; c < current.length; c++) {
                            if (current[c].id.toString() === employee.id.toString()) {
                                current[c].approved = true
                            }
                        }
                    }
                    setSelectedEmployee('')
                    if ((crew !== undefined && crew !== '' && crew.members.length < 2)) {
                        setSelectedCrew('')
                        await handleLoadEmployees()
                    } else {
                    }
                }
            }
        }
    }

    const handleLoadEmployees = async () => {
        let queryObj = phase !== undefined && phase !== null ? {[phase.phase.company.toLowerCase()]: 1} : null
        setEmployees(await Employees.loadEmployeesForDropDown(queryObj, scheduleDate.format('YYYY-MM-DD')))
    }
    const handleLoadCrews = async (phase) => {
        let queryObj = phase !== null ? {company: phase.phase.company} : null
       // setCrews(await Crews.loadCrewsForDropdown(queryObj))
    }

    return (
            <div style={{marginTop: "0px", paddingBottom: "1px"}}>
                <Select id="crew-filter-select-id"
                        className="crew-filter-select"
                        value={selectedCrew}
                        placeholder="Select A Crew"
                        onChange={(e) => handleCrewChanged(e)}
                        options={crews}
                        isClearable
                        isSearchable
                        isOptionDisabled={(option) => option.members.filter(x => x.employee.scheduled_off === 0 || x.employee.scheduled_off === false).length === 0}
                        filterOption={createFilter(filterConfig(true, true, true, true))}/>
                <Select id="employee-filter-select-id"
                        className="employee-filter-select"
                        value={selectedEmployee}
                        placeholder="Select An Employee"
                        onChange={(e) => handleEmployeeSelected(e)}
                        options={employees}
                        isOptionDisabled={(option) => option.scheduled_off === 1 || option.scheduled_off === true}
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig(false, true, true, true))}/>
            </div>
    )
}

export default CrewFilter;
