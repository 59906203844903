import axios from 'axios';
import {version, urlMaps } from '../../api';
import {API_ROOT} from "../../utilities/api";
import Config from "../../config"
import {logError} from "../../utilities/error";
import Utilities from "../../classes/Utilities";

class Auth {

    static login(user){
        // Save token to session
        localStorage.setItem('token', JSON.stringify({token: user.token}));
        localStorage.setItem('user_id', user.id)
        localStorage.setItem('username', JSON.stringify({username: user.username}));
        localStorage.setItem('firstName', JSON.stringify({firstName: user.firstname}));
        localStorage.setItem('lastName', JSON.stringify({lastName: user.lastname}));
        localStorage.setItem('email', user.email);
        localStorage.setItem('permission_group_id', user.permission_group_id);
        localStorage.setItem('permissions', JSON.stringify({permissions: user.permissions}));
        localStorage.setItem('position', JSON.stringify(user.position));
        localStorage.setItem('s3', JSON.stringify(user.s3));
    }

    static getToken(){
        // Grab and return token
        const tokenObj = JSON.parse(localStorage.getItem('token'))
        return tokenObj ? tokenObj.token : null;
    }

    static validateData = (data) => {
        // TODO Go through post-controller and direct errors to a central point with message
        if(data === null){
            logError("Data returned null, check CRUD operation");
            return null;
        }

        if(data.versionError){
            this.logout();
            const uri =  Config.BUILD_TYPE() === 'DEV' ? 'http://localhost:3000/wrong-version' : 'https://api.svcts.com/wrong-version';
            window.open(uri, "_self");
        }
        if(data.tokenError){
            logError("token error", data.tokenError)
            return null;
        }
        return data;
    }

    static async hitEndpoint(endpoint, urlIdentifier, queryString = "", dataIn = null, params = ""){

        let dataOut = null;
        const token = Auth.getToken()
        const headers = {
            headers: {
                version: version,
                // token: token,
                Authorization: 'Bearer ' + token
            }
        }
        let url = urlMaps[urlIdentifier]
        if(params !== "") {
            url += '/' + params
        }
        if(queryString !== ""){
            url += '?' + queryString
        }
        switch(endpoint){
            case "GET":
                switch(urlIdentifier){
                    case "WEATHER_CURRENT":
                        try {
                            dataOut = await axios(`https://api.weather.gov/stations/kphx/observations/latest?require_qc=true`);
                        }
                        catch(e){
                            dataOut = []
                        }
                        break;
                    case "WEATHER_FORECAST":
                        try {
                            dataOut = await axios(`https://api.weather.gov/gridpoints/PSR/158,56/forecast`);
                        }
                        catch(e){
                            dataOut = []
                        }
                        break;
                    case "STATS":
                        try {
                            dataOut = Auth.validateData((await axios.get(url, headers)).data);
                        }
                        catch(err){
                            console.log(err)
                            Auth.logout()
                        }
                        break;
                    default:
                        dataOut = Auth.validateData((await axios.get(url, headers)).data);
                        break;
                }
                break;
            case "POST":
                switch(urlIdentifier){
                    case "LOGIN":
                        dataOut = Auth.validateData((await axios.post(url, dataIn, headers)).data);
                        break;
                    case "PROSPECTIVE_EMPLOYEES":
                        // Interest form no validation
                        dataOut = (await axios.post(url, dataIn)).data;
                        break;
                    default:
                        dataOut = Auth.validateData((await axios.post(url, dataIn, headers)).data);
                        break;
                }
                break;
            case "PATCH":
                switch(urlIdentifier){
                    case "DATA":
                        dataOut = Auth.validateData((await axios.patch(urlMaps[urlIdentifier], dataIn, headers)).data);
                        break;
                    default:
                        const patchURL = queryString === "" ? `${urlMaps[urlIdentifier]}/${dataIn.id}` : `${urlMaps[urlIdentifier]}/${dataIn.id}?${queryString}`;
                        dataOut = Auth.validateData((await axios.patch(patchURL, dataIn, headers)).data);
                        break;
                }
                break;
            case "DELETE":
                dataOut = Auth.validateData((await axios.delete(`${url}/${dataIn.id}`, headers)).data);
                break;
            default:
                logError("ERROR: Bad endpoint in Auth.hitPoint()");
                break;
        }
        return dataOut;
    }

    static async hitEndpointNew(method, endpoint, queryString = "", dataIn = null, params = ""){
        let dataOut = null;
        const token = Auth.getToken()
        const headers = {
            headers: {
                version: version,
                //token: token,
                Authorization: 'Bearer ' + token
            }
        }
        let url = `${API_ROOT}/${endpoint}`
        if(params !== "") {
            url += '/' + params
        }
        if(queryString !== ""){
            url += '?' + queryString
        }
        switch(method){
            case "GET":
                switch(endpoint){
                    case "WEATHER_CURRENT":
                        try {
                            dataOut = await axios(`https://api.weather.gov/stations/kphx/observations/latest?require_qc=true`);
                        }
                        catch(e){
                            dataOut = []
                        }
                        break;
                    case "WEATHER_FORECAST":
                        try {
                            dataOut = await axios(`https://api.weather.gov/gridpoints/PSR/158,56/forecast`);
                        }
                        catch(e){
                            dataOut = []
                        }
                        break;
                    case "STATS":
                        dataOut = Auth.validateData((await axios.get(url, headers)).data);
                        break;
                    default:
                        dataOut = Auth.validateData((await axios.get(url, headers)).data);
                        break;
                }
                break;
            case "POST":
                switch(endpoint){
                    case "LOGIN":
                        dataOut = Auth.validateData((await axios.post(url, dataIn, headers)).data);
                        break;
                    case "PROSPECTIVE_EMPLOYEES":
                        // Interest form no validation
                        dataOut = (await axios.post(url, dataIn)).data;
                        break;
                    default:
                        dataOut = Auth.validateData((await axios.post(url, dataIn, headers)).data);
                        break;
                }
                break;
            case "PATCH":
                switch(endpoint){
                    case "DATA":
                        dataOut = Auth.validateData((await axios.patch(url, dataIn, headers)).data);
                        break;
                    default:
                        const patchURL = !Array.isArray(dataIn) ? queryString === "" ? `${url}/${dataIn.id}` : `${url}/${dataIn.id}?${queryString}` : `${url}`;
                        console.log(patchURL)
                        dataOut = Auth.validateData((await axios.patch(patchURL, dataIn, headers)).data);
                        break;
                }
                break;
            case "DELETE":
                if(dataIn.id !== undefined){
                    dataOut = Auth.validateData((await axios.delete(`${url}/${dataIn.id}`, headers)).data);
                }
                else{
                    dataOut = Auth.validateData((await axios.delete(`${url}`, headers)).data);
                }
                break;
            default:
                logError("ERROR: Bad endpoint in Auth.hitPoint()");
                break;
        }
        return dataOut;
    }

    static async getUserPermissionGroupId(){
        return localStorage.getItem('permission_group_id')
    }

    static async getUserId(){
        return localStorage.getItem('user_id')
    }

    static async getUserName(){
        return localStorage.getItem('username')
    }

    static async getFirstName(){
        return localStorage.getItem('firstName')
    }

    static async getLastName(){
        return localStorage.getItem('lastName')
    }

    static async getEmail(){
        return localStorage.getItem('email')
    }
    static async getS3() {
        const s3 = await JSON.parse(localStorage.getItem('s3'))
        return s3
    }

    static async getFullName(){
        let firstname = await JSON.parse(localStorage.getItem('firstName')).firstName
        let lastname = await JSON.parse(localStorage.getItem('lastName')).lastName
        return firstname + ' ' + lastname
    }

    static async getPermissions(){
        const data = JSON.parse(localStorage.getItem('permissions'))
        return data !== null ? data.permissions : []
    }

    static async getPosition(){
        const position = JSON.parse(localStorage.getItem('position'))
        return position
    }

    static logout(){

        // const { isAuthenticated, setIsAuthenticated, state, setState } = useContext(AppContext);
        // setIsAuthenticated(false)
        // setState(false)
        // Remove token from session storage
        localStorage.removeItem('token');
        localStorage.removeItem('s3');
        localStorage.removeItem('permissions');
        localStorage.removeItem('position');
        localStorage.removeItem('nav')
        localStorage.removeItem('appState');
    }
}

export default Auth;