import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from "react-select";
import {MaterialReactTable} from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from '@mui/material/Tooltip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import dayjs from 'dayjs';

const LotReleases = ({jobs, lotReleasesLots, setLotReleasesLots, updateLotsInLotsArray,
                        setOpenLotNotes, setLotToViewNotes, setLotNotesType}) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [phases, setPhases] = useState([]);
    const [startsStatus, setStartsStatus] = useState([]);

    const [lots, setLots] = useState([]);

    const [concreteMeasurementTemplate, setConcreteMeasurementTemplate] = useState([]);
    const [paverMeasurementTemplate, setPaverMeasurementTemplate] = useState([]);

    const gateColumns = useMemo(() => [
        {
            accessorFn: (row) => `${row.width} ${row.measurement_type.name}`,
            header: 'Width',
            size: 180
        },
        {
            accessorFn: (row) => row.handedness != null ? row.handedness.label : '',
            header: 'Hand',
            size: 150
        },
        {
            accessorFn: (row) => row.decorative_iron ? 'X' : '',
            header: 'Deco Iron',
            size: 150
        },
        {
            accessorFn: (row) => row.level != null ? row.level.label : '',
            header: 'Level',
            size: 150
        },
    ], []);

    const loadData = async () => {
        setDidLoadData(true);

        const tempStartsStatus = (await Auth.hitEndpointNew('GET', 'starts-status')).map(ss => {
            return {
                ...ss,
                value: ss.id,
                label: ss.display
            }
        });
        setStartsStatus(tempStartsStatus.sort((ss1, ss2) => parseInt(ss1) - parseInt(ss2)));

        const tempPhases = await Auth.hitEndpointNew('GET', 'phases');
        setPhases(tempPhases.sort((p1, p2) => parseInt(p1.stats_order_by) - parseInt(p2.stats_order_by)));

        const tempConcreteMeasurementTemplate = await Auth.hitEndpointNew("GET", "measurements/field/1");
        tempConcreteMeasurementTemplate.sort((t1, t2) => parseInt(t1.order_by) < parseInt(t2.order_by) ? -1 : 1);
        const tempPaverMeasurementTemplate = await Auth.hitEndpointNew("GET", "measurements/field/6");
        tempPaverMeasurementTemplate.sort((t1, t2) => parseInt(t1.order_by) < parseInt(t2.order_by) ? -1 : 1);

        setConcreteMeasurementTemplate([...tempConcreteMeasurementTemplate]);
        setPaverMeasurementTemplate([...tempPaverMeasurementTemplate]);

        const tempLots = [...lotReleasesLots];
        for(let i = 0; i < tempLots.length; i++){
            tempLots[i].tempPhases = [];
            for(let j = 0; j < tempPhases.length; j++){
                const currentPhase = tempLots[i].phases.find(p => parseInt(p.phase_id) === parseInt(tempPhases[j].id));
                if(currentPhase === undefined){
                    tempLots[i].tempPhases.push({
                        isModified: false,
                        lot_id: tempLots[i].id,
                        modified_at: new Date(),
                        modified_by: await Auth.getUserId(),
                        phase: tempPhases[j],
                        phase_id: tempPhases[j].id,
                        release_date: null,
                        start_status_id: null,
                        starts_status: null,
                    });
                }
                else{
                    tempLots[i].tempPhases.push({
                        isModified: false,
                        id: currentPhase.id,
                        lot_id: tempLots[i].id,
                        modified_at: currentPhase.modified_at !== null ? new Date(currentPhase.modified_at) : null,
                        modified_by: currentPhase.modified_by,
                        phase: currentPhase.phase,
                        phase_id: currentPhase.phase_id,
                        release_date: currentPhase.release_date !== null ? new Date(currentPhase.release_date) : null,
                        start_status_id: currentPhase.start_status_id,
                        starts_status: currentPhase.start_status_id !== null || currentPhase.starts_status !== '' ? tempStartsStatus.find(ss => {
                            return (parseInt(ss.id) === parseInt(currentPhase.start_status_id)) || (currentPhase.starts_status === ss.name);
                        }) : null,
                    })
                }
            }

            if(tempLots.length === 1){
                const currentJob = jobs.find(j => parseInt(j.id) === parseInt(tempLots[i].job.id));

                tempLots[i].hasConcrete = currentJob.attributes.find(att => parseInt(att.attribute_id) === 1) !== undefined;
                tempLots[i].hasPavers = currentJob.attributes.find(att => parseInt(att.attribute_id) === 10) !== undefined;
                tempLots[i].isPaversOpt = currentJob.attributes.find(att => parseInt(att.attribute_id) === 11) !== undefined;
                tempLots[i].lotHasPavers = tempLots[i].attributes.find(att => parseInt(att.attribute_id) === 10 || parseInt(att.attribute_id) === 11) !== undefined;
                tempLots[i].hasGates = currentJob.attributes.find(att => parseInt(att.attribute_id) === 12) !== undefined;

                // Add temp concrete measurements if job has concrete and they don't exist
                tempLots[i].tempMeasurements = [];
                let tempTotalConcrete = 0;
                if(tempLots[i].hasConcrete){
                    for(let k = 0; k < tempConcreteMeasurementTemplate.length; k++){
                        const cmtId = parseInt(tempConcreteMeasurementTemplate[k].id);
                        const currentMeasurement = tempLots[i].measurements.find(m => parseInt(m.measurement_id) === cmtId)
                        if(!currentMeasurement){
                            tempLots[i].tempMeasurements.push({
                                date: new Date(),
                                field_id: 1,
                                lot_id: tempLots[i].id,
                                measurement_id: cmtId,
                                user_id: await Auth.getUserId(),
                                value: 0
                            });
                        }
                        else{
                            tempLots[i].tempMeasurements.push({
                                date: currentMeasurement.date,
                                field_id: currentMeasurement.field_id,
                                lot_id: currentMeasurement.lot_id,
                                measurement_id: currentMeasurement.measurement_id,
                                user_id: currentMeasurement.user_id,
                                value: currentMeasurement.value
                            });
                        }

                        tempTotalConcrete += parseInt((tempLots[i].tempMeasurements.find(m => parseInt(m.measurement_id) === cmtId)).value);
                    }
                }
                
                // Add temp paver measurements if job has pavers or, on optional pavers, lot has pavers and they don't exist
                let tempTotalPavers = 0;
                if(tempLots[i].hasPavers){
                    if(!tempLots[i].isPaversOpt || (tempLots[i].isPaversOpt && tempLots[i].lotHasPavers)){
                        for(let k = 0; k < tempPaverMeasurementTemplate.length; k++){
                            const pmtId = parseInt(tempPaverMeasurementTemplate[k].id);
                            const currentMeasurement = tempLots[i].measurements.find(m => parseInt(m.measurement_id) === pmtId)
                            if(!currentMeasurement){
                                const value = pmtId === 13 || pmtId === 14 || pmtId === 15 ? '' : 0;
                                tempLots[i].tempMeasurements.push({
                                    date: new Date(),
                                    field_id: 6,
                                    lot_id: tempLots[i].id,
                                    measurement_id: pmtId,
                                    user_id: await Auth.getUserId(),
                                    value: value,
                                })
                            }
                            else{
                                tempLots[i].tempMeasurements.push({
                                    date: currentMeasurement.date,
                                    field_id: currentMeasurement.field_id,
                                    lot_id: currentMeasurement.lot_id,
                                    measurement_id: currentMeasurement.measurement_id,
                                    user_id: currentMeasurement.user_id,
                                    value: currentMeasurement.value
                                });
                            }

                            if(pmtId >= 7 && pmtId <= 12){
                                tempTotalPavers += parseInt((tempLots[i].tempMeasurements.find(m => parseInt(m.measurement_id) === pmtId)).value);
                            }
                        }
                    }
                }

                tempLots[i].totalConcrete = tempTotalConcrete;
                tempLots[i].totalPavers = tempTotalPavers;
            }
        }
        setLots(tempLots);
    }

    if(!didLoadData){
        loadData();
    }

    const closeWithSave = async () => {
        for(let i = 0; i < lots.length; i++){
            let filteredPhases = lots[i].tempPhases.filter(p => p.isModified)
            filteredPhases = filteredPhases.map(p => {
                delete p.isModified;
                delete p.label;
                delete p.value;
                delete p.phase;
                
                p.release_date = p.release_date !== null ? dayjs(p.release_date).format('YYYY-MM-DD H:mm:ss') : null;
                p.modified_at = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
                p.starts_status = p.starts_status !== null ? p.starts_status.name : '';
                return p;
            });
            const dbObj = {
                id: lots[i].id,
                phases: filteredPhases
            }
            const result = await Auth.hitEndpointNew("PATCH", "lot/no-processing", "", dbObj);

            for(let j = 0; j < result.phases.length; j++){
                const tempPhase = {
                    id: result.phases[j].id,
                    lot_id: result.phases[j].lot_id,
                    modified_at: new Date(result.phases[j].modified_at),
                    modified_by: result.phases[j].modified_by,
                    phase: phases.find(p => parseInt(p.id) === result.phases[j].phase_id),
                    phase_id: result.phases[j].phase_id,
                    release_date: result.phases[j].release_date !== null ? new Date(result.phases[j].release_date) : null,
                    start_status_id: result.phases[j].start_status_id,
                    starts_status: result.phases[j].starts_status,
                }
                const index = lots[i].phases.findIndex(p => parseInt(p.phase_id) === parseInt(tempPhase.phase_id));
                if(index >= 0){
                    lots[i].phases[index] = tempPhase;
                }
                else{
                    lots[i].phases.push(tempPhase);
                }
            }
        }
        setLotReleasesLots([]);
        updateLotsInLotsArray(lots);
    }

    const closeNoSave = () => {
        setLotReleasesLots([]);
    }

    if(lots.length === 0){
        return null;
    }

    return (
        <Dialog open={lots.length > 0} fullWidth={true} maxWidth={'md'}>
            {lots.length === 1 ? 
            <DialogTitle style={{borderBottom: "2px solid black"}}>Lot Information - Lot #{lots[0].number}</DialogTitle>
            : <DialogTitle style={{borderBottom: "2px solid black"}}>Lot Information - <span style={{color: '#c62828'}}>Multiple Lots Selected</span></DialogTitle>}
            <DialogContent sx={{minWidth: '600px'}}>
                <Grid
                    sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: '10px auto',
                    }}
                >Phase Releases</Grid>
                {phases.map((phase, index) => {
                    // This should always return a valid phase, either from DB or temp created in loadData function
                    const currentLotPhase = lots[0].tempPhases.find(p => parseInt(p.phase_id) === parseInt(phase.id));
                    currentLotPhase.value = phase.id;
                    currentLotPhase.label = phase.display;
                    return (
                        <Grid key={`phase-${index}`}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '150px 200px 200px 100px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '5px 0',
                            }}
                        >
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                    paddingRight: '10px'
                                }}
                            >{phase.stat_name}</Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={async (date) => {
                                        const receivedStatus = startsStatus.find(ss => ss.name === 'Received');
                                        for(let i = 0; i < lots.length; i++){
                                            const tempLots = [...lots];
                                            const index = tempLots[i].tempPhases.findIndex(p => parseInt(p.phase_id) === parseInt(phase.id));
                                            tempLots[i].tempPhases[index].isModified = true;
                                            tempLots[i].tempPhases[index].starts_status = tempLots[i].tempPhases[index].starts_status === null ? receivedStatus : tempLots[i].tempPhases[index].starts_status;
                                            tempLots[i].tempPhases[index].start_status_id = tempLots[i].tempPhases[index].starts_status.id;
                                            tempLots[i].tempPhases[index].release_date = new Date(date);
                                            tempLots[i].tempPhases[index].modified_at = new Date();
                                            tempLots[i].tempPhases[index].modified_by = await Auth.getUserId();
                                            setLots(tempLots);
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            sx: {
                                                width: '95%', 
                                                minWidth: '120px',
                                            },
                                        },
                                    }}
                                    defaultValue={dayjs(currentLotPhase.release_date)}
                                    selected={currentLotPhase.release_date}
                                />
                            </LocalizationProvider>
                            <Select 
                                styles={{
                                    control: styles => {
                                        return {
                                            ...styles,
                                            height: '40px',
                                        }
                                    }
                                }} 
                                placeholder="Select Starts Status"
                                value={currentLotPhase.starts_status}
                                onChange={async (startsStatus) => {
                                    if(!startsStatus){
                                        for(let i = 0; i < lots.length; i++){
                                            const tempLots = [...lots];
                                            const index = tempLots[i].tempPhases.findIndex(p => parseInt(p.phase_id) === parseInt(phase.id));
                                            tempLots[i].tempPhases[index].isModified = true;
                                            tempLots[i].tempPhases[index].start_status_id = null;
                                            tempLots[i].tempPhases[index].starts_status = null;
                                            tempLots[i].tempPhases[index].modified_at = new Date();
                                            tempLots[i].tempPhases[index].modified_by = await Auth.getUserId();
                                            tempLots[i].tempPhases[index].release_date = null;
                                            setLots([...tempLots]);
                                        }
                                        return;
                                    }
                                    for(let i = 0; i < lots.length; i++){
                                        const tempLots = [...lots];
                                        const index = tempLots[i].tempPhases.findIndex(p => parseInt(p.phase_id) === parseInt(phase.id));
                                        tempLots[i].tempPhases[index].isModified = true;
                                        tempLots[i].tempPhases[index].start_status_id = startsStatus.id;
                                        tempLots[i].tempPhases[index].starts_status = startsStatus;
                                        tempLots[i].tempPhases[index].modified_at = new Date();
                                        tempLots[i].tempPhases[index].modified_by = await Auth.getUserId();
                                        const rd = tempLots[i].tempPhases[index].release_date;
                                        tempLots[i].tempPhases[index].release_date = rd !== null ? rd : new Date();
                                        setLots(tempLots);
                                    }
                                }}
                                options={startsStatus}
                                isClearable
                                isSearchable
                            />
                            {lots.length === 1 ? 
                            <Grid sx={{paddingLeft: '10px'}}>
                                <Tooltip title="View/Edit Notes">
                                    <TextSnippetIcon 
                                        sx={{cursor: 'pointer'}}
                                        onClick={() => {
                                            setOpenLotNotes(true);
                                            setLotToViewNotes(lots[0]);
                                            setLotNotesType(phase.stat_name);
                                        }}/>
                                </Tooltip>
                            </Grid>
                            : null}
                        </Grid>
                    )
                })}
                {lots.length === 1 ? 
                <Grid>
                    <Grid
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: '20px auto 10px',
                        }}
                    >Concrete Measurements</Grid>
                    {lots[0].hasConcrete ? 
                    <Grid>
                        {concreteMeasurementTemplate.map((template, index) => {
                            const measurement = lots[0].tempMeasurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            return (
                                <Grid key={`concrete-measurement-${index}`}
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '180px 100px 100px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '5px 0',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            textAlign: 'right',
                                            paddingRight: '5px',
                                        }}
                                    >{template.description}</Grid>
                                    <Grid
                                        sx={{
                                            textAlign: 'right',
                                            paddingRight: '5px',
                                        }}
                                    >{measurement.value}</Grid>
                                    <Grid>{`sqft`}</Grid>
                                </Grid>
                            )
                        })}
                        <Grid
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '180px 100px 100px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '10px auto',
                            }}
                        >
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                    paddingRight: '5px',
                                    fontWeight: 'bold',
                                }}
                            >Total</Grid>
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                    paddingRight: '5px',
                                    fontWeight: 'bold',
                                }}
                            >{lots[0].totalConcrete}</Grid>
                            <Grid
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >{`sqft`}</Grid>
                        </Grid>
                    </Grid>
                    :<Grid sx={{textAlign: 'center'}}>
                        This lot does not have concrete service
                    </Grid>}
                    <Grid
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: '20px auto',
                        }}
                    >Paver Measurements & Information</Grid>
                    {lots[0].hasPavers && (!lots[0].isPaversOpt || (lots[0].isPaversOpt && lots[0].lotHasPavers)) ?
                    <Grid>
                        {paverMeasurementTemplate.map((template, index) => {
                            const measurement = lots[0].tempMeasurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            if(parseInt(template.id) >= 7 && parseInt(template.id) <= 12){
                                return (
                                    <Grid key={`paver-measurement-${index}`}
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '180px 100px 100px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '5px 0',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                textAlign: 'right',
                                                paddingRight: '5px',
                                            }}
                                        >{template.description}</Grid>
                                        <Grid
                                            sx={{
                                                textAlign: 'right',
                                                paddingRight: '5px',
                                            }}
                                        >{measurement.value}</Grid>
                                        <Grid>{`sqft`}</Grid>
                                    </Grid>
                                )
                            }
                            return null;
                        })}
                        <Grid
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '180px 100px 100px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '10px auto',
                            }}
                        >
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                    paddingRight: '5px',
                                    fontWeight: 'bold',
                                }}
                            >Total</Grid>
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                    paddingRight: '5px',
                                    fontWeight: 'bold',
                                }}
                            >{lots[0].totalPavers}</Grid>
                            <Grid
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >{`sqft`}</Grid>
                        </Grid>
                        {/* Commented out until color, style and PO input and use of data is relevent */}
                        {/* {paverMeasurementTemplate.map((template, index) => {
                            const information = lots[0].measurements.find(m => parseInt(m.measurement_id) === parseInt(template.id));
                            if(parseInt(template.id) === 13 || parseInt(template.id) === 14 || parseInt(template.id) === 15){
                                return (
                                    <Grid key={`paver-information-${index}`}
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '100px 100px 100px'
                                        }}
                                    >
                                        <Grid>{`${template.description}:`}</Grid>
                                        <Grid>{information.value}</Grid>
                                    </Grid>
                                )
                            }
                            return null;
                        })} */}
                    </Grid>
                    :<Grid sx={{textAlign: 'center'}}>
                        This lot does not have paver service
                    </Grid>}
                    <Grid
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: '20px auto',
                        }}
                    >Gates Information</Grid>
                    {lots[0].hasGates ?
                    <MaterialReactTable
                        columns={gateColumns}
                        data={lots[0].gate_information.filter(g => g.deleted_at === null)}
                        enableSorting={false}
                        enableColumnActions={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        layoutMode='semantic'
                        muiTableHeadCellProps={() => {
                            return {
                                align: 'center',
                                sx: {
                                    padding: '0',
                                    height: '35px',
                                    backgroundColor: 'rgb(180, 180, 180)',
                                }
                            }
                        }}
                        muiTableBodyCellProps={({row}) => {
                            return {
                                align: 'center',
                                sx: {
                                    height: '30px',
                                    padding: '0',
                                    backgroundColor: row.index % 2 === 0 ? 'rgb(200, 200, 200)' : 'rgb(230, 230, 230)'
                                }
                            }
                        }}
                        muiTableBodyRowProps={({row}) => {
                            return {
                                hover: false,
                            }
                        }}
                    />
                    :<Grid sx={{textAlign: 'center'}}>
                        This lot does not have gate service
                    </Grid>}
                </Grid> : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default LotReleases;
