import ReportsContainer from "./styles/ReportsContainer";
import MainHeader from "../utilities/MainHeader";
import SplitPane from "../utilities/styles/SplitPane";
import Split from "react-split";
import {List, ListItem} from "@mui/material";
import {useEffect, useState} from "react";
import Auth from "../authentication/Auth";
import Reports from "../../classes/Reports";
import EvenflowPage from "../evenflow/EvenflowPage";
import ScheduleReportPage from "./schedule/ScheduleReportPage";
import StartsReport from "./starts/StartsReport";
import WipCycleTime from "./Misc/WipCycleTime";
import WorkProjection from "./Misc/WorkProjection";
import UserLogin from "./Misc/UserLogin";
import MaterialUsageLogReport from "./Misc/MaterialUsageLogReport";
import GatesReport from "./Misc/GatesReport";
import CompletionReport from "./schedule/CompletionReport";
import Can from "../authentication/Can"
import Users from "../../classes/Users";


const ReportsPage = () => {

    const [reports, setReports] = useState([])
    const [selectedReport, setSelectedReport] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [didLoadData, setDidLoadData] = useState(false)
    const [userId, setUserId] = useState('')
    const [position, setPosition] = useState('')

    const [permissions, setPermissions] = useState([])

    const [allUsers, setAllUsers] = useState([])

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true)
            setDidLoadData(true);
            const user = await Auth.getUserId()
            setUserId(user)

            setAllUsers(await Users.loadUsers({}))
            const tempPermissions = await Auth.getPermissions()
            setPermissions(tempPermissions)

            const pos = await Auth.getPosition()
            setPosition(pos)
            let queryObj = {};
            if (pos !== null && pos.name === 'super') {
                queryObj['job_assignment.user_id'] = userId
            }

            let rpts = await Reports.loadReports('')
            //TODO comment this out when ready for deploy
            //setSelectedReport(rpts[0])
            setReports(rpts)
            setIsLoading(false)
        }

        if (!didLoadData) {
            loadData();
        }
    }, []);

    const handleReportSelected = (event, index) => {
        setSelectedReport(reports[index])
    }
    return (
        <ReportsContainer>
            <SplitPane>
                <Split sizes={[10, 90]} direction="horizontal" className="split">
                    <section className="pane sidebar">
                      <List style={{height: '99%', width: '90%', backgroundColor: 'white', marginLeft: '10px', }}>
                          {reports.map((r, index) => {
                              if((Can.isAuthorizedRead(permissions, r.permission) || Can.isAdmin(permissions)) && r.active) {
                                  return (
                                      <ListItem style={{cursor: 'pointer', backgroundColor: r.id === selectedReport.id ? 'yellow' : ''}} key={r.id} onClick={(event) => handleReportSelected(event, index)}>{r.display}</ListItem>
                                  )
                              }
                          })}
                      </List>
                    </section>
                    <section className="pane editor">
                        {selectedReport.name==="daily_schedule" && <ScheduleReportPage/>}
                        {selectedReport.name==="crew_schedule" && <ScheduleReportPage/>}
                        {selectedReport.name==="material_usage_log_report" && <div><MaterialUsageLogReport/></div>}
                        {selectedReport.name==="gates_report" && <div><GatesReport allUsers={allUsers}/></div>}
                        {selectedReport.name==="evenflow" && <div><EvenflowPage/></div>}
                        {selectedReport.name==="starts_report" && <div><StartsReport/></div>}
                        {/*{selectedReport.name==="wip_cycle_time" && <div><WipCycleTime/></div>}*/}
                        {selectedReport.name==="work_projection" && <div><WorkProjection/></div>}
                        {selectedReport.name==="user_login" && <div><UserLogin/></div>}
                        {selectedReport.name==="completion_report" && <div><CompletionReport/></div>}
                        {/*{selectedReport.name==="payouts" && <Payout/>}*/}
                        {/*{selectedReport.name==="timecards" && <Timecard/>}*/}
                    </section>
                </Split>
            </SplitPane>
        </ReportsContainer>
    )
}



export default ReportsPage;
