import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import EWAs from '../../classes/Ewas'
import Employees from '../../classes/Employees.js';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import Select, {createFilter} from "react-select";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import FieldMenu from "./FieldMenu";
import JobLotFilter from "./JobLotFilter";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import {filterConfig} from "../utilities/FilterConfig";

const EWAForm = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [ewa, setEWA] = useState(null);

    const [employeesForDropDown, setEmployeesForDropDown] = useState([]);

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const [phases, setPhases] = useState([]);
    const [ewaCodes, setEwaCodes] = useState([])

    const [selectedPhase, setSelectedPhase] = useState('')
    const [selectedEwaCode, setSelectedEwaCode] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState(null)

    const loadData = async () => {
        if (Utilities.isLoggedOut()) {
            const uri = Config.BASE_UI();
            window.location.replace(uri);
        } 
        else {
            setDidLoadData(true);
            setPhases(await Phases.loadPhases(null))
        }
    }
    
    if (!didLoadData) {
        loadData();
    }

    const resetPhaseCodeAndEmployeeDropDown = () => {
        setSelectedPhase(null)
        setSelectedEwaCode(null)
        setSelectedEmployee(null)
        const noteBox = document.getElementById('ew-form-job-description');
        if(noteBox !== null) {
            noteBox.value = '';
        }
        const internalEstimateBox = document.getElementById('ew-form-internal-estimate-number-input');
        if(internalEstimateBox !== null){
            internalEstimateBox.value = ''
        }
        const builderPoBox = document.getElementById('ew-form-builder-po-number-input');
        if(builderPoBox !== null){
            builderPoBox.value = ''
        }
    }

    const lotSelected = async (lot) => {
        if(lot === null){
            setEWA(null)
        }
        else {
            resetPhaseCodeAndEmployeeDropDown();
            const tempSelectedLot = lots.find(l => l.id.toString() === lot.value.toString());
            const tempNewEWA = await EWAs.createNewEWA(tempSelectedLot);
            tempNewEWA.lot_id = tempSelectedLot ? tempSelectedLot.id : null;
            setEWA(tempNewEWA);
        }
        return lot
    }

    const selectPhase = async (e) => {
        const phase = phases.find(phaseToFind => parseInt(phaseToFind.id) === parseInt(e.value))
        setSelectedPhase(e)
        ewa.line_items[0].phase_id = phase !== undefined ? phase.id : null

        setEwaCodes(await EWAs.loadEWACodes({"phase_task_phases.phase_id": e.value}))

        const company = phase !== undefined ? phase.company : null;
        let employees = []
        if(company === null){
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", null);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVC"){// 1 is SVC
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({concrete: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        else if(company === "SVP"){// 2 is SVP
            const tempEmp = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${JSON.stringify({plumbing: 1})}`);
            employees = Employees.convertEmployeesToDropDown(tempEmp);
        }
        setEmployeesForDropDown(employees)
    }

    const selectPhaseCode = async (e) => {
        setSelectedEwaCode(e)
        ewa.line_items[0].phase_task_id = e === null ? null : e.value
    }

    const selectEmployee = (selection) => {
        const tempEmployee = selection === null ? null : employeesForDropDown.find(employee => employee.id.toString() === selection.value.toString());
        if(tempEmployee === undefined || tempEmployee === null) {
            ewa.line_items[0].crew = {
                crew_id: null,
                employees: [{
                    employee_id: null,
                    is_lead: false,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        else{
            ewa.line_items[0].crew = {
                crew_id: tempEmployee.membership !== null ? tempEmployee.membership.crew_id : null,
                employees: [{
                    employee_id: tempEmployee.id,
                    is_lead: true,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                }]
            }
        }
        setSelectedEmployee(selection)
        setEWA({...ewa});
    }

    const setLineItem = (e, field, value) => {
        ewa.line_items[0][field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const setEWABillable = (e, field, value) => {
        ewa[field] = value === undefined || value === '' ? e.target.value : value
        setEWA({...ewa})
    }

    const handleSubmitEWA = async () => {
        let result = await EWAs.checkEWAForErrors(ewa, selectedEwaCode && selectedEwaCode.billable === 1);
        if(result !== "SUCCESS"){
            setError(result);
            return;
        }

        let number
        let ewaToSave = {...ewa}
        let scheduleYear = dayjs(new Date(ewa.schedule_date)).format('YYYY')
        let ewaCode = ewaCodes.find(codeToFind => codeToFind.id.toString() === ewa.line_items[0].phase_task_id.toString())
        ewaToSave.line_items[0].scheduled = true
        if(ewaCode !== undefined && ewaCode !== null) {
            let company = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.company : 'SVC'
            let suffix = selectedPhase !== undefined && selectedPhase !== '' ? selectedPhase.ewa_suffix : 'ALL'
            number = await EWAs.getNextNumber(company, suffix, scheduleYear, number)
            ewaToSave.number = number.ewa_number
        }

        await EWAs.saveNewEWA(ewaToSave);
        resetPhaseCodeAndEmployeeDropDown();
        let tempNewEwa = await EWAs.createNewEWA(selectedLot);
        setEWA(tempNewEwa)
        setSuccess(true);
        setError('');
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <JobLotFilter
                title={'Extra Work Authorization'}
                jobs={jobs}
                setJobs={setJobs}
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                lots={lots}
                setLots={setLots}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
                lotSelected={lotSelected}
            />
            {(selectedLot && ewa) && 
            <Box
                sx={{
                    background: 'rgb(210, 200, 190)',
                    padding: '0 0 15px',
                    margin: '3px 0 100px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderBottom: '3px solid white'
                    }}
                >Extra Work Authorization Form</Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Phase:</Box>
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    gridColumn: '5/20',
                                    zIndex: '49'
                                }
                            }
                        }} 
                        placeholder={'Select Phase'}
                        value={selectedPhase}
                        onChange={(phase) => selectPhase(phase)}
                        options={phases.map(phase => {
                            return (
                                {
                                    value: phase.id,
                                    label: `${phase.name}`,
                                    phase: phase,
                                }
                            )
                        })}
                        filterOption={createFilter(filterConfig(false, true, true, true))}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Code:</Box>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "ERROR_NO_PHASE_CODE" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            },
                            container: styles => {
                                return {
                                    ...styles,
                                    gridColumn: '5/20',
                                    zIndex: '48'
                                }
                            }
                        }} 
                        placeholder={'Select Code'}
                        value={selectedEwaCode}
                        onChange={(phaseCode) => selectPhaseCode(phaseCode)}
                        options={ewaCodes.map(code => {
                            return (
                                {
                                    value: code.id,
                                    label: `${code.code} - ${code.description}`,
                                    billable: code.billable,
                                    code: code,
                                }
                            )
                        })}
                        filterOption={createFilter(filterConfig(false, true, true, true))}
                    />
                </Box>
                {(selectedEwaCode && selectedEwaCode.billable === 1) &&
                <Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(20, 1fr)',
                            alignItems: 'center',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                gridColumn: '2/5',
                                alignSelf: 'center',
                                textAlign: 'end',
                                paddingRight: '5px',
                            }}
                        >Int Est:</Box>
                        <TextField
                            id="internal-estimate-id"
                            sx={{
                                gridColumn: '5/20',
                                backgroundColor: error === "ERROR_NO_INTERNAL_ESTIMATE" ? 'rgb(255, 150, 150)' : 'white',
                            }}
                            size='small'
                            placeholder='Enter Internal Estimate'
                            value={ewa.line_items[0].internal_estimate_number}
                            onClick={() => document.getElementById('internal-estimate-id').select()}
                            onChange={(e) => setEWABillable(e, "internal_estimate_number")}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(20, 1fr)',
                            alignItems: 'center',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                gridColumn: '1/5',
                                alignSelf: 'center',
                                textAlign: 'end',
                                paddingRight: '5px',
                            }}
                        >Bldr PO:</Box>
                        <TextField
                            id="builder-po-id"
                            sx={{
                                gridColumn: '5/20',
                                backgroundColor: error === "ERROR_NO_BUILDER_PO" ? 'rgb(255, 150, 150)' : 'white',
                            }}
                            size='small'
                            placeholder='Enter Builder PO'
                            value={ewa.line_items[0].builder_po_number}
                            onClick={() => document.getElementById('builder-po-id').select()}
                            onChange={(e) => setEWABillable(e, "builder_po_number")}
                        />
                    </Box>
                </Box>}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Notes:</Box>
                    <TextField
                        id="ewa-description-id"
                        sx={{
                            gridColumn: '5/20', 
                            backgroundColor: error === "ERROR_NO_DESCRIPTION" ? 'rgb(255, 150, 150)' : 'white',
                        }}
                        placeholder='Enter EWA Description'
                        multiline
                        rows='4'
                        value={ewa.line_items[0].notes}
                        onChange={(e) => setLineItem(e, "notes")}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Date:</Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setLineItem(null, "schedule_date", date)}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    size: 'small',
                                    sx: {
                                        gridColumn: '5/20',
                                        backgroundColor: error === "ERROR_NO_SCHEDULED_DATE" ? 'rgb(255, 150, 150)' : 'white',
                                    },
                                    field: { clearable: true },
                                },
                            }}
                            value={ewa.line_items.length > 0 && ewa.line_items[0].schedule_date !== null ? dayjs(ewa.line_items[0].schedule_date) : null}
                            selected={ewa.line_items[0].schedule_date}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Payout:</Box>
                    <TextField
                        id="payout-id"
                        sx={{
                            gridColumn: '5/20',
                            backgroundColor: error === "ERROR_NO_PAYOUT_AMOUNT" ? 'rgb(255, 150, 150)' : 'white',
                        }}
                        type='number'
                        size='small'
                        value={ewa.line_items[0].payout}
                        onClick={() => document.getElementById('payout-id').select()}
                        onChange={(e) => setLineItem(e, "payout")}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '1/5',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px',
                        }}
                    >Emp:</Box>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "ERROR_NO_EMPLOYEE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            },
                            container: styles => {
                                return {
                                    ...styles,
                                    gridColumn: '5/20',
                                    zIndex: '47'
                                }
                            }
                        }} 
                        placeholder={'Select Employee'}
                        value={selectedEmployee}
                        onChange={(employee) => selectEmployee(employee)}
                        options={employeesForDropDown.map(emp => {
                            return (
                                {
                                    value: emp.id,
                                    label: emp.display,
                                    employee: emp,
                                }
                            )
                        })}
                        filterOption={createFilter(filterConfig(false, true, true, true))}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        justifyContent: 'center',
                        margin: '20px 0 10px'
                    }}
                >
                    <Button variant='contained' onClick={handleSubmitEWA}>Submit Extra Work Authorization</Button>
                </Box>
            </Box>}
            {success &&
            <Dialog open={success}>
                <DialogTitle sx={{backgroundColor: 'rgb(150, 255, 150)', fontWeight: 'bold'}}>Success</DialogTitle>
                <DialogContent 
                    sx={{width: "350px", backgroundColor: 'rgb(150, 255, 150)'}}>Extra Work Authorization Form saved successfully</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(150, 255, 150)'}}>
                    <Button 
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => setSuccess(false)}
                    >OK</Button>
                </DialogActions>
            </Dialog>}
        </FieldFormsContainer>
    )
};

export default EWAForm;