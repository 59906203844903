import {useState} from 'react';
import dayjs from "dayjs";
import Auth from '../../authentication/Auth.js';
import { Box } from '@mui/system';
import Select from "react-select";
import Reports from '../../../classes/Reports';
import Button  from "@mui/material/Button";
import ReporterContainer from '../styles/ReporterContainer';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '20mm',
        backgroundColor: 'white',
        width: '280mm',
        height: '216mm'
      },
    companyInfo: {
        margin: '7mm auto 0',
        fontSize: '10mm',
        width: '55%',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        borderBottom: '1mm solid black'
    },
    docTitle: {
        margin: '3mm auto 0',
        padding: '2mm 0',
        fontSize: '8mm',
        width: '40%',
        textAlign: 'center',
        fontFamily: 'Helvetica',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '170mm',
        width: '100%',
        fontSize: '3mm',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',
    },
    columns: {
        marginLeft: '.5mm',
        marginRight: '.5mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        fontFamily: 'Helvetica',
        fontSize: '3mm',
        textAlign: 'center',
    },
});

const CompletionReport = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);

    const [reports, setReports] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);
    const [phaseTasks, setPhaseTasks] = useState([]);

    const [report, setReport] = useState([]);

    const [filters, setFilters] = useState({
        // Required
        reports: [],
        startDate: null,
        endDate: null,
        includeEWAs: true,
        // Optional
        job: null,
        lot: null,
        phaseTask: null
    });

    const [error, setError] = useState('');

    const [button, setButton] = useState({
        text: 'Create Completion Report',
        disabled: false
    });

    const loadData = async () => {
        setDidLoadData(true);
        
        const tempReports = (await Reports.loadScheduleReports()).map(r => {
            return {
                ...r,
                value: r.id,
                label: r.display
            }
        });
        setReports(tempReports);

        const today = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
        let tempStartDate = null;
        let tempEndDate = null;
        if(today.get('day') !== 6){
            tempStartDate = dayjs(today).set('date', today.get('date') - (today.get('day') + 1))
        }
        tempEndDate = dayjs(tempStartDate).set('date', tempStartDate.get('date') + 7);
        setFilters({
            ...filters,
            startDate: tempStartDate,
            endDate: tempEndDate
        })

        const tempUsers = await Auth.hitEndpointNew('GET', 'users');
        setUsers([...tempUsers]);

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const handleReportsSelect = async (reports) => {
        if(reports.length === 0){
            setFilters({...filters, reports: [], phaseTask: null});
            setPhaseTasks([])
            return;
        }

        setReport([]);

        setButton({
            text: 'Please Wait. Loading Data...',
            disabled: true
        });

        let tempPhaseTasks = [];
        for(let i = 0; i < reports.length; i++){
            const dbPhaseTasks = await Auth.hitEndpointNew('GET', 'phase-tasks-new', `jsonString=schedule_report_id=${reports[i].id}`);
            if(dbPhaseTasks){
                tempPhaseTasks = [...tempPhaseTasks, ...dbPhaseTasks]
            }
        }
        setPhaseTasks(tempPhaseTasks.map(pt => {
            return {
                ...pt,
                value: pt.id,
                label: pt.code + " - " + pt.name
            }
        }));
        setFilters({...filters, reports: reports, phaseTask: null});

        setButton({
            text: 'Create Completion Report',
            disabled: false
        });
    }

    const handleJobSelect = async (job) => {
        if(job === null){
            setFilters({...filters, job: null, lot: null});
            return;
        }

        setReport([]);

        setButton({
            text: 'Please Wait. Loading Data...',
            disabled: true
        });

        const tempLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=lot.job_id=${job.id}`);
        setLots(tempLots.map(l => {
            return {
                ...l,
                value: l.id,
                label: `${l.number} - ${l.phase} - ${l.address}`
            }
        }));
        setFilters({...filters, job: job});

        setButton({
            text: 'Create Completion Report',
            disabled: false
        });
    }

    const getNameFromId = (id) => {
        const user = users.find(u => parseInt(u.id) === parseInt(id));
        return user ? `${user.firstname} ${user.lastname}` : ''
    }

    const createCompletionReport = async () => {
        setButton({
            text: 'Please Wait. Fetching Report...',
            disabled: true
        });

        if(filters.reports.length === 0){
            setError('REPORT_NOT_SELECTED');
            return;
        }
        if(filters.startDate === null){
            setError('START_DATE_NOT_SELECTED');
            return;
        }
        if(filters.endDate === null){
            setError('END_DATE_NOT_SELECTED');
            return;
        }

        setError('');

        let queryStr = 'queryString=schedule.complete=1';
        queryStr += ` and (schedule.completed_date between '${filters.startDate.format('YYYY-MM-DD')}' and '${filters.endDate.format('YYYY-MM-DD')}')`
        if(filters.phaseTask === null){
            for(let i = 0; i < phaseTasks.length; i++){
                if(i === 0){
                    queryStr += ' and (';
                }
                else{
                    queryStr += ' or ';
                }
                queryStr += 'schedule.phase_task_id=' + phaseTasks[i].id;
                if(i + 1 === phaseTasks.length){
                    queryStr += ')'
                }
            }
        }
        else{
            queryStr += ' and schedule.phase_task_id=' + filters.phaseTask.id;
        }

        if(!filters.includeEWAs){
            queryStr += ` and phase_task.type!='ewa'`
        }
        if(filters.job !== null){
            queryStr += ` and lot.job_id = ${filters.job.id}`
        }
        if(filters.lot !== null){
            queryStr += ` and lot.id = ${filters.lot.id}`
        }

        const dbData = await Auth.hitEndpointNew("GET", `schedule/report/index-new`, queryStr);
        const reportData = dbData.sort((d1, d2) => {
            if(parseInt(d1.lot.job.number) === parseInt(d2.lot.job.number)){
                if(parseInt(d1.lot.number) === parseInt(d2.lot.number)){
                    return d1.lot.phase < d2.lot.phase ? -1 : 1;
                }
                return parseInt(d1.lot.number) < parseInt(d2.lot.number) ? -1 : 1;
            }
            return parseInt(d1.lot.job.number) < parseInt(d2.lot.job.number) ? -1 : 1;
        }).map(d => {
            return {
                id: d.id,
                jobNumber: d.lot.job.number,
                lotNumber: d.lot.phase ? `${d.lot.number} - ${d.lot.phase}` : d.lot.number,
                projectName: d.lot.job.project_name,
                phaseTask: `${d.task.code ? d.task.code : ''}${d.task.code && d.task.name ? ' - ' : ''}${d.task.name ? d.task.name : ''}`,
                isEWA: d.task.type === 'ewa' ? 'X' : '',
                scheduledBy: getNameFromId(d.scheduled_by),
                scheduledOn: dayjs(d.schedule_date).format('MM-DD-YYYY'),
                completedBy: getNameFromId(d.completed_by),
                completedOn: dayjs(d.completed_date).format('MM-DD-YYYY'),
            }
        })
        setReport(reportData);

        setButton({
            text: 'Create Completion Report',
            disabled: false
        });
    }

    return (
        <ReporterContainer>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '830px',
                    marginLeft: '20px',
                    border: '2px solid rgb(220, 220, 220)'
                }}
            >
                <Box 
                    sx={{ 
                        display: 'grid',
                        width: '400px',
                        borderRadius: '3px',
                        marginBottom: '10px'
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '18px',
                            textAlign: 'center',
                            margin: '10px 0'
                        }}
                    >
                        Required Fields
                    </Box>
                    <Select
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "REPORT_NOT_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            },
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '80%',
                                    justifySelf: 'center',
                                    margin: '5px 0',
                                    zIndex: '49'
                                }
                            },
                        }}
                        placeholder="Select Report"
                        value={filters.reports}
                        onChange={handleReportsSelect}
                        options={reports}
                        isClearable
                        isSearchable
                        isMulti
                    />
                    <Box 
                        style={{
                            justifySelf: 'center',
                            width: '80%',
                            marginBottom: "5px",
                        }}
                    >Start Date</Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => {
                                setReport([]);
                                setFilters({...filters, startDate: date})
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    size: 'small',
                                    sx: {
                                        justifySelf: 'center',
                                        width: '80%', 
                                        minWidth: '120px',
                                        backgroundColor: error === "START_DATE_NOT_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                    },
                                },
                            }}
                            value={filters.startDate}
                        />
                    </LocalizationProvider>
                    <Box 
                        style={{
                            justifySelf: 'center',
                            width: '80%',
                            margin: "5px 0",
                        }}
                    >End Date</Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => {
                                setReport([]);
                                setFilters({...filters, endDate: date})
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    size: 'small',
                                    sx: {
                                        justifySelf: 'center',
                                        width: '80%', 
                                        minWidth: '120px',
                                        backgroundColor: error === "END_DATE_NOT_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                    },
                                },
                            }}
                            value={filters.endDate}
                        />
                    </LocalizationProvider>
                </Box>
                <Box 
                    sx={{ 
                        display: 'grid',
                        width: '400px',
                        borderRadius: '3px',
                        marginBottom: '10px'
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '18px',
                            textAlign: 'center',
                            margin: '10px 0'
                        }}
                    >
                        Optional Fields
                    </Box>
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '80%',
                                    justifySelf: 'center',
                                    margin: '5px 0',
                                    zIndex: '48'
                                }
                            },
                        }}
                        placeholder="Select Job"
                        value={filters.job}
                        onChange={handleJobSelect}
                        options={jobs}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '80%',
                                    justifySelf: 'center',
                                    margin: '5px 0',
                                    zIndex: '47'
                                }
                            },
                        }}
                        placeholder="Select Lot"
                        value={filters.lot}
                        onChange={(lot) => {
                            setReport([]);
                            setFilters({...filters, lot: lot})
                        }}
                        options={lots}
                        isClearable
                        isSearchable
                    />
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '80%',
                                    justifySelf: 'center',
                                    margin: '5px 0',
                                    zIndex: '46'
                                }
                            },
                        }}
                        placeholder="Select Phase Task"
                        value={filters.phaseTask}
                        onChange={(phaseTask) => {
                            setReport([]);
                            setFilters({...filters, phaseTask: phaseTask})
                        }}
                        options={phaseTasks}
                        isClearable
                        isSearchable
                    />
                </Box>
                <FormControlLabel 
                        sx={{
                            gridColumn: '1/3',
                            justifySelf: 'center',
                            margin: '5px 0'
                        }}
                        control={
                            <Switch 
                                defaultChecked
                                value={filters.includeEWAs}
                                onChange={(e) => {
                                    setReport([]);
                                    setFilters({...filters, includeEWAs: e.target.checked})
                                }}
                            />} 
                        label="Include EWA's" 
                    />
                <Button 
                    sx={{
                        gridColumn: '1/3',
                        justifySelf: 'center',
                        margin: '10px auto 15px'
                    }}
                    disabled={button.disabled}
                    variant='contained'
                    onClick={createCompletionReport}
                >{button.text}</Button>
            </Box>
            <PDFViewer style={styles.viewer}>
                <Document title="Completion Report">
                    <Page orientation="landscape" size="LETTER" style={styles.page} wrap>
                        <View style={styles.companyInfo} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.docTitle} fixed>
                            <Text>Completion Report</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '15mm'}]}>Job #</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '30mm'}]}>Lot #</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '40mm'}]}>Project</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '50mm'}]}>Phase Task</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '10mm'}]}>EWA</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '35mm'}]}>Scheduled By</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '25mm'}]}>Scheduled On</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '35mm'}]}>Completed By</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '25mm'}]}>Completed On</Text>
                        </View>
                        {report.map((r, index) => {
                            const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';
                            const isEWAColor = r.isEWA === '' ? color : 'rgb(99, 202, 228)'
                            return (
                                <View key={`${r.id}-${index}`} style={styles.tableRow} wrap={false}>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '15mm'}]}>{r.jobNumber}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '30mm'}]}>{r.lotNumber}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '40mm'}]}>{r.projectName}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '50mm'}]}>{r.phaseTask}</Text>
                                    <Text style={[styles.columns, {backgroundColor: isEWAColor, width: '10mm'}]}>{r.isEWA}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '35mm'}]}>{r.scheduledBy}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '25mm'}]}>{r.scheduledOn}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '35mm'}]}>{r.completedBy}</Text>
                                    <Text style={[styles.columns, {backgroundColor: color, width: '25mm'}]}>{r.completedOn}</Text>
                                </View>
                            )
                        })}
                    </Page>
                </Document>
            </PDFViewer>
        </ReporterContainer>
    );
}

export default CompletionReport;