import {useEffect, useMemo, useState} from 'react';
import moment from "moment/moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Builders from '../../classes/Builders.js';
import Jobs from '../../classes/Jobs.js';
import Lots from '../../classes/Lots.js';
import EWAs from '../../classes/Ewas';
import Employees from '../../classes/Employees.js';
import Auth from '../authentication/Auth.js';
import Can from '../authentication/Can.js'
import { Button } from '../utilities/Button.js';
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable';
import svcLogo from './../../img/HeaderLogo.png'
import { LoadingMessage } from '../utilities/LoadingMessage';
import Popup, {PopupFailureColor, PopupWarningColor, PopupSuccessColor} from '../utilities/Popup.js';
import PopupOk, {PopupOkWarningColor} from '../utilities/PopupOk';

import EWAListContainer from './styles/EWAListContainer';
import Globals from '../utilities/Globals.js';
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import printjs from 'print-js';
import {Box, darken, IconButton, lighten, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";

import EWA from "./EWA"

import {AddCircle, CheckCircle, Clear, Download, Edit, Info, Print, ThumbDown, ThumbUp} from "@mui/icons-material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Select, {createFilter} from "react-select";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Ewas from "../../classes/Ewas";
import TextField from "@mui/material/TextField";
import Crews from "../../classes/Crews";
import {filterConfig} from "../utilities/FilterConfig";

const EWAList = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [userId, setUserId] = useState()
    const [user, setUser] = useState()

    const [filterOptions, setFilterOptions] = useState({
        jobId: '',
        lotId: '',
        company: '',
        employeeId: '',
        approvalStatus: '',
        completedStatus: '',
        dateType: '',
        startDate: null,
        endDate: null
    });
    //Filter variables
    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);
    const [selectedJob, setSelectedJob] = useState('')
    const [lotsForFilterDropDown, setLotsForFilterDropDown] = useState([]);
    const [selectedLot, setSelectedLot] = useState('')
    const [companiesForDropDowns, setCompaniesForDropDowns] = useState([
        {value: "1", label: "SVC"},
        {value: "2", label: "SVP"},
        {value: "3", label: "SVF"}
    ])
    const [selectedCompany, setSelectedCompany] = useState('')
    const [selectedPhase, setSelectedPhase] = useState('')
    const [crewsForFilterDropDown, setCrewsForFilterDropDown] = useState([]);
    const [employeesForFilterDropDown, setEmployeesForFilterDropDown] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [selectedCrew, setSelectedCrew] = useState('')

    const [approvalStatusForDropDowns, setApprovalStatusForDropDowns] = useState([
        {value: "Needs Review", label: "Needs Review"},
        {value: "Approved", label: "Approved"},
        {value: "Declined", label: "Declined"}
    ])
    const [selectedApprovalStatus, setSelectedApprovalStatus] = useState('')
    const [completionStatusForDropDowns, setCompletionStatusForDropDowns] = useState([
        {value: "Completed", label: "Completed"},
        {value: "Not Completed", label: "Not Completed"}
    ])
    const [selectedCompletionStatus, setSelectedCompletionStatus] = useState('')
    const [selectedDateFilterType, setSelectedDateFilterType] = useState('')

    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })

    const [phases, setPhases] = useState([]);
    const [ewas, setEWAs] = useState([]);
    const [openEWA, setOpenEWA] = useState(false);
    const [editEWA, setEditEWA] = useState({});
    const [approvedEWA, setApprovedEWA] = useState(null);
    const [declinedEWA, setDeclinedEWA] = useState(null);
    const [completedEWA, setCompletedEWA] = useState(null);

    const [ewasToPrint, setEWAsToPrint] = useState([]);
    const [reprintEWA, setReprintEWA] = useState(false);

    const [allUsers, setAllUsers] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);

    const [displayLoadingMessage, setDisplayLoadingMessage] = useState(false);

    const [warningNoLotsFromSelectedFilters, setWarningNoLotsFromSelectedFilters] = useState(false);
    const [errorNoFiltersSelected, setErrorNoFiltersSelected] = useState(false);
    const [errorsInNewEWA, setErrorsInNewEWA] = useState(false);
    const [errorNoLotSelected, setErrorNoLotSelected] = useState(false);
    const [errorNoPhaseCodeSelected, setErrorNoPhaseCodeSelected] = useState(false);
    const [errorNoJobDescription, setErrorNoJobDescription] = useState(false);
    const [errorNoInternalEstimateOrBuilderPO, setErrorNoInternalEstimateOrBuilderPO] = useState(false);
    const [errorNoScheduledDate, setErrorNoScheduledDate] = useState(false);
    const [errorNoAmountEntered, setErrorNoAmountEntered] = useState(false);
    const [errorNoEmployeeSelected, setErrorNoEmployeeSelected] = useState(false);

    const [newEWAsuccess, setNewEWASuccess] = useState(false);
    const [editEWAsuccess, setEditEWASuccess] = useState(false);

    const [canPrintFirstCopy, setCanPrintFirstCopy] = useState(false) //Can.isAuthorized('ewa', 'WRITE');
    const [canCreateEditEWA, setCanCreateEditEWA] = useState(false) //Can.isAuthorized('ewa', 'WRITE');
    const [canReprintEWA, setCanReprintEWA] = useState(false) //Can.isAuthorized('ewaReprint', 'WRITE');
    const [canApproveEWA, setCanApproveEWA] = useState(false) //Can.isAuthorized('ewaApprove', 'WRITE');
    const [canCompleteEWA, setCanCompleteEWA] = useState(false) //Can.isAuthorized('ewaComplete', 'WRITE');

    const togglePopup = () => {
        setWarningNoLotsFromSelectedFilters(false);
        setErrorNoFiltersSelected(false);
        setErrorsInNewEWA(false);
        setErrorNoLotSelected(false);
        setErrorNoPhaseCodeSelected(false);
        setErrorNoJobDescription(false);
        setErrorNoInternalEstimateOrBuilderPO(false);
        setErrorNoScheduledDate(false);
        setErrorNoAmountEntered(false);
        setErrorNoEmployeeSelected(false);
        setNewEWASuccess(false);
        setEditEWASuccess(false);
        setOpenEWA(false);
    }

    const getEmployeeName = (id) => {
        const employee = allEmployees.find(employee => employee.id === id);
        let rtn = employee ? `${employee.alias !== null ? employee.alias : ''} ${employee.first_name} ${employee.last_name}` : '';
        return rtn.trim()
    }

    const getUserName = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname} ${user.lastname}` : '';
    }

    const getUserInitals = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname[0]}.${user.lastname[0]}.` : '';
    }

    const loading = (isLoading) => {
        setDisplayLoadingMessage(isLoading);
    }

    const applyFilters = async () => {
        loading(true);
        
        if(
            (filterOptions.jobId === undefined || filterOptions.jobId === null || filterOptions.jobId.toString() === '') &&
            (filterOptions.lotId === undefined ||  filterOptions.lotId === null || filterOptions.lotId === '') &&
            (filterOptions.company === undefined || filterOptions.company === null || filterOptions.company === '') &&
            (filterOptions.phaseId === undefined || filterOptions.phaseId === null || filterOptions.phaseId === '') &&
            (filterOptions.employeeId === undefined || filterOptions.employeeId === null || filterOptions.employeeId === '') &&
            (filterOptions.crewId === undefined || filterOptions.crewId === null || filterOptions.crewId === '') &&
            filterOptions.approvalStatus === '' &&
            filterOptions.completedStatus === '' &&
            filterOptions.startDate === null &&
            filterOptions.endDate === null){
            setErrorNoFiltersSelected(true);
            loading(false);
            return;
        }

        let whereStr = '';
        if(filterOptions.jobId !== undefined && filterOptions.jobId !== null && filterOptions.jobId !== ""){ whereStr += `job.id=${filterOptions.jobId} and `; }
        if(filterOptions.lotId !== undefined && filterOptions.lotId !== null && filterOptions.lotId !== ""){ whereStr += `lot.id=${filterOptions.lotId} and `; }
        if(filterOptions.crewId !== undefined && filterOptions.crewId !== null && filterOptions.crewId !== ""){ whereStr += `schedule_crew.crew_id=${filterOptions.crewId} and `; }
        if(filterOptions.employeeId !== undefined && filterOptions.employeeId !== null && filterOptions.employeeId !== ""){ whereStr += `schedule_crew_employee.employee_id=${filterOptions.employeeId} and `; }
        if(filterOptions.approvalStatus === "Needs Review"){ whereStr += `ewa.approval_by is null and `; }
        if(filterOptions.approvalStatus === "Approved"){ whereStr += `ewa.approved=1 and ewa.approval_by is not null and `; }
        if(filterOptions.approvalStatus === "Declined"){ whereStr += `ewa.approved=0 and ewa.approval_by is not null and `; }
        if(filterOptions.completedStatus === "Completed"){ whereStr += `schedule.complete=1 and `; }
        if(filterOptions.completedStatus === "Not Completed"){ whereStr += `(schedule.complete=0 or schedule.complete is null) and (schedule.approved=1 or schedule.approval_by is null) and `; }
        if(filterOptions.company === '1'){ whereStr += `(employee.svc=1 or employee.sub_concrete=1) and `}
        if(filterOptions.company === '2'){ whereStr += `(employee.svp=1 or employee.sub_plumbing=1) and `}
        if(filterOptions.company === '3'){ whereStr += `(employee.svf=1 or employee.sub_framing=1) and `}
        if(filterOptions.phaseId !== undefined && filterOptions.phaseId !== null && filterOptions.phaseId !== ""){ whereStr += `phase.id=${filterOptions.phaseId} and `}
        if(filterOptions.dateType !== '' && filterOptions.startDate !== null){
            if(filterOptions.dateType === 'approval_date'){ whereStr += `schedule.approved=1 and schedule.approval_date>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
            else if(filterOptions.dateType === 'declined'){ whereStr += `schedule.approved=0 and schedule.approval_date>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
            else{ whereStr += `${filterOptions.dateType}>='${moment(new Date(filterOptions.startDate)).format('YYYY-MM-DD')}' and `; }
        }
        if(filterOptions.dateType !== '' && filterOptions.endDate !== null){
            if(filterOptions.dateType === 'approval_date'){ whereStr += `schedule.approved=1 and schedule.approval_date<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
            else if(filterOptions.dateType === 'declined'){ whereStr += `schedule.approved=0 and schedule.approval_date<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
            else{ whereStr += `${filterOptions.dateType}<'${moment(new Date(filterOptions.endDate)).format('YYYY-MM-DD')}' and `; }
        }

        // Trim 'and' off end of whereStr
        if(whereStr !== ''){
            whereStr = whereStr.slice(0, whereStr.length - 5);
        }

        let tempEWA = []
        if(whereStr !== '') {
            tempEWA = await Auth.hitEndpointNew("GET", "ewas/ewa-page", `whereStr=${whereStr}`);
        }
        if(tempEWA.length === 0 && whereStr !== ''){
            setWarningNoLotsFromSelectedFilters(true);
            setEWAs([]);
        }
        else {
            setEWAs([...tempEWA]);
        }
        loading(false);
    }

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);

            let uid = await Auth.getUserId()
            setUserId(uid)

            setPhases(await Phases.loadPhases(null))
            let dbUsers = await Auth.hitEndpoint("GET", "USERS");
            setUser(dbUsers.find(x => x.id === uid))
            setAllUsers(dbUsers)

            setAllEmployees(await Employees.loadEmployees(null));
            setEmployeesForFilterDropDown(await Employees.loadEmployeesForDropDown(null));
            setCrewsForFilterDropDown(await Crews.loadCrewsForDropdown(null));

            setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));

            setCanPrintFirstCopy(await Can.isAuthorized('ewa', 'WRITE'));
            setCanCreateEditEWA(await Can.isAuthorized('ewa', 'WRITE'));
            setCanReprintEWA(await Can.isAuthorized('ewaReprint', 'WRITE'));
            setCanApproveEWA(await Can.isAuthorized('ewaApprove', 'WRITE'));
            setCanCompleteEWA(await Can.isAuthorized('ewaComplete', 'WRITE'));
        }

        if(!didLoadData){
            loadData();
        }
    }, []);

    const selectJobforEWAFilter = async (e) => {
        setSelectedJob(e)
        if(e === null || e.value === null || e.value === ''){
            setLotsForFilterDropDown([]);
            setFilterOptions({...filterOptions, jobId: "", lotId: ""});
            return;
        }
        setFilterOptions({...filterOptions, jobId: e.value, lotId: ""});
        setLotsForFilterDropDown(await Lots.loadLotsForDropDown({jobId: e.value}, e.value));
    }

    const saveEditedEWA = async (ewa) => {
        ewa.modified_by = await Auth.getUserId();
        ewa.modified_at = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
        ewa.created_at = dayjs(new Date(ewa.created_at)).format('YYYY-MM-DD H:mm:ss');

        await Auth.hitEndpointNew("PATCH", 'ewa', '', ewa);
        await applyFilters();
    }

    const markEWAApproved = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setApprovedEWA(null);
            return;
        }

        const tempApproveEWA = approvedEWA;
        tempApproveEWA.approved = 1;
        tempApproveEWA.approval_by = await Auth.getUserId();
        tempApproveEWA.approval_at = approvalDate;
        tempApproveEWA.line_items[0].approved = 1;
        tempApproveEWA.line_items[0].approval_by = await Auth.getUserId();
        tempApproveEWA.line_items[0].approval_date = approvalDate;

        await saveEditedEWA(tempApproveEWA);

        setApprovedEWA(null);
    }

    const markEWADeclined = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setDeclinedEWA(null);
            return;
        }

        const tempDeclinedEWA = declinedEWA;
        tempDeclinedEWA.approved = 0;
        tempDeclinedEWA.approval_by = await Auth.getUserId();
        tempDeclinedEWA.approval_at = approvalDate;
        tempDeclinedEWA.line_items[0].approved = 0;
        tempDeclinedEWA.line_items[0].approval_by = await Auth.getUserId();
        tempDeclinedEWA.line_items[0].approval_date = approvalDate;

        await saveEditedEWA(tempDeclinedEWA);

        setDeclinedEWA(null);
    }

    const markEWACompleted = async (e) => {
        let completedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setCompletedEWA(null);
            return;
        }

        const tempCompletedEWA = completedEWA;
        tempCompletedEWA.line_items[0].complete = 1;
        tempCompletedEWA.line_items[0].completed_by = await Auth.getUserId();
        tempCompletedEWA.line_items[0].completed_date = completedDate;

        await saveEditedEWA(tempCompletedEWA);

        setCompletedEWA(null);
    }

    const printEWAs = async (ewasPrint, isReprint, table = null) => {
        if(ewasPrint.length === 0){
            return;
        }

        const columns = [
            {dataKey: 'project_name',               header: `Project Name`},
            {dataKey: 'job_number',                 header: `Job #`},
            {dataKey: 'lot_number',                 header: `Lot #`},
            {dataKey: 'lot_phase',                  header: `Lot Phase`},
            {dataKey: 'task',                       header: `Phase Code`},
            {dataKey: 'notes',                      header: `Notes`},
            {dataKey: 'internal_estimate_number',   header: `Internal Estimate #`},
            {dataKey: 'builder_po_number',          header: `Builder PO #`},
            {dataKey: 'created_by',                 header: `Created`},
            {dataKey: 'approval_by',                header: `Approved`},
            {dataKey: 'payout',                     header: `Payout`},
        ];

        const columnStyles = {
            project_name: {cellWidth: 25},
            job_number: {cellWidth: 11, halign: 'center'},
            lot_number: {cellWidth: 11, halign: 'center'},
            lot_phase: {cellWidth: 16, halign: 'center'},
            task: {cellWidth: 20, halign: 'center'},
            notes: {cellWidth: 68},
            internal_estimate_number: {cellWidth: 28, halign: 'center'},
            builder_po_number: {cellWidth: 28, halign: 'center'},
            created_by: {cellWidth: 13, halign: 'center'},
            approval_by: {cellWidth: 13, halign: 'center'},
            payout: {cellWidth: 18, halign: 'right'},
        }

        // Sort ewa's into employee names
        let ewaLines = []
        let leads = []
        for(let i = 0; i < ewasPrint.length; i++) {
            let ewa = ewasPrint[i]
            for (let ii = 0; ii < ewa.line_items.length; ii++) {
                let item = ewa.line_items[ii]
                let lead = item.crew.employees.filter(x => x.is_lead === 1)
                if (lead === undefined || lead.length === 0) {
                    lead = [item.crew.employees[0]]
                }
                let employee = lead[0].employee
                let existingEmp = leads.find(x => x.employee_id === employee.id)
                if(existingEmp === undefined){
                    leads.push({
                        employee_id: employee.id,
                        crew_id: item.crew.crew_id,
                        employee: employee,
                    })
                }

                ewaLines.push({
                    id: item.id,
                    employee_id: employee.id,
                    project_name: item.lot.job.project_name,
                    job_number: item.lot.job.number,
                    lot_number: item.lot.number,
                    lot_phase: item.lot.phase,
                    task: item.task.code,
                    notes: item.notes,
                    internal_estimate_number: ewa.internal_estimate_number === null ? '' : ewa.internal_estimate_number,
                    builder_po_number: ewa.builder_po_number === null ? '' : ewa.builder_po_number,
                    created_by: getUserInitals(ewa.created_by),
                    approval_by: getUserInitals(item.approval_by),
                    payout: item.payout === null ? '0.00' : item.payout.toString(),
                })
            }
        }
        ewaLines = ewaLines.sort((a, b) => a.employee_id - b.employee_id || a.id - b.id)

        // Set up printData for autotable display
        const tableData = leads.map(l => {
            let lines = ewaLines.filter(x => x.employee_id === l.employee_id)
            // Calculate totals of each employee's payouts
            let total = lines.reduce(function(prev, cur) {
                return prev + parseFloat(cur.payout);
            }, 0);
            return {
                employee: {
                    name: `${l.employee.first_name} ${l.employee.last_name}`,
                    crews: l.employee.crews

                },
                lineItems: lines,
                total: total.toString()
            }
        })

        // Create pdf
        // autotable only supports pt units
        let doc = new jsPDF({ format: [280, 216], orientation: 'l'});
        for(let i = 0; i < tableData.length; i++){
            autoTable(doc, {
                rowPageBreak: 'avoid',
                columns: columns,
                body: tableData[i].lineItems,
                foot:[{
                    "approval_by": 'Total',
                    "payout": tableData[i].total
                }],
                showFoot: 'lastPage',
                theme: 'grid',
                headStyles: {
                    fillColor: [180, 180, 180],
                    lineColor: 0,
                    lineWidth: .4,
                    halign: 'center'
                },
                columnStyles: columnStyles,
                bodyStyles: {
                    lineColor: 0,
                    lineWidth: .4,
                },
                footStyles: {
                    fillColor: 'white',
                    textColor: [0, 0, 0],
                    lineColor: 0,
                    lineWidth: .4,
                    fontSize: 10,
                    fontStyle: 'normal',
                    payout: {halign: 'right'}
                },
                margin: {
                    top: 40
                },
                didParseCell: (data) => {
                    if(data.section === 'foot'){
                        data.cell.styles.fillColor = 0;
                    }
                    if(data.column.dataKey === 'approval_by' && data.section === 'foot'){
                        data.cell.styles.fillColor = 'white';
                        data.cell.styles.halign = 'right';
                    }
                    if(data.column.dataKey === 'payout') {
                        if(parseFloat(data.cell.text) < 0){
                            data.cell.styles.fontStyle = 'bold'
                        }
                        if (data.section === 'foot') {
                            data.cell.styles.fillColor = 'white';
                            data.cell.styles.halign = 'right';
                            data.cell.styles.lineColor = 0;
                        }
                    }
                },
                didDrawCell: (data) => {
                    if(data.column.dataKey === 'payout'){
                        const posX = data.cell.x + 2;
                        const posY = data.cell.y + (data.cell.contentHeight / 2);
                        doc.text('$', posX, posY, {align: 'left', baseline: 'middle'});
                        if(parseFloat(data.cell.text) < 0){
                            doc.setFont(doc.getFont().fontName, 'bold')
                            doc.setFontSize(15)
                            doc.text('*', posX + data.cell.width, posY, {align: 'left', baseline: 'middle'});
                            doc.setFont(doc.getFont().fontName, 'normal')
                            doc.setFontSize(10)
                        }
                    }
                },
                didDrawPage: (data) => {
                    const middleOptions = {align: 'center', baseline: 'middle'};
                    const bottomLeftOptions = {align: 'left', baseline: 'bottom'};
                    const bottomRightOptions = {align: 'right', baseline: 'bottom'};

                    if(isReprint){
                        doc.setTextColor('#bbbbbb');
                        doc.saveGraphicsState();
                        doc.setGState(new doc.GState({opacity: 0.3}));
                        doc.setFontSize(168);
                        doc.setFont(doc.getFont().fontName, 'bold');
                        doc.text('REPRINT', 140, 65, middleOptions);
                        doc.setFont(doc.getFont().fontName, 'normal');
                        doc.restoreGraphicsState();
                    }

                    // Header
                    doc.addImage(svcLogo, 'PNG', 10, 5, 39, 20, 'NONE', 'NONE', 0);

                    doc.setFontSize(22);
                    doc.setFont(doc.getFont().fontName, 'bold');
                    doc.setTextColor('#000000');
                    doc.text('Extra Work Authorization', 140, 15, middleOptions);
                    doc.setFont(doc.getFont().fontName, 'normal');

                    doc.setFontSize(10);
                    doc.text("Employee:", 20, 38, bottomLeftOptions);
                    doc.text(tableData[i].employee.name, 38, 38, bottomLeftOptions);

                    doc.text("Crew(s):", 120, 38, bottomLeftOptions);
                    doc.text(tableData[i].employee.crews, 135, 38, bottomLeftOptions);

                    doc.text(dayjs(new Date()).format('MM/DD/YYYY'), 260, 38, bottomRightOptions);


                    doc.text('page', 265, 211, bottomRightOptions);
                    doc.text(data.pageNumber.toString(), 265, 211, bottomLeftOptions);
                }
            });

            if(i + 1 < tableData.length){
                doc.addPage({ orientation: 'l'});
            }
        }

        const data = doc.output('blob');
        const blobURL = URL.createObjectURL(data);
        printjs(blobURL);

        // Mark ewa line items as printed and save
        let printedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
        for(let i = 0; i < ewasPrint.length; i++){
            ewasPrint[i].printed = 1;
            ewasPrint[i].printed_by = await Auth.getUserId();
            ewasPrint[i].printed_at = printedDate;

            ewasPrint[i].created_at = dayjs(new Date(ewasPrint[i].created_at)).format('YYYY-MM-DD H:mm:ss');
            ewasPrint[i].modified_by = await Auth.getUserId();
            ewasPrint[i].modified_at = printedDate;
            await Auth.hitEndpointNew("PATCH", 'ewa', '', ewasPrint[i]);
        }
        await applyFilters();
        if(table !== null){
            table.resetRowSelection()
        }
    }

    const startReprintEWA = (ewa) => {
        if(!canReprintEWA){return;}
        setReprintEWA(true);
        setEWAsToPrint([ewa]);
    }

    const handleReprintEWA = (e) => {
        if(e.target.value === 'cancel'){
            setEWAsToPrint([]);
            setReprintEWA(false);
            return;
        }

        setReprintEWA(false);
        printEWAs(ewasToPrint, true);
    }

    const exportEWAsToXLSX = async (e, table) => {
       document.getElementById('id-export-to-xlsx-button').innerHTML = 'Exporting...';

        const exportData = [];
        for(let i = 0; i < ewas.length; i++){
            for(let li = 0; li < ewas[i].line_items.length; li++) {
                let item = ewas[i].line_items[li]
                let emp = item.crew.employees.filter(x => x.is_lead === 1)
                if(emp === undefined || emp.length === 0){ emp = [item.crew.employees[0]] }
                let employee = emp[0].employee
                exportData.push({
                    "Printed By": getUserName(ewas[i].printed_by),
                    "Printed Date": Globals.formatDateToDisplay(ewas[i].printed_at),
                    "Job Name": item.lot.job.project_name,
                    "Job #": item.lot.job.number,
                    "Lot #": item.lot.number,
                    "Lot Phase": item.lot.phase,
                    "Phase Code": item.task.code,
                    "Description": item.notes,
                    "Payout": item.payout,
                    "Internal Est #": ewas[i].internal_estimate_number,
                    "Builder PO #": ewas[i].builder_po_number,
                    "Scheduled On": Globals.formatDateToDisplay(item.schedule_date),
                    "Crew #": employee.crews,
                    "Employee": getEmployeeName(employee.id),
                    "Created By": getUserName(ewas[i].created_by),
                    "Created Date": Globals.formatDateToDisplay(ewas[i].created_at),
                    "Approved By": ewas[i].approved === 1 && ewas[i].approval_by ? getUserName(ewas[i].approval_by) : '',
                    "Approved Date": ewas[i].approved === 1 && ewas[i].approval_by ? Globals.formatDateToDisplay(ewas[i].approval_date) : '',
                    "Declined By": ewas[i].approved === 0 && ewas[i].approval_by ? getUserName(ewas[i].approval_by) : '',
                    "Declined Date": ewas[i].approved === 0 && ewas[i].approval_by ? Globals.formatDateToDisplay(ewas[i].approval_date) : '',
                    "Completed By": getUserName(item.completed_by),
                    "Completed Date": Globals.formatDateToDisplay(item.completed_date),
                });
            }
        }

        Globals.exportToXLSXFileFormat(exportData, 'EWAs', './EWAs.xlsx');
        document.getElementById('id-export-to-xlsx-button').innerHTML = '';
    }

    const [ewaTable, setEWATable] = useState()
    const gridColumns = useMemo(
        () => {
            return([
                {
                    accessorFn: (row) => {
                        if(row.all_projects.length === 1){
                            return (row.top_project)
                        }
                        else {
                            return (
                                <Tooltip title={
                                    <table key={`projects-table-${row.id}`}>
                                        <tbody>
                                        {row.all_projects.map((project, index) => {
                                            return (
                                                <tr key={`project-row-${row.id}-${index}`}
                                                    style={{border: "1pt solid", paddingTop: "10px"}}>
                                                    <td key={`project-cell-name-${row.id}-${index}`}
                                                        style={{paddingRight: "20px"}}>
                                                        {project}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                } arrow>
                                    <div>{row.top_project === null || row.top_project === '' ? 'NA' : `${row.top_project} +${row.all_projects.length - 1} more`}</div>
                                </Tooltip>
                            )
                        }
                    },
                    id: 'project',
                    header: 'Project',
                    size: 50,
                    enableEditing: false,
                    enableColumnPinning: false,
                },
                {
                    accessorFn: (row) => {
                        if(row.all_jobs.length === 1){
                            return (row.top_job)
                        }
                        else {
                            return (
                                <Tooltip title={
                                    <table key={`jobs-table-${row.id}`}>
                                        <tbody>
                                        {row.all_jobs.map((job, index) => {
                                            return (
                                                <tr key={`job-row-${row.id}-${index}`}
                                                    style={{border: "1pt solid", paddingTop: "10px"}}>
                                                    <td key={`job-cell-name-${row.id}-${index}`}
                                                        style={{paddingRight: "20px"}}>
                                                        {job}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                } arrow>
                                    <div>{row.top_job === null || row.top_job === '' ? 'NA' : `${row.top_job} +${row.all_jobs.length - 1} more`}</div>
                                </Tooltip>
                            )
                        }
                    },
                    id: 'job',
                    header: 'Job',
                    size: 50,
                    enableEditing: false,
                    enableColumnPinning: false,
                },
                {
                   accessorFn: (row) => {
                        return (
                            <Tooltip title={
                                <table key={`lots-table-${row.id}`}>
                                    <tbody>
                                    {row.all_lots.map((lot, index) => {
                                        return (
                                            <tr key={`lot-row-${row.id}-${index}`} style={{
                                                borderLeft: "1pt solid",
                                                borderRight: "1pt solid",
                                                borderTop: "1pt solid",
                                                paddingTop: "10px"
                                            }}>
                                                <td key={`lot-cell-name-${row.id}-${index}`}
                                                    style={{width: '50px', paddingRight: "20px"}}>
                                                    {lot.lot}
                                                </td>
                                                <td key={`address-cell-name-${row.id}-${index}`}
                                                    style={{width: '150px', paddingRight: "20px"}}>
                                                    {lot.address}
                                                </td>
                                            </tr>)
                                    })}
                                    </tbody>
                                </table>
                            } arrow>
                                <div>{row.top_lot === null || row.top_lot === '' ? 'NA' : row.top_lot.lot}</div>
                            </Tooltip>
                        )
                    },
                    id: 'lot',
                    header: 'Lot',
                    size: 50,
                    enableEditing: false,
                    enableColumnPinning: false,
                    filterFn: (row, _columnIds, filterValue) => {
                       return row.original['all_lots'].join(',').toLowerCase().startsWith(filterValue.toLowerCase())
                    },
                },
                {
                    accessorFn: (row) => row.all_notes.join(', '),
                    id: 'notes',
                    header: 'Notes',
                    enableEditing: false,
                    enableColumnPinning: false,
                    filterFn: (row, _columnIds, filterValue) => {
                        return row.original['all_notes'].join(',').toLowerCase().includes(filterValue.toLowerCase())
                    },
                },
                {
                    accessorFn: (row) => {
                        if(row.all_tasks.length === 1){
                            return (row.top_task)
                        }
                        else {
                            return (
                                <Tooltip title={
                                    <table key={`notes-table-${row.id}`}>
                                        <tbody>
                                        {row.all_tasks.map((task, index) => {
                                            return (
                                                <tr key={`task-row-${row.id}-${index}`} style={{border: "1pt solid", paddingTop: "10px"}}>
                                                    <td key={`task-cell-name-${row.id}-${index}`} style={{paddingRight: "20px"}}>
                                                        {task}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                } arrow>
                                    <div>{`${row.top_task} +${row.all_tasks.length - 1} more`}</div>
                                </Tooltip>
                            )
                        }
                    },
                    id: 'tasks',
                    header: 'Tasks',
                    size: 10,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => {
                        if(row.all_leads.length === 1){
                            return (row.top_lead)
                        }
                        else {
                            return (
                                <Tooltip title={
                                    <table key={`leads-table-${row.id}`}>
                                        <tbody>
                                        {row.all_leads.map((lead, index) => {
                                            return (
                                                <tr key={`lead-row-${row.id}-${index}`} style={{border: "1pt solid", paddingTop: "10px"}}>
                                                    <td key={`lead-cell-name-${row.id}-${index}`} style={{paddingRight: "20px"}}>
                                                        {lead}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                } arrow>
                                    <div>{row.top_lead}</div>
                                </Tooltip>
                            )
                        }
                    },
                    id: 'leads',
                    header: 'Crews',
                    size: 10,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => {
                        if(row.line_items.length > 1 || !canCreateEditEWA) {
                            return (
                                row.total_payout
                            )
                        }
                        else{
                            if(row.total_payout !== 0){
                                if(row.total_payout < 0){
                                    return (`($${Math.abs(row.total_payout)})`)
                                }
                                else {
                                    return (`$${row.total_payout}`)
                                }
                            }
                            else {
                                return (
                                    <TextField defaultValue={row.total_payout}
                                               type={'decimal'}
                                               inputProps={{style: { textAlign: 'center' }}}
                                               onFocus={e => {
                                                   e.target.select();
                                               }}
                                               onBlur={async (e) => {
                                                   let payout = e.target.value
                                                   if (payout === '') {
                                                       payout = null
                                                   }
                                                   if(payout > 0) {
                                                       let tempEWAs = [...ewas]
                                                       let tempEWA = tempEWAs.find(x => x.id === row.id)
                                                       tempEWA.line_items[0].payout = payout
                                                       tempEWA.total_payout = payout
                                                       row.total_payout = payout
                                                       row.line_items[0].payout = payout
                                                       setBypassPagination(true);
                                                       await EWAs.updateEWA([tempEWA])
                                                       completeAndApproveEWA(row, null, null)
                                                   }
                                               }}/>
                                )
                            }
                        }
                    },
                    id: 'total_payout',
                    header: 'Total Payout',
                    enableEditing: false,
                    size: 5,
                    muiTableHeadCellProps: {
                        align: 'center'
                    }
                },
                {
                    accessorFn: (row) => {
                        let minDate = row.line_items.sort(function(d1, d2){
                            return d2.schedule_date-d1.schedule_date;
                        })[0].schedule_date;
                        let maxDate = row.line_items.sort(function(d1, d2){
                            return d1.schedule_date-d2.schedule_date;
                        })[0].schedule_date;
                        if(minDate !== maxDate) {
                            return `${dayjs(minDate).format('MM/DD/YYYY')}${minDate !== maxDate ? ' - ': ''}${minDate !== maxDate ? dayjs(maxDate).format('MM/DD/YYYY') : ''}`
                        }
                        else{
                            return `${dayjs(minDate).format('MM/DD/YYYY')}`
                        }
                    },
                    id: 'schedule_date',
                    header: 'Date',
                    // filterVariant: 'date-range',
                    enableEditing: false,
                },
                {
                    Cell: ({
                               cell
                           }) => cell.getValue() === 'true' ? 'Yes' : 'No',
                    accessorFn: (row) => row.approved ? 'true' : 'false',
                    filterVariant: 'checkbox',
                    id: 'approved',
                    header: 'Approved',
                    size: 10,
                    enableEditing: false,
                },
                {
                    Cell: ({
                               cell
                           }) => cell.getValue() === 'true' ? 'Yes' : 'No',
                    accessorFn: (row) => row.printed ? 'true' : 'false',
                    filterVariant: 'checkbox',
                    id: 'printed',
                    header: 'Printed',
                    size: 10,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => getUserName(row.created_by),
                    id: 'created_by',
                    header: 'Created By',
                    size: 20,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => getUserName(row.printed_by),
                    id: 'printed_by',
                    header: 'Printed By',
                    size: 20,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => row.printed_at === null ? '' : moment(row.printed_at).format('MM/DD/YYYY'),
                    id: 'printed_at',
                    header: 'Printed Date',
                    size: 30,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => row.internal_estimate_number,
                    id: 'internal_estimate',
                    header: 'Internal Estimate #',
                    size: 30,
                    enableEditing: false,
                },
                {
                    accessorFn: (row) => row.builder_po_number,
                    id: 'builder_po',
                    header: 'Builder PO #',
                    size: 30,
                    enableEditing: false,
                },
            ])
        }
    );
    let colorGroupCounter = 1

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    const baseHeaderColor = theme.palette.mode === 'dark' ? 'rgba(200, 200, 200, 1)' : 'rgba(200, 200, 200, 1)'
    const baseCompleteColor = theme.palette.mode === 'dark' ? 'rgba(200, 211, 210, 1)' : 'rgba(200, 211, 234, 1)'
    const baseApprovedColor = theme.palette.mode === 'dark' ? 'rgba(143, 248, 144, 1)' : 'rgba(143, 225, 110, 1)'
    const baseDeniedColor = theme.palette.mode === 'dark' ? 'rgba(255, 71, 76, 1)' : 'rgba(255, 90, 76, 1)'

    const completeAndApproveEWA = (row, complete, approve) => {
        let tempEWAs = [...ewas]
        let selectedEWAs = []
        let date = dayjs(new Date()).format('YYYY-MM-DD')
        let ewa = tempEWAs.find(x => x.id === row.id)

        row.approved = approve === null ? row.approved : approve
        row.complete = complete === null ? row.complete : complete
        ewa.approved = approve === null ? row.approved : approve
        ewa.complete = complete === null ? row.complete : complete

        for (let l = 0; l < row.line_items.length; l++) {

            ewa.line_items[l].approved = approve === null ? ewa.line_items[l].approved : approve
            if (approve !== null) {
                row.line_items[l].approved = approve
                ewa.line_items[l].approval_by = userId
                ewa.line_items[l].approval_date = date
                ewa.approval_by = userId
                ewa.approval_date = date
                for (let e = 0; e < row.line_items[l].crew.employees.length; e++) {
                    row.line_items[l].crew.employees[e].approved = approve
                    ewa.line_items[l].crew.employees[e].approved = approve
                }
            }
            if (complete !== null) {
                row.line_items[l].complete = complete
                ewa.line_items[l].completed_by = userId
                ewa.line_items[l].completed_date = date
                ewa.completed_by = userId
                ewa.completed_date = date
            }
        }

        let lines = [...ewa.line_items]
        let compApprovedLines = ewa.line_items.filter(x => (x.approved === 1 || x.approved === true) && (x.complete === 1 || x.complete === true))
        let canPrint = compApprovedLines.length === lines.length && ewa.total_payout !== 0

        row.can_print = canPrint
        row.can_approve = approve === 0 || (approve === null && complete === null)
        row.can_deny = approve === 1 || (approve === null && complete === null)
        row.can_complete = approve === 1 && (complete === null || complete === 0)

        ewa.can_print = canPrint
        ewa.can_approve = (approve === 0 || (approve === null && complete === null)) && ewa.total_payout !== 0
        ewa.can_deny = (approve === 1 || (approve === null && complete === null)) && ewa.total_payout !== 0
        ewa.can_complete = approve === 1 && (complete === null || complete === 0) && ewa.total_payout !== 0

        if(approve === null && complete === null){
            //do nothing
        }
        else {
            selectedEWAs.push(ewa)
            EWAs.updateEWA(selectedEWAs, user)
        }
        setEWAs(tempEWAs)
    }
    const printSelectedEWAs = async (table) => {
        let tmpEwas = []
        let rows = table.getSelectedRowModel().rows
        let reprint = 0
        for(let r=0; r<rows.length; r++){
            if(rows[r].original.printed === 1){
                reprint = 1
            }
            tmpEwas.push(rows[r].original)
        }

        await printEWAs(tmpEwas, reprint, table)
    }

    const getBackgroundColor = (rows, row) => {
        let index = rows.indexOf(row)
        let coefficient = index%2 === 0 ? 0.1 : 0.3
        let background = row.original.complete ? darken(baseCompleteColor, coefficient) :
            row.original.approved === 1 ? darken(baseApprovedColor, coefficient) :
                row.original.approved === 0 && row.original.approval_by !== null ? darken(baseDeniedColor, coefficient) :
                    darken(baseBackgroundColor, coefficient)

        background = darken(baseBackgroundColor, coefficient)
        return background
    }

    const [rowSelection, setRowSelection] = useState(false);
    const tableTheme = createTheme({
        overrides: {
            MuiTablePagination: {
                spacer: {
                    flex: 'none !important'
                }
            }
        }
    })

    return (
        <EWAListContainer>
            <div className='ewa-top-boxes'>
                <div className='ewa-filter'>
                    <div className='ewa-filter-header'>Filters</div>
                    <div className='ewa-filter-options'>
                        <div className='job-lot-filter-box'>
                            <Select className="job-filter-select" id="job-filter-select-id" name="job-filter-select"
                                    label="Job"
                                    onChange={(e) => selectJobforEWAFilter(e)}
                                    options={jobsForDropDowns.map((job) => {
                                        return{
                                            value: job.id,
                                            label: `${job.number} ${job.project_name}`,
                                            job: job
                                        }
                                    })}
                                    value={selectedJob}
                                    placeholder={'Job'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(true, true, true, true))}
                            />
                            <Select className="lot-filter-select" id="lot-filter-select-id" name="lot-filter-select"
                                    label="Lot"
                                    onChange={(e) => {
                                        setSelectedLot(e)
                                        setFilterOptions({...filterOptions, lotId: e === null ? null :  e === null ? null : e.value})
                                    }}
                                    options={lotsForFilterDropDown.map((lot) => {
                                        return{
                                            value: lot.id,
                                            label: (`${lot.number} ${lot.phase !== null ? lot.phase : ''} ${lot.address}`),
                                            lot: lot
                                        }
                                    })}
                                    value={selectedLot}
                                    placeholder={'Lot'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(true, true, true, true))}
                            />
                        </div>
                        <div className='company-crew-filter-box'>
                            <Select className="company-filter-select" id="company-filter-select-id" name="company-filter-select"
                                    label="Company"
                                    onChange={(e) => {
                                        setSelectedCompany(e)
                                        setFilterOptions({...filterOptions, company: e === null ? null : e === null ? null : e.value})
                                    }}
                                    options={companiesForDropDowns}
                                    value={selectedCompany}
                                    placeholder={'Company'}
                                    isClearable={true}
                            />
                            <Select className="crew-filter-select" id="crew-filter-select-id" name="crew-filter-select"
                                    label="Crew"
                                    onChange={(e) => {
                                        setSelectedCrew(e)
                                        setFilterOptions({...filterOptions, crewId:  e === null ? null : e.value})
                                    }}
                                    options={crewsForFilterDropDown.map((crew) => {
                                        let lead = crew.members.find(x => x.position === 'Leader')
                                        if(lead === undefined && crew.members !== null && crew.members.length > 0){
                                            lead = crew.members[0]
                                        }
                                        let leadName = ''
                                        if(lead !== undefined){
                                            leadName = (`${lead.employee.alias !== null && lead.employee.alias !== '' ? '(' + lead.employee.alias + ')': ''} ${lead.employee.first_name} ${lead.employee.last_name}`).trim()
                                        }
                                        return{
                                            value: crew.id,
                                            label: `${crew.number} - ${leadName}`,
                                            crew: crew
                                        }
                                    })}
                                    value={selectedCrew}
                                    placeholder={'Crew'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(true, true, true, true))}
                            />
                        </div>
                        <div className='phase-employee-filter-box'>
                            <Select className="phase-filter-select" id="phase-filter-select-id" name="phase-filter-select"
                                    label="Phase"
                                    onChange={(e) => {
                                        setSelectedPhase(e)
                                        setFilterOptions({...filterOptions, phaseId: e === null ? null : e === null ? null : e.value})
                                    }}
                                    options={(selectedCompany === null || selectedCompany === '' ? phases : phases.filter(x => x.company === selectedCompany.label)).map((phase) => {
                                        return {
                                            value: phase.id,
                                            label: phase.name,
                                            phase: phase
                                        }
                                    })}
                                    value={selectedPhase}
                                    placeholder={'Phase'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(false, true, true, true))}
                            />
                            <Select className="employee-filter-select" id="employee-filter-select-id" name="employee-filter-select"
                                    label="Employee"
                                    onChange={(e) => {
                                        setSelectedEmployee(e)
                                        setFilterOptions({...filterOptions, employeeId:  e === null ? null : e.value})
                                    }}
                                    options={employeesForFilterDropDown.map((employee) => {
                                        return{
                                            value: employee.id,
                                            label: (`${employee.alias !== null && employee.alias !== '' ? '(' + employee.alias + ')': ''} ${employee.first_name} ${employee.last_name}`).trim(),
                                            employee: employee
                                        }
                                    })}
                                    value={selectedEmployee}
                                    placeholder={'Employee'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(false, true, true, true))}
                            />
                        </div>
                        <div className='approval-status-filter-box'>
                            <Select className="approval-status-filter-select" id="approval-status-filter-select-id" name="approval-status-filter-select"
                                    label="Approval Status"
                                    value={selectedApprovalStatus}
                                    onChange={(e) => {
                                        setSelectedApprovalStatus(e)
                                        setFilterOptions({...filterOptions, approvalStatus: e === null ? null : e.value})
                                    }}
                                    options={approvalStatusForDropDowns}
                                    placeholder={'Approval Status'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(false, true, true, true))}
                            />
                            <Select className="completion-status-filter-select" id="completion-status-filter-select-id" name="completion-status-filter-select"
                                    label="Completion Status"
                                    value={selectedCompletionStatus}
                                    onChange={(e) => {
                                        setSelectedCompletionStatus(e)
                                        setFilterOptions({...filterOptions, completedStatus: e === null ? null : e.value})
                                    }}
                                    options={completionStatusForDropDowns}
                                    placeholder={'Completion Status'}
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(false, true, true, true))}
                            />
                        </div>
                    </div>
                </div>
                <div className={'ewa-filter'}>
                    <div className='ewa-filter-header'>Date Filters</div>
                    <div className='ewa-filter-options'>
                        <div className='date-search-type-filter-box'>
                            <Select className="date-search-type-filter-select" id="date-search-type-filter-select-id" name="date-search-type-filter-select"
                                    label="Filter Date By"
                                    value={selectedDateFilterType}
                                    onChange={(e) => {
                                        setSelectedDateFilterType(e)
                                        setFilterOptions({...filterOptions, dateType: e.value})
                                    }}
                                    options={Globals.getDateSearchType()}
                                    placeholder='Filter By Date'
                                    isClearable={true}
                                    filterOption={createFilter(filterConfig(false, true, true, true))}
                            />
                        </div>
                        <div className='date-range-filter-box'>
                            <div className='start-date-filter'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(date) => {
                                            setFilterOptions({...filterOptions, startDate: date})
                                        }}
                                        slotProps={{
                                            textField: {
                                                required: false,
                                                helperText: 'Start Date',
                                                sx: {minWidth: '120px', paddingTop: '20px'},
                                                variant: 'standard',
                                            },
                                        }}
                                        required={false}
                                        sx={{backgroundColor: 'white'}}
                                        maxDate={filterOptions.endDate}
                                        defaultValue={dayjs(filterOptions.startDate)}
                                        selected={filterOptions.startDate}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='end-date-filter'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(date) => {
                                            setFilterOptions({...filterOptions, endDate: date})
                                        }}
                                        slotProps={{
                                            textField: {
                                                required: false,
                                                helperText: 'End Date',
                                                sx: {minWidth: '120px', paddingTop: '20px'},
                                                variant: 'standard',
                                            },
                                        }}
                                        minDate={filterOptions.startDate}
                                        defaultValue={dayjs(filterOptions.endDate)}
                                        selected={filterOptions.endDate}
                                    />
                                </LocalizationProvider>
                            </div>
                            <IconButton
                                onClick={() => {
                                    let opts = {...filterOptions}
                                    opts.dateType = ""
                                    opts.startDate = null
                                    opts.endDate = null
                                    setFilterOptions(opts)
                                }}>
                                <Clear/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className='filter-actions'>
                <Button className="apply-filters" handleKeyPress={((e) => {if(e.key === 'Enter'){applyFilters()}})} handleClick={((e) => applyFilters())} buttonText="Apply Filters" width="120"/>
            </div>
            <div className={'table-container'}>
                <div className='table-header'>
                    <div className='header-title'>EWA's</div>
                </div>
                {displayLoadingMessage && <LoadingMessage message="Loading Data, this may take several minutes..." width="600px"/>}
                <MaterialReactTable
                    id="ewa-table"
                    columns={gridColumns}
                    data={ewas}
                    enableColumnFilterModes
                    enableRowSelection={row => row.original.can_print}
                    enableSelectAll={true}
                    enableBatchRowSelection={true}
                    enableStickyHeader
                    enableSorting={true}
                    enableEditing={false}
                    editDisplayMode={'table'}
                    enableRowActions={true}
                    enableColumnPinning={true}
                    paginationDisplayMode={'pages'}
                    positionPagination={"top"}
                    enableBottomToolbar={false}
                    muiPaginationProps={({}) => {
                        return {
                            color: 'primary',
                            shape: 'rounded',
                            boundaryCount: 5,
                            variant: 'outlined',
                            width: '100%',
                            sx: {width: '100%'}
                        }
                    }}

                    positionGlobalFilter="left"
                    muiSearchTextFieldProps={({}) => {
                        return {
                            placeholder: 'Search all EWAs',
                            variant: 'outlined',
                            className: 'global-search-box'
                        }
                    }}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnFilters={true}
                    enableGlobalFilterModes={true}
                    globalFilterModeOptions={['fuzzy', 'startsWith', 'contains']}
                    muiTableBodyRowProps={({row, table}) => {
                        let approved = row.original.line_items.filter(x => x.approved === 1 || x.approved === true).length === row.original.line_items.length
                        let completed = row.original.line_items.filter(x => x.completed === 1 || x.completed === true).length === row.original.line_items.length
                        let denied = row.original.line_items.filter(x => (x.approved === 0 || x.approved === false) && x.approval_by !== null).length === row.original.line_items.length
                        return({
                            sx: {
                                backgroundColor: () => {
                                    let background = approved ? baseApprovedColor : completed ? baseCompleteColor : denied ? baseDeniedColor : baseBackgroundColor
                                    if (colorGroupCounter % 2) {
                                        background = darken(background, 0.1)
                                    } else {
                                        background = lighten(background, 0.3)
                                    }
                                    return background
                                },
                            },
                        })}}
                    muiSelectCheckboxProps = {({table,  row }) => ({
                        disabled: row.original.locked || !row.original.can_print,
                        onClick: async (e) => {
                            if (e.shiftKey) {
                                let rows = table.getRowModel().rows
                                let selectedRows = table.getSelectedRowModel().rows
                                let lastSelectedIndex = selectedRows[selectedRows.length - 1].index
                                let index = row.index
                                let start = index > lastSelectedIndex ? lastSelectedIndex : index
                                let end = index > lastSelectedIndex ? index : lastSelectedIndex
                                const res = {}
                                for (let i = start; i < end; i++) {
                                    if (!rows[i]?.getCanSelect()) {
                                        continue
                                    }
                                    res[rows[i]["id"]] = true
                                }
                                table.setRowSelection(updater => {return {...updater, ...res}})
                            }
                            setEWATable(table)
                            setPagination(table.getState().pagination);
                            setBypassPagination(true);
                        },
                    })}
                    muiTableBodyCellProps={({cell, row, table}) => {
                        let columnId = cell.column.id
                        let rows = table.getRowModel().rows
                        return ({
                            sx: {
                                color: () => {
                                    let color = ''
                                    if(columnId === 'task' || columnId === 'crew') {
                                    }
                                    if(columnId === 'total_payout' && row.original.total_payout < 0){
                                        color = 'red'
                                    }
                                    return color
                                },
                                backgroundColor: () => {
                                    let color = ''
                                    if(columnId === 'total_payout' && row.original.total_payout === 0){
                                        color = 'red'
                                    }
                                    return color
                                },
                                borderTop: () => {
                                    let border = 'solid 2pt black'
                                    return border
                                },
                                borderRight: () => {
                                    let columns = table.getAllColumns()
                                    if(columnId === columns[columns.length - 1].id) {
                                        return 'solid 2pt black'
                                    }
                                },
                                borderLeft: () => {
                                    if(columnId === 'mrt-row-actions') {
                                        return 'solid 2pt black'
                                    }
                                },
                                fontWeight: () => {
                                    let weight = ''
                                    if (columnId === 'task' || columnId === 'crew') {
                                        weight = 'bold'
                                    }
                                    return weight
                                },
                                textAlign: () => {
                                    if(columnId === 'total_payout'){
                                        return 'center'
                                    }
                                }
                            },
                            onClick: async () => {
                                setEWATable(table)
                                setPagination(table.getState().pagination);
                                setBypassPagination(true);
                            }
                        })}}
                    renderRowActions={({table, row }) =>
                        (<Box className={'row-actions'}>
                                 <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                      {(row.original.can_approve && (canApproveEWA === true || canApproveEWA === 1)) &&
                                          <Tooltip title={'Approve and Complete EWA'}>
                                              <IconButton
                                                 aria-label="Approve and Complete EWA"
                                                 tabIndex={-1}
                                                 sx={{backgroundColor: 'green', color: 'white', "&:hover": { color: 'green', backgroundColor: 'white' }}}
                                                 disabled={(!row.original.can_approve && !row.original.can_complete) || !canApproveEWA}
                                                 onClick={() => {
                                                     setPagination(table.getState().pagination);
                                                     setBypassPagination(true);
                                                     if(window.confirm("Confirm approval AND completion of this EWA?")){
                                                         completeAndApproveEWA(row.original, 1, 1)
                                                     }
                                                 }}
                                             >
                                                 <div style={{height: '24px'}}>
                                                     <ThumbUp/>
                                                     <CheckCircle style={{width: '35%', marginTop: '3px', position: 'absolute', marginLeft: '-17px', color: 'green'}}/>
                                                 </div>
                                              </IconButton>
                                          </Tooltip>}
                                 </span>
                                 <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                      {(row.original.can_approve && (canApproveEWA === true || canApproveEWA === 1)) &&
                                          <Tooltip title={'Approve EWA'}>
                                             <IconButton
                                                 aria-label="Approve EWA"
                                                 tabIndex={-1}
                                                 sx={{backgroundColor: 'green', color: 'white', "&:hover": { color: 'green', backgroundColor: 'white' }}}
                                                 disabled={!row.original.can_approve || !canApproveEWA}
                                                 onClick={() => {
                                                     setPagination(table.getState().pagination);
                                                     setBypassPagination(true);
                                                     if (window.confirm("Confirm approval of this EWA?")) {
                                                         completeAndApproveEWA(row.original, null, 1)
                                                     }
                                                 }}
                                                 variant="contained"
                                             >
                                                <ThumbUp />
                                            </IconButton>
                                          </Tooltip>}

                                 </span>

                                    <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                         {(row.original.can_deny && (canApproveEWA === true || canApproveEWA === 1)) &&
                                             <Tooltip title={'Deny EWA'}>
                                                 <IconButton
                                                     aria-label="Deny EWA"
                                                     tabIndex={-1}
                                                     sx={{backgroundColor: 'red', color: 'white', "&:hover": { color: 'red', backgroundColor: 'white' }}}
                                                     disabled={!row.original.can_deny || !canApproveEWA}
                                                     onClick={() => {
                                                         setPagination(table.getState().pagination);
                                                         setBypassPagination(true);
                                                         if(window.confirm("Confirm declining this EWA?")) {
                                                             completeAndApproveEWA(row.original, null, 0)
                                                         }
                                                     }}
                                                     variant="contained"
                                                 >
                                                     <ThumbDown />
                                                 </IconButton>
                                             </Tooltip>}
                                     </span>
                                     <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                         {(row.original.can_complete && (canCompleteEWA === true || canCompleteEWA === 1)) &&
                                             <Tooltip title={'Complete EWA'}>
                                                 <IconButton
                                                     aria-label="Complete EWA"
                                                     tabIndex={-1}
                                                     sx={{backgroundColor: 'green', color: 'white', "&:hover": { color: 'green', backgroundColor: 'white' }}}
                                                     disabled={!row.original.can_complete}
                                                     onClick={() => {
                                                         setPagination(table.getState().pagination);
                                                         setBypassPagination(true);
                                                         if(window.confirm("Confirm completion of this EWA?")) {
                                                             completeAndApproveEWA(row.original, 1, null)
                                                         }
                                                     }}
                                                     variant="contained"
                                                 >
                                                     <CheckCircle />
                                                 </IconButton>
                                             </Tooltip>}
                                     </span>

                                     <span style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                         {(canCreateEditEWA === true || canCreateEditEWA === 1) &&
                                             <Tooltip title={'Edit EWA'}>
                                                 <IconButton
                                                     aria-label="Edit EWA"
                                                     aria-hidden={false}
                                                     tabIndex={-1}
                                                     sx={{backgroundColor: 'orange', color: 'white', "&:hover": { color: 'orange', backgroundColor: 'white' }}}
                                                     disabled={!canCreateEditEWA}
                                                     onClick={async () => {
                                                         setPagination(table.getState().pagination);
                                                         setBypassPagination(true);
                                                         setEditEWA(await Ewas.buildEWA(row.original))
                                                         setOpenEWA(true)
                                                     }}
                                                     variant="contained"
                                                 >
                                                     <Edit />
                                                 </IconButton>
                                             </Tooltip>}
                                     </span>

                        </Box>)
                    }
                    state={{pagination}}
                    onPaginationChange={(page) => {
                        if(!bypassPagination){
                            setPagination(page);
                        }
                        setBypassPagination(false);
                    }}
                    initialState={{
                        showColumnFilters: false,
                        pagination: pagination,
                        columnPinning: {
                            left: ['mrt-row-actions', 'mrt-row-select']
                        },
                        density: 'compact',
                        expanded: false,
                        showGlobalFilter: true,
                    }}
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <Box className={'top-toolbar-box'}>
                                <Box style={{display: 'flex', gap: '0.5rem'}}>
                                    {canCreateEditEWA &&
                                    <Tooltip title={'Add New EWA'}>
                                     <span>
                                         <IconButton
                                             aria-label="Create New EWA"
                                             color="success"
                                             onClick={async () => {
                                                 let newEWA = await Ewas.createNewEWA(null)
                                                 newEWA.line_items = []
                                                 setEditEWA(newEWA)
                                                 setOpenEWA(true)
                                             }}
                                             variant="contained"

                                         >
                                             <AddCircle />
                                         </IconButton>
                                     </span>
                                    </Tooltip>}
                                    {(canCreateEditEWA) &&
                                    <Tooltip title={'Export To XLSX'}>
                                     <span>
                                         <IconButton
                                             aria-label="Export To XLSX"
                                             color="success"
                                             disabled={(table.getRowModel().rows.length === 0)}
                                             onClick={exportEWAsToXLSX}
                                             variant="contained"
                                         >
                                             <Download />
                                             <div id={'id-export-to-xlsx-button'}></div>
                                         </IconButton>
                                     </span>
                                    </Tooltip>}
                                    {(canCreateEditEWA) &&
                                    <Tooltip title="Print">
                                        <span>
                                            <IconButton
                                                aria-label="Print Selected EWAs"
                                                color="success"
                                                disabled={table.getSelectedRowModel().rows.length === 0}
                                                onClick={() => {
                                                    setPagination(table.getState().pagination);
                                                    setBypassPagination(true);
                                                    printSelectedEWAs(table)
                                                }}
                                                variant="contained"
                                            >
                                                <Print />
                                            </IconButton>
                                        </span>
                                    </Tooltip>}
                                </Box>
                            </Box>
                        );
                    }}
                />
            </div>

            <Dialog
                open={openEWA}
                fullWidth={true}
                maxWidth={'2000'}
            >
                <EWA
                    openEWA={openEWA}
                    setOpenEWA={setOpenEWA}
                    ewa={editEWA}
                    setEWA={setEditEWA}
                    applyFilters={applyFilters}
                    canCreateEditEWA={canCreateEditEWA}
                />
            </Dialog>
            {approvedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm approval of this EWA?`} handlePopup={markEWAApproved}/>}
            {declinedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm declining this EWA?`} handlePopup={markEWADeclined}/>}
            {completedEWA && <PopupOk color={PopupOkWarningColor} message={`Confirm completion of this EWA?`} handlePopup={markEWACompleted}/>}
            {reprintEWA && <PopupOk color={PopupOkWarningColor} message={`You are requesting to print an EWA that has already been printed, do you wish to reprint this EWA?`} handlePopup={handleReprintEWA}/>}
            {warningNoLotsFromSelectedFilters && <Popup color={PopupWarningColor} message={"No ewa's match the filter options"} handlePopup={togglePopup}/>}
            {errorNoFiltersSelected && <Popup color={PopupWarningColor} message={'Please select and apply at least 1 filter.'} handlePopup={togglePopup}/>}
            {errorsInNewEWA && <Popup color={PopupFailureColor} message={"Errors exist in some or all of the new EWA's. Please correct or remove the EWA's and resubmit."} handlePopup={togglePopup}/>}
            {errorNoLotSelected && <Popup color={PopupFailureColor} message={'No Lot Selected. Please select a lot.'} handlePopup={togglePopup}/>}
            {errorNoPhaseCodeSelected && <Popup color={PopupFailureColor} message={'No Phase Code Selected. Please select a phase code.'} handlePopup={togglePopup}/>}
            {errorNoJobDescription && <Popup color={PopupFailureColor} message={'No job description entered. Please enter a job description.'} handlePopup={togglePopup}/>}
            {errorNoInternalEstimateOrBuilderPO && <Popup color={PopupFailureColor} message={'No Internal Estimate Number or Builder PO Number entered. Please enter an Internal Estimate Number or Builder PO Number.'} handlePopup={togglePopup}/>}
            {errorNoScheduledDate && <Popup color={PopupFailureColor} message={'No scheduled date selected. Please select a schedule date.'} handlePopup={togglePopup}/>}
            {errorNoAmountEntered && <Popup color={PopupFailureColor} message={'No amount entered. Please enter an amount.'} handlePopup={togglePopup}/>}
            {errorNoEmployeeSelected && <Popup color={PopupFailureColor} message={'No employee selected. Please select an employee.'} handlePopup={togglePopup}/>}
            {newEWAsuccess && <Popup color={PopupSuccessColor} message={`New Extra Work Authorization has been submitted`} handlePopup={togglePopup}/>}
            {editEWAsuccess && <Popup color={PopupSuccessColor} message={`Edited Extra Work Authorization has been updated`} handlePopup={togglePopup}/>}
        </EWAListContainer>
    )
};

export default EWAList;
