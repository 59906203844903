import styled from 'styled-components';

const  EvenflowPageContainer = styled.div`
    display: grid;
    margin: 10px 0 175px 10px;
    box-sizing: border-box;


   td{
        min-width: 100px;
   }



   .tab-wrap {
      transition: 0.3s box-shadow ease;
      border-radius: $tab-wrap-border-radius;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      list-style: none;
      background-color: #fff;
      margin: 40px 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      &:hover {
        box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
      }
    }

    .tab {
      display: none;
      @for $i from 1 through $max-tab-count {
        &:checked:nth-of-type(#{$i}) ~ .tab__content:nth-of-type(#{$i}) {
          opacity: 1;
          transition: 0.5s opacity ease-in, 0.8s transform ease;
          position: relative;
          top: 0;
          z-index: 100;
          transform: translateY(0px);
          text-shadow: 0 0 0;
        }
      }
      &:first-of-type:not(:last-of-type) + label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:not(:first-of-type):not(:last-of-type) + label {
        border-radius: 0;
      }
      &:last-of-type:not(:first-of-type) + label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:checked + label {
        background-color: #fff;
        box-shadow: 0 -1px 0 #fff inset;
        cursor: default;
        &:hover {
          box-shadow: 0 -1px 0 #fff inset;
          background-color: #fff;
        }
      }
      + label {
        box-shadow: 0 -1px 0 #eee inset;
        border-radius: $tab-wrap-border-radius $tab-wrap-border-radius 0 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #333;
        flex-grow: 3;
        text-align: center;
        background-color: #f2f2f2;
        user-select: none;
        text-align: center;
        transition: 0.3s background-color ease, 0.3s box-shadow ease;
        height: 50px;
        box-sizing: border-box;
        padding: 15px;
        &:hover {
          background-color: #f9f9f9;
          box-shadow: 0 1px 0 #f4f4f4 inset;
        }
      }
      &__content {
        padding: 10px 25px;
        background-color: transparent;
        position: absolute;
        width: 100%;
        z-index: -1;
        opacity: 0;
        left: 0;
        transform: translateY(-3px);
        border-radius: $tab-wrap-border-radius;
      }
    }

   .tab-active{
        border-top-right-radius: 30;
        border-bottom-right-radius: 30;
   }
   
   .tab-builder{
        border: 1pt solid;
    }
    

   .tab-inactive{

   }

    .export-div{
        width: 100%;
        align-content: middle;
        align-items: center;
    }
    
    
    .top-boxes{
        display: grid;
        grid-template-columns: 600px 1fr;
        grid-gap: 10px;
        width: 50%;
        border-bottom: 10px solid white;

        .filter{
            display: grid;
            background: rgb(210, 200, 190);

            .filter-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 28px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .filter-options{
                display: grid;
                padding: 10px 0;

                .job-lot-filter-box,
                .phase-builder-filter-box,
                .phase-employee-filter-box,
                .approval-status-filter-box,
                .completion-status-filter-box,
                .date-search-type-filter-box,
                .date-range-filter-box{
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);
                    margin: 3px 0;
                    
                    .job-filter-select,
                    .phase-filter-select,
                    .date-filter-box{
                        display: grid;
                        grid-column: 1/11;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .builder-filter-select,
                    .lot-filter-select{
                        display: grid;
                        grid-column: 11/21;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    
                    .date-search-type-filter-select{
                        display: grid;
                        grid-column: 4/16;
                        grid-row: 5/6;
                    }
                    
                    .date-filter{
                        display: grid;
                        grid-column: 2/11;
                        grid-row: 1/4;
                        width: 75%;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .end-date-filter{
                        display: grid;
                        grid-column: 11/21;
                        grid-row: 1/4;
                        width: 75%;
                    }
                } 
            }
        }
    } 
    
    .filter-actions{
        display: grid;
        background: rgb(210, 200, 190);
        margin-top: -10px;
        width: 50%;
    }
    
    
    .filter-box{
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        grid-template-rows: 40px;
        width: 600px;
        height: auto;
        background: rgb(210, 200, 190);
        border-bottom: 3px solid white;

        .filter-builder{
            display: grid;
            grid-column: 2/4;
            grid-row: 1/1;
            width: 300px;
            margin-top: 10px;
            grid-template-columns: 300px, 30px, 300px;
            z-index: 40;
        }
        .filter-phase{
            display: grid;
            grid-column: 2/4;
            grid-row: 3/4;
            width: 300px;
            margin-top: 20px;
            grid-template-columns: 300px, 30px, 300px;
            z-index: 30;
        }
        .filter-date{
            display: grid;
            align-items: center;
            width: 300px;
            grid-column: 2/4;
            grid-row: 4/4;
            margin-top: 20px;
            grid-template-columns: 300px, 30px, 300px;
        }
        .filter-button{
            display: grid;
            align-items: center;
            width: 200px;
            grid-column: 2/4;
            grid-row: 5/5;
            grid-template-columns: 300px, 30px, 300px;
            margin-top: 10px;
            margin-left: 50px;
        }
        
    }
    
    .average-table{
        margin-left: 35px;
    }
`;

export default EvenflowPageContainer;
