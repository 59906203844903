import Auth from "../components/authentication/Auth";
import dayjs from "dayjs";

class Payroll {

    static async updateApprovals(schedule, userId){



        await Auth.hitEndpointNew("PATCH", "payroll/approval", null, {
            id: schedule.id,
            approved: schedule.approved,
            approval_by: userId,
            approval_at: dayjs(new Date()).format('YYYY-MM-DD'),
            complete: schedule.complete
        });
        return schedule;
    }

}

export default Payroll;
