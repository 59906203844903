import {useEffect, useMemo, useState} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Builders from '../../../classes/Builders.js';
import Jobs from '../../../classes/Jobs.js';
import Lots from '../../../classes/Lots.js';
import Employees from '../../../classes/Employees.js';
import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button.js';
import { LoadingMessage } from '../../utilities/LoadingMessage';
import Popup, {PopupWarningColor} from '../../utilities/Popup.js';
import PopupOk, {PopupOkWarningColor} from '../../utilities/PopupOk';

import PayrollApprovalContainer from '../styles/PayrollApprovalContainer';
import dayjs from "dayjs";

import Phases from "../../../classes/Phases";
import printjs from 'print-js';
import {Box, darken, IconButton, lighten, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";

import {PayWeekPicker} from "../../utilities/PayWeekPicker"

import {
    Add, Business, Check,
    CheckCircle,
    Clear, People,
    ThumbDown,
    ThumbUp
} from "@mui/icons-material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Select from "react-select";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Ewas from "../../../classes/Ewas";
import TextField from "@mui/material/TextField";
import Crews from "../../../classes/Crews";
import {logError} from "../../../utilities/error";
import Utilities from "../../../classes/Utilities";
import Config from "../../../config";
import EWAs from "../../../classes/Ewas";
import Payroll from "../../../classes/Payroll";
import Schedules from "../../../classes/Schedules";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ReassignCrew from "../../scheduling/crew/ReassignCrew";
import DialogActions from "@mui/material/DialogActions";

const PayrollApproval = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [userId, setUserId] = useState()
    const [user, setUser] = useState()

    const [todayDate, setTodayDate] = useState(new Date())

    const [filterOptions, setFilterOptions] = useState({
        jobId: '',
        lotId: '',
        company: '',
        employeeId: '',
        approvalStatus: '',
        completedStatus: '',
    });
    const [startDate, setStartDate] = useState(dayjs(todayDate).weekday() === 6 ? dayjs(todayDate) : dayjs(todayDate).weekday(0).add(-1, 'd')) //Starts at Sunday so backing off 1 day to Saturday
    const [endDate, setEndDate] = useState(dayjs(todayDate).weekday() === 6 ? dayjs(todayDate).add(6, 'd') : dayjs(todayDate).weekday(0).add(5, 'd')) //Starts at Sunday so only adding 5 days

    //Filter variables
    const [jobsForDropDowns, setJobsForDropDowns] = useState([]);
    const [selectedJob, setSelectedJob] = useState('')
    const [lotsForFilterDropDown, setLotsForFilterDropDown] = useState([]);
    const [selectedLot, setSelectedLot] = useState('')
    const [companiesForDropDowns, setCompaniesForDropDowns] = useState([
        {value: "1", label: "SVC"},
        {value: "2", label: "SVP"},
        {value: "3", label: "SVF"}
    ])
    const [selectedCompany, setSelectedCompany] = useState('')
    const [selectedPhase, setSelectedPhase] = useState('')
    const [crewsForFilterDropDown, setCrewsForFilterDropDown] = useState([]);
    const [employeesForFilterDropDown, setEmployeesForFilterDropDown] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [selectedCrew, setSelectedCrew] = useState('')

    const [approvalStatusForDropDowns, setApprovalStatusForDropDowns] = useState([
        {value: "Needs Review", label: "Needs Review"},
        {value: "Approved", label: "Approved"},
        {value: "Declined", label: "Declined"}
    ])
    const [selectedApprovalStatus, setSelectedApprovalStatus] = useState('')
    const [completionStatusForDropDowns, setCompletionStatusForDropDowns] = useState([
        {value: "Completed", label: "Completed"},
        {value: "Not Completed", label: "Not Completed"}
    ])
    const [selectedCompletionStatus, setSelectedCompletionStatus] = useState('')
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 15,
        pageIndex: 0
    })

    const [phases, setPhases] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [openSchedule, setOpenSchedule] = useState(false);
    const [approvedSchedule, setApprovedSchedule] = useState(null);
    const [declinedSchedule, setDeclinedSchedule] = useState(null);
    const [completedSchedule, setCompletedSchedule] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);


    const [displayLoadingMessage, setDisplayLoadingMessage] = useState(false);

    const [warningNoLotsFromSelectedFilters, setWarningNoLotsFromSelectedFilters] = useState(false);
    const [errorNoFiltersSelected, setErrorNoFiltersSelected] = useState(false);

    const canPrintFirstCopy = props.isAuthorized('schedule', 'WRITE');
    const canCreateEdit = props.isAuthorized('schedule', 'WRITE');
    const canReprint = props.isAuthorized('schedule', 'WRITE');

    const togglePopup = () => {
        setWarningNoLotsFromSelectedFilters(false);
        setErrorNoFiltersSelected(false);
        setOpenSchedule(false);
    }

    const [addJob, setAddJob] = useState(false)
    const [addSwapCrew, setSwapCrew] = useState(false)
    const [openCrew, setOpenCrew] = useState(false)

    const [selectedScheduleCrew, setSelectedScheduleCrew] = useState('')
    const [selectedScheduleRows, setSelectedScheduleRows] = useState([])

    const [scheduleEmployees, setScheduleEmployees] = useState([])
    const [selectedScheduleEmployees, setSelectedScheduleEmployees] = useState([])
    const [selectedScheduleEmployeesReassign, setSelectedScheduleEmployeesReassign] = useState([])
    const [scheduleEmpsToRemove, setScheduleEmpsToRemove] = useState([])

    const getEmployeeName = (id) => {
        const employee = allEmployees.find(employee => employee.id === id);
        let rtn = employee ? `${employee.alias !== null ? employee.alias : ''} ${employee.first_name} ${employee.last_name}` : '';
        return rtn.trim()
    }

    const getUserName = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname} ${user.lastname}` : '';
    }

    const getUserInitals = (id) => {
        const user = allUsers.find(user => user.id === id);
        return user ? `${user.firstname[0]}.${user.lastname[0]}.` : '';
    }

    const loading = (isLoading) => {
        setDisplayLoadingMessage(isLoading);
    }

    const applyFilters = async () => {
        if (Utilities.isLoggedOut()) {
            const uri = Config.BASE_UI();
            window.location.replace(uri);
        } else {
            loading(true);

            let queryObj = {}

            if (
                (filterOptions.jobId === undefined || filterOptions.jobId === null || filterOptions.jobId.toString() === '') &&
                (filterOptions.lotId === undefined || filterOptions.lotId === null || filterOptions.lotId === '') &&
                (filterOptions.company === undefined || filterOptions.company === null || filterOptions.company === '') &&
                (filterOptions.phaseId === undefined || filterOptions.phaseId === null || filterOptions.phaseId === '') &&
                (filterOptions.employeeId === undefined || filterOptions.employeeId === null || filterOptions.employeeId === '') &&
                (filterOptions.crewId === undefined || filterOptions.crewId === null || filterOptions.crewId === '') &&
                filterOptions.approvalStatus === '' &&
                filterOptions.completedStatus === '' &&
                startDate === null && endDate === null) {
                setErrorNoFiltersSelected(true);
                loading(false);
                return;
            }

            if (filterOptions.approvalStatus === "Needs Review") {
                queryObj['schedule.approval_by'] = 'null'
            }
            if (filterOptions.approvalStatus === "Approved") {
                queryObj['schedule.approved'] = 1
            }
            if (filterOptions.approvalStatus === "Declined") {
                queryObj['schedule.approved'] = 0
            }
            if (filterOptions.completedStatus === "Completed") {
                queryObj['schedule.complete'] = 1
            }
            if (filterOptions.completedStatus === "Not Completed") {
                queryObj['schedule.complete'] = [0, 'null']
                queryObj['schedule.approved'] = [0, 'null']
            }
            let start = null
            let end = null;
            if (filterOptions.jobId !== undefined && filterOptions.jobId !== null && filterOptions.jobId !== "") {
                queryObj['job.id'] = filterOptions.jobId
            }
            if (filterOptions.lotId !== undefined && filterOptions.lotId !== null && filterOptions.lotId !== "") {
                queryObj['lot.id'] = filterOptions.lotId
            }
            if (filterOptions.crewId !== undefined && filterOptions.crewId !== null && filterOptions.crewId !== "") {
                queryObj['schedule_crew.crew_id'] = filterOptions.crewId
            }
            if (filterOptions.employeeId !== undefined && filterOptions.employeeId !== null && filterOptions.employeeId !== "") {
                queryObj['schedule_crew_employee.employee_id'] = filterOptions.employeeId
            }
            if (filterOptions.company === '1') {
                queryObj['employee.svc or employee.sub_concrete'] = 1
            }
            if (filterOptions.company === '2') {
                queryObj['employee.svp or employee.sub_plumbing'] = 1
            }
            if (filterOptions.company === '3') {
                queryObj['employee.svf or employee.sub_framing'] = 1
            }
            if (filterOptions.phaseId !== undefined && filterOptions.phaseId !== null && filterOptions.phaseId !== "") {
                queryObj['phase.id'] = filterOptions.phaseId
            }
            if (startDate !== null && endDate !== null) {
                start = dayjs(new Date(startDate)).format('YYYY-MM-DD')
                end = dayjs(new Date(endDate)).format('YYYY-MM-DD')
            }

            let tempSchedule = await Auth.hitEndpointNew("GET", `payroll/schedule/${start}/${end}`, Object.keys(queryObj).length > 0 ? `jsonString=${JSON.stringify(queryObj)}` : null);
            if (tempSchedule.length === 0) {
                setWarningNoLotsFromSelectedFilters(true);
                setSchedule([]);
            } else {
                setSchedule(tempSchedule);
            }
            loading(false);
        }
    }

    useEffect(() => {
        const loadData = async () => {
            if (Utilities.isLoggedOut()) {
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            } else {
                setDidLoadData(true);

                let uid = await Auth.getUserId()
                setUserId(uid)

                setPhases(await Phases.loadPhases(null))
                let dbUsers = await Auth.hitEndpoint("GET", "USERS");
                setUser(dbUsers.find(x => x.id === uid))
                setAllUsers(dbUsers)

                setAllEmployees(await Employees.loadEmployees(null));
                setEmployeesForFilterDropDown(await Employees.loadEmployeesForDropDown(null));
                setCrewsForFilterDropDown(await Crews.loadCrewsForDropdown(null));

                setJobsForDropDowns(await Jobs.loadJobsForDropDown(null, await Builders.loadBuilders()));
            }
        }

        if(!didLoadData){
            loadData();
        }
    }, []);

    const selectJobforFilter = async (e) => {
        setSelectedJob(e)
        if(e === null || e.value === null || e.value === ''){
            setLotsForFilterDropDown([]);
            setFilterOptions({...filterOptions, jobId: "", lotId: ""});
            return;
        }
        setFilterOptions({...filterOptions, jobId: e.value, lotId: ""});
        setLotsForFilterDropDown(await Lots.loadLotsForDropDown({jobId: e.value}, e.value));
    }

    const markApproved = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setApprovedSchedule(null);
            return;
        }

        const tempApproveSchedule = approvedSchedule;
        tempApproveSchedule.approved = 1;
        tempApproveSchedule.approval_by = await Auth.getUserId();
        tempApproveSchedule.approval_at = approvalDate;
        setApprovedSchedule(null);
    }

    const markDeclined = async (e) => {
        let approvalDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setDeclinedSchedule(null);
            return;
        }

        const tempDeclinedSchedule = declinedSchedule;
        tempDeclinedSchedule.approved = 0;
        tempDeclinedSchedule.approval_by = await Auth.getUserId();
        tempDeclinedSchedule.approval_at = approvalDate;

        setDeclinedSchedule(null);
    }

    const markCompleted = async (e) => {
        let completedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss')
        if(e.target.value === "cancel"){
            setCompletedSchedule(null);
            return;
        }

        const tempCompletedSchedule = completedSchedule;
        tempCompletedSchedule.complete = 1;
        tempCompletedSchedule.completed_by = await Auth.getUserId();
        tempCompletedSchedule.completed_date = completedDate;

        setCompletedSchedule(null);
    }

    // const print = async (schedulePrint, isReprint, table = null) => {
    //     if(schedulePrint.length === 0){
    //         return;
    //     }
    //
    //     const columns = [
    //         {dataKey: 'project_name',               header: `Project Name`},
    //         {dataKey: 'job_number',                 header: `Job #`},
    //         {dataKey: 'lot_number',                 header: `Lot #`},
    //         {dataKey: 'lot_phase',                  header: `Lot Phase`},
    //         {dataKey: 'task',                       header: `Phase Code`},
    //         {dataKey: 'notes',                      header: `Notes`},
    //         {dataKey: 'internal_estimate_number',   header: `Internal Estimate #`},
    //         {dataKey: 'builder_po_number',          header: `Builder PO #`},
    //         {dataKey: 'created_by',                 header: `Created`},
    //         {dataKey: 'approval_by',                header: `Approved`},
    //         {dataKey: 'payout',                     header: `Payout`},
    //     ];
    //
    //     const columnStyles = {
    //         project_name: {cellWidth: 25},
    //         job_number: {cellWidth: 11, halign: 'center'},
    //         lot_number: {cellWidth: 11, halign: 'center'},
    //         lot_phase: {cellWidth: 16, halign: 'center'},
    //         task: {cellWidth: 20, halign: 'center'},
    //         notes: {cellWidth: 68},
    //         internal_estimate_number: {cellWidth: 28, halign: 'center'},
    //         builder_po_number: {cellWidth: 28, halign: 'center'},
    //         created_by: {cellWidth: 13, halign: 'center'},
    //         approval_by: {cellWidth: 13, halign: 'center'},
    //         payout: {cellWidth: 18, halign: 'right'},
    //     }
    //
    //     // Sort into employee names
    //     let lines = []
    //     let leads = []
    //     for(let i = 0; i < schedulePrint.length; i++) {
    //         let s = schedulePrint[i]
    //         for (let ii = 0; ii < s.line_items.length; ii++) {
    //             let item = s.line_items[ii]
    //             let lead = item.crew.employees.filter(x => x.is_lead === 1)
    //             if (lead === undefined || lead.length === 0) {
    //                 lead = [item.crew.employees[0]]
    //             }
    //             let employee = lead[0].employee
    //             let existingEmp = leads.find(x => x.employee_id === employee.id)
    //             if(existingEmp === undefined){
    //                 leads.push({
    //                     employee_id: employee.id,
    //                     crew_id: item.crew.crew_id,
    //                     employee: employee,
    //                 })
    //             }
    //
    //             lines.push({
    //                 id: item.id,
    //                 employee_id: employee.id,
    //                 project_name: item.lot.job.project_name,
    //                 job_number: item.lot.job.number,
    //                 lot_number: item.lot.number,
    //                 lot_phase: item.lot.phase,
    //                 task: item.task.code,
    //                 notes: item.notes,
    //                 internal_estimate_number: s.internal_estimate_number === null ? '' : s.internal_estimate_number,
    //                 builder_po_number: s.builder_po_number === null ? '' : s.builder_po_number,
    //                 created_by: getUserInitals(s.created_by),
    //                 approval_by: getUserInitals(item.approval_by),
    //                 payout: item.payout === null ? '0.00' : item.payout.toString(),
    //             })
    //         }
    //     }
    //     lines = lines.sort((a, b) => a.employee_id - b.employee_id || a.id - b.id)
    //
    //     // Set up printData for autotable display
    //     const tableData = leads.map(l => {
    //         let lines = lines.filter(x => x.employee_id === l.employee_id)
    //         // Calculate totals of each employee's payouts
    //         let total = lines.reduce(function(prev, cur) {
    //             return prev + parseFloat(cur.payout);
    //         }, 0);
    //         return {
    //             employee: {
    //                 name: `${l.employee.first_name} ${l.employee.last_name}`,
    //                 crews: l.employee.crews
    //
    //             },
    //             lineItems: lines,
    //             total: total.toString()
    //         }
    //     })
    //
    //     // Create pdf
    //     // autotable only supports pt units
    //     let doc = new jsPDF({ format: [280, 216], orientation: 'l'});
    //     for(let i = 0; i < tableData.length; i++){
    //         autoTable(doc, {
    //             rowPageBreak: 'avoid',
    //             columns: columns,
    //             body: tableData[i].lineItems,
    //             foot:[{
    //                 "approval_by": 'Total',
    //                 "payout": tableData[i].total
    //             }],
    //             showFoot: 'lastPage',
    //             theme: 'grid',
    //             headStyles: {
    //                 fillColor: [180, 180, 180],
    //                 lineColor: 0,
    //                 lineWidth: .4,
    //                 halign: 'center'
    //             },
    //             columnStyles: columnStyles,
    //             bodyStyles: {
    //                 lineColor: 0,
    //                 lineWidth: .4,
    //             },
    //             footStyles: {
    //                 fillColor: 'white',
    //                 textColor: [0, 0, 0],
    //                 lineColor: 0,
    //                 lineWidth: .4,
    //                 fontSize: 10,
    //                 fontStyle: 'normal',
    //                 payout: {halign: 'right'}
    //             },
    //             margin: {
    //                 top: 40
    //             },
    //             didParseCell: (data) => {
    //                 if(data.section === 'foot'){
    //                     data.cell.styles.fillColor = 0;
    //                 }
    //                 if(data.column.dataKey === 'approval_by' && data.section === 'foot'){
    //                     data.cell.styles.fillColor = 'white';
    //                     data.cell.styles.halign = 'right';
    //                 }
    //                 if(data.column.dataKey === 'payout') {
    //                     if(parseFloat(data.cell.text) < 0){
    //                         data.cell.styles.fontStyle = 'bold'
    //                     }
    //                     if (data.section === 'foot') {
    //                         data.cell.styles.fillColor = 'white';
    //                         data.cell.styles.halign = 'right';
    //                         data.cell.styles.lineColor = 0;
    //                     }
    //                 }
    //             },
    //             didDrawCell: (data) => {
    //                 if(data.column.dataKey === 'payout'){
    //                     const posX = data.cell.x + 2;
    //                     const posY = data.cell.y + (data.cell.contentHeight / 2);
    //                     doc.text('$', posX, posY, {align: 'left', baseline: 'middle'});
    //                     if(parseFloat(data.cell.text) < 0){
    //                         doc.setFont(doc.getFont().fontName, 'bold')
    //                         doc.setFontSize(15)
    //                         doc.text('*', posX + data.cell.width, posY, {align: 'left', baseline: 'middle'});
    //                         doc.setFont(doc.getFont().fontName, 'normal')
    //                         doc.setFontSize(10)
    //                     }
    //                 }
    //             },
    //             didDrawPage: (data) => {
    //                 const middleOptions = {align: 'center', baseline: 'middle'};
    //                 const bottomLeftOptions = {align: 'left', baseline: 'bottom'};
    //                 const bottomRightOptions = {align: 'right', baseline: 'bottom'};
    //
    //                 if(isReprint){
    //                     doc.setTextColor('#bbbbbb');
    //                     doc.saveGraphicsState();
    //                     doc.setGState(new doc.GState({opacity: 0.3}));
    //                     doc.setFontSize(168);
    //                     doc.setFont(doc.getFont().fontName, 'bold');
    //                     doc.text('REPRINT', 140, 65, middleOptions);
    //                     doc.setFont(doc.getFont().fontName, 'normal');
    //                     doc.restoreGraphicsState();
    //                 }
    //
    //                 // Header
    //                 doc.addImage(svcLogo, 'PNG', 10, 5, 39, 20, 'NONE', 'NONE', 0);
    //
    //                 doc.setFontSize(22);
    //                 doc.setFont(doc.getFont().fontName, 'bold');
    //                 doc.setTextColor('#000000');
    //                 doc.text('Extra Work Authorization', 140, 15, middleOptions);
    //                 doc.setFont(doc.getFont().fontName, 'normal');
    //
    //                 doc.setFontSize(10);
    //                 doc.text("Employee:", 20, 38, bottomLeftOptions);
    //                 doc.text(tableData[i].employee.name, 38, 38, bottomLeftOptions);
    //
    //                 doc.text("Crew(s):", 120, 38, bottomLeftOptions);
    //                 doc.text(tableData[i].employee.crews, 135, 38, bottomLeftOptions);
    //
    //                 doc.text(dayjs(new Date()).format('MM/DD/YYYY'), 260, 38, bottomRightOptions);
    //
    //
    //                 doc.text('page', 265, 211, bottomRightOptions);
    //                 doc.text(data.pageNumber.toString(), 265, 211, bottomLeftOptions);
    //             }
    //         });
    //
    //         if(i + 1 < tableData.length){
    //             doc.addPage({ orientation: 'l'});
    //         }
    //     }
    //
    //     const data = doc.output('blob');
    //     const blobURL = URL.createObjectURL(data);
    //     printjs(blobURL);
    //
    //     // Mark line items as printed and save
    //     let printedDate = dayjs(new Date()).format('YYYY-MM-DD H:mm:ss');
    //     for(let i = 0; i < schedulePrint.length; i++){
    //         schedulePrint[i].printed = 1;
    //         schedulePrint[i].printed_by = await Auth.getUserId();
    //         schedulePrint[i].printed_at = printedDate;
    //
    //         schedulePrint[i].created_at = dayjs(new Date(schedulePrint[i].created_at)).format('YYYY-MM-DD H:mm:ss');
    //         schedulePrint[i].modified_by = await Auth.getUserId();
    //         schedulePrint[i].modified_at = printedDate;
    //         await Auth.hitEndpointNew("PATCH", 'schedule', '', schedulePrint[i]);
    //     }
    //     await applyFilters();
    //     if(table !== null){
    //         table.resetRowSelection()
    //     }
    // }

    // const startReprint = (item) => {
    //     if(!canReprint){return;}
    //     setReprint(true);
    //     setToPrint([item]);
    // }

    // const handleReprint = (e) => {
    //     if(e.target.value === 'cancel'){
    //         setToPrint([]);
    //         setReprint(false);
    //         return;
    //     }
    //
    //     setReprint(false);
    //     print(scheduleToPrint, true);
    // }

    const [scheduleTable, setScheduleTable] = useState()
    const crewColumns = useMemo(
        () => {
            return([
                {
                    accessorFn: (row) => {
                        return (
                            <table key={`schedule-crew-table-${row.index}`} cellSpacing="0">
                                <tbody key={`schedule-table-body-${row.index}`}>
                                <tr key={`labor-row-${row.index}`}>
                                    <td className={'action-cell'}>
                                        <IconButton
                                            aria-label="Complete"
                                            tabIndex={-1}
                                            sx={{
                                                color: 'blue',
                                                ml: '25px',
                                            }}
                                            onClick={() => {
                                            //    setPagination(table.getState().pagination);
                                            //    setBypassPagination(true);
                                                setAddJob(true)
                                            }}>
                                            <Business/>
                                        </IconButton>
                                    </td>
                                    <td className={'action-cell'}>
                                        <IconButton
                                            aria-label="Complete"
                                            tabIndex={-1}
                                            sx={{
                                                color: 'blue',
                                                ml: '25px',
                                            }}
                                            onClick={() => {
                                                //   setPagination(table.getState().pagination);
                                                //  setBypassPagination(true);
                                                setSwapCrew(true)
                                            }}>
                                            <People/>
                                        </IconButton>
                                    </td>
                                    <td className={'action-cell'}>
                                    </td>
                                    <td key={`schedule-crew-cell-${row.index}`}>
                                        <span>{row.crew}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        )
                    },
                    id: 'crew',
                    header: 'Crew',
                    size: 80,
                    enableEditing: false,
                },
            ])
        }
    );

    let colorGroupCounter = 1
    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });
    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'
    const baseColor = theme.palette.mode === 'dark' ? 'rgba(195, 216, 230, 1)' : 'rgba(195, 216, 230, 1)'
    const baseCompleteColor = theme.palette.mode === 'dark' ? 'rgba(255, 255, 200, 1)' : 'rgba(255, 255, 237, 1)'
    const baseApprovedColor = theme.palette.mode === 'dark' ? 'rgba(143, 248, 144, 1)' : 'rgba(143, 225, 110, 1)'
    const baseDeniedColor = theme.palette.mode === 'dark' ? 'rgba(255, 71, 76, 1)' : 'rgba(255, 90, 76, 1)'

    const completeAndApprove = (row, scheduleId, complete, approve, empApprove, scheduleCrewEmployeeId) => {
        let temp = [...schedule]
        let selected = []
        let date = dayjs(new Date()).format('YYYY-MM-DD')
        let record = temp.find(x => x.crew === row.crew)

      //  console.log(record)
        if (record !== undefined) {
            let sRow
            let obj
            if (empApprove !== null) {
                sRow = record.schedule.find(x => x.schedule_crew_employee_id === scheduleCrewEmployeeId)
                obj = sRow.raw.crew.employees.find(x => x.id === scheduleCrewEmployeeId)
                console.log(sRow, obj)
                sRow.emp_approved = empApprove
                sRow.emp_approval_by = userId
                sRow.emp_approval_date = date
                obj.approved = empApprove
                obj.approval_by = userId
                obj.approval_date = date
                selected.push(obj)
            } else {
                sRow = record.schedule.find(x => x.schedule_id === scheduleId)
                obj = sRow.raw
                if (complete !== null) {
                    sRow.status = complete
                    sRow.status_by = userId
                    sRow.status_date = date
                    obj.complete = complete
                    obj.completed_by = userId
                    obj.completed_date = date
                }
                if (approve !== null) {
                    sRow.approved = approve
                    sRow.approval_by = userId
                    sRow.approval_date = date
                    obj.approved = approve
                    obj.approval_by = userId
                    obj.approval_date = date
                }
                selected.push(obj)
            }
        }

        if(empApprove !== null){
            Schedules.updateEmpApproval(selected)
        }
        else {
            Schedules.updateApproval(selected)
        }
        setSchedule(temp)
    }

    const handleCloseCrew = () => {
        setOpenCrew(false)
    }

    const handleAssignmentCloseNoSave = () => {
        setOpenCrew(false);
    };

    const handleCloseAssignmentSave = async () => {
    //    setSchedule(await Schedules.handleAssignmentSave(selectedRows, schedule, employees, selectedEmployeesReassign, empsToRemove, userId))
        setOpenCrew(false)
    }

    return (
        <PayrollApprovalContainer>
            <div className='top-boxes'>
                <div className='filter'>
                    <div className='filter-header'>Filters</div>
                    <div className='filter-options'>
                        <PayWeekPicker
                            start={startDate}
                            end={endDate}
                            setStart={setStartDate}
                            setEnd={setEndDate}
                        />
                        <div className='job-lot-filter-box'>
                            <Select className="job-filter-select" id="job-filter-select-id" name="job-filter-select"
                                    label="Job"
                                    onChange={(e) => selectJobforFilter(e)}
                                    options={jobsForDropDowns.map((job) => {
                                        return{
                                            value: job.id,
                                            label: `${job.number} ${job.project_name}`,
                                            job: job
                                        }
                                    })}
                                    value={selectedJob}
                                    placeholder={'Job'}
                                    isClearable={true}
                            />
                            <Select className="lot-filter-select" id="lot-filter-select-id" name="lot-filter-select"
                                    label="Lot"
                                    onChange={(e) => {
                                        setSelectedLot(e)
                                        setFilterOptions({...filterOptions, lotId: e === null ? null :  e === null ? null : e.value})
                                    }}
                                    options={lotsForFilterDropDown.map((lot) => {
                                        return{
                                            value: lot.id,
                                            label: (`${lot.number} ${lot.phase !== null ? lot.phase : ''} ${lot.address}`),
                                            lot: lot
                                        }
                                    })}
                                    value={selectedLot}
                                    placeholder={'Lot'}
                                    isClearable={true}
                            />
                        </div>
                        <div className='company-crew-filter-box'>
                            <Select className="company-filter-select" id="company-filter-select-id" name="company-filter-select"
                                    label="Company"
                                    onChange={(e) => {
                                        setSelectedCompany(e)
                                        setFilterOptions({...filterOptions, company: e === null ? null : e === null ? null : e.value})
                                    }}
                                    options={companiesForDropDowns}
                                    value={selectedCompany}
                                    placeholder={'Company'}
                                    isClearable={true}
                            />
                            <Select className="crew-filter-select" id="crew-filter-select-id" name="crew-filter-select"
                                    label="Crew"
                                    onChange={(e) => {
                                        setSelectedCrew(e)
                                        setFilterOptions({...filterOptions, crewId:  e === null ? null : e.value})
                                    }}
                                    options={crewsForFilterDropDown.map((crew) => {
                                        let lead = crew.members.find(x => x.position === 'Leader')
                                        if(lead === undefined && crew.members !== null && crew.members.length > 0){
                                            lead = crew.members[0]
                                        }
                                        let leadName = ''
                                        if(lead !== undefined){
                                            leadName = (`${lead.employee.alias !== null && lead.employee.alias !== '' ? '(' + lead.employee.alias + ')': ''} ${lead.employee.first_name} ${lead.employee.last_name}`).trim()
                                        }
                                        return{
                                            value: crew.id,
                                            label: `${crew.number} - ${leadName}`,
                                            crew: crew
                                        }
                                    })}
                                    value={selectedCrew}
                                    placeholder={'Crew'}
                                    isClearable={true}
                            />
                        </div>
                        <div className='phase-employee-filter-box'>
                            <Select className="phase-filter-select" id="phase-filter-select-id" name="phase-filter-select"
                                    label="Phase"
                                    onChange={(e) => {
                                        setSelectedPhase(e)
                                        setFilterOptions({...filterOptions, phaseId: e === null ? null : e === null ? null : e.value})
                                    }}
                                    options={(selectedCompany === null || selectedCompany === '' ? phases : phases.filter(x => x.company === selectedCompany.label)).map((phase) => {
                                        return {
                                            value: phase.id,
                                            label: phase.name,
                                            phase: phase
                                        }
                                    })}
                                    value={selectedPhase}
                                    placeholder={'Phase'}
                                    isClearable={true}
                            />
                            <Select className="employee-filter-select" id="employee-filter-select-id" name="employee-filter-select"
                                    label="Employee"
                                    onChange={(e) => {
                                        setSelectedEmployee(e)
                                        setFilterOptions({...filterOptions, employeeId:  e === null ? null : e.value})
                                    }}
                                    options={employeesForFilterDropDown.map((employee) => {
                                        return{
                                            value: employee.id,
                                            label: (`${employee.alias !== null && employee.alias !== '' ? '(' + employee.alias + ')': ''} ${employee.first_name} ${employee.last_name}`).trim(),
                                            employee: employee
                                        }
                                    })}
                                    value={selectedEmployee}
                                    placeholder={'Employee'}
                                    isClearable={true}
                            />
                        </div>
                        <div className='approval-status-filter-box'>
                            <Select className="approval-status-filter-select" id="approval-status-filter-select-id" name="approval-status-filter-select"
                                    label="Approval Status"
                                    value={selectedApprovalStatus}
                                    onChange={(e) => {
                                        setSelectedApprovalStatus(e)
                                        setFilterOptions({...filterOptions, approvalStatus: e === null ? null : e.value})
                                    }}
                                    options={approvalStatusForDropDowns}
                                    placeholder={'Approval Status'}
                                    isClearable={true}
                            />
                            <Select className="completion-status-filter-select" id="completion-status-filter-select-id" name="completion-status-filter-select"
                                    label="Completion Status"
                                    value={selectedCompletionStatus}
                                    onChange={(e) => {
                                        setSelectedCompletionStatus(e)
                                        setFilterOptions({...filterOptions, completedStatus: e === null ? null : e.value})
                                    }}
                                    options={completionStatusForDropDowns}
                                    placeholder={'Completion Status'}
                                    isClearable={true}
                            />
                        </div>
                    </div>
                    <div className='filter-actions'>
                        <Button className="apply-filters" handleKeyPress={((e) => {if(e.key === 'Enter'){applyFilters()}})} handleClick={((e) => applyFilters())} buttonText="Apply Filters" width={200}/>
                    </div>
                </div>
            </div>
            <div className={'table-container'}>
                <div className='table-header'>
                    <div className='header-title'>Schedule</div>
                </div>
                {displayLoadingMessage && <LoadingMessage message="Loading Data, this may take several minutes..." width="600px"/>}
                <MaterialReactTable
                    id="schedule-crew-table"
                    columns={crewColumns}
                    data={schedule}
                    enableColumnFilterModes
                    enableRowSelection={false}
                    enableSelectAll={false}
                    enableBatchRowSelection={false}
                    enableStickyHeader
                    enableSorting={true}
                    enableEditing={false}
                    enableRowActions={false}
                    enableColumnPinning={true}
                    enableBottomToolbar={true}
                    positionPagination={"bottom"}
                    paginationDisplayMode={'pages'}
                    muiPaginationProps={({}) => {
                        return {
                            color: 'primary',
                            shape: 'rounded',
                            boundaryCount: 5,
                            variant: 'outlined',
                        }
                    }}
                    muiSearchTextFieldProps={({}) => {
                        return {
                            placeholder: 'Search all',
                            variant: 'outlined',
                            className: 'global-search-box'
                        }
                    }}
                    enableColumnFilters={false}
                    enableGlobalFilterModes={true}
                    globalFilterModeOptions={['fuzzy', 'startsWith', 'contains']}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    displayColumnDefOptions={{
                        'mrt-row-expand': {
                            header: '',
                        },
                        'mrt-row-actions': {
                            header: '',
                            size: 25,
                            grow: false,
                        }}}
                    muiTableProps={{
                        sx: () => ({
                            borderCollapse: 'collapse',
                            ml: '10px',
                        })
                    }}
                    enableExpanding={false}
                    enableExpandAll={false}
                    muiTableBodyRowProps={({row}) => {
                        return({
                        sx: {
                            backgroundColor: row.index %2 === 0 ? darken(baseBackgroundColor, 0.1) : lighten(baseBackgroundColor, 0.3),
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                            fontColor: 'white',
                            textTransform: 'capitalize',
                            fontSize: 'h6.fontSize'
                        }
                    })}}
                    renderDetailPanel={({row, table}) =>
                        row.original?.schedule.map((record, index) => {
                            let curId = record.schedule_id
                            let prevId = row.original?.schedule[index - 1]?.schedule_id
                            let nextId = row.original?.schedule[index + 1]?.schedule_id

                            let background = record.approved === 1 ? baseApprovedColor :
                                (record.approved === 0 || record.emp_approved === 0) ? baseDeniedColor :
                                record.status ? baseCompleteColor : baseBackgroundColor

                            if (curId !== prevId) {
                                colorGroupCounter += 1
                            }
                            if (colorGroupCounter % 2) {
                                background = darken(background, 0.1)
                            } else {
                                background = lighten(background, 0.3)
                            }
                            let bottomBorder = curId !== nextId ? 'solid 1pt black' : ''
                            let topBorder = index === 0 ? 'solid 1pt black' : ''
                            return (
                                <table key={`schedule-table-${curId}-${index}`} cellSpacing="0">
                                    <tbody key={`schedule-table-body-${curId}-${index}`}>
                                    <tr key={`labor-row-${curId}-${index}`}>
                                        <td className={'action-cell'} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            borderLeft: 'solid 1pt'
                                        }}>
                                            {(curId !== prevId && !record.status) &&
                                                <Tooltip title={'Complete'}>
                                                    <IconButton
                                                        aria-label="Complete"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'green',
                                                        }}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, 1, null, null, null)
                                                        }}>
                                                            <Check/>
                                                    </IconButton>
                                                </Tooltip>}
                                        </td>
                                        <td className={'action-cell'} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                        }}>
                                            {(curId !== prevId && !record.status && !record.approved) &&
                                                <Tooltip title={'Approve and Complete'}>
                                                    <IconButton
                                                        aria-label="Approve and Complete"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'green',
                                                        }}
                                                        //disabled={!row.original.can_approve && !row.original.can_complete}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, 1, 1, null, null)
                                                        }}>
                                                        <div style={{height: '24px'}}>
                                                            <ThumbUp/>
                                                            <CheckCircle style={{
                                                                width: '35%',
                                                                marginTop: '3px',
                                                                position: 'absolute',
                                                                marginLeft: '-17px',
                                                                color: 'white'
                                                            }}/>
                                                        </div>
                                                    </IconButton>
                                                </Tooltip>}
                                            {(curId !== prevId && record.status === 1 && !record.approved) &&
                                                <Tooltip title={'Approve'}>
                                                    <IconButton
                                                        aria-label="Approve"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'green',
                                                        }}
                                                        //disabled={!row.original.can_approve && !row.original.can_complete}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, null, 1, null, null)
                                                        }}>
                                                            <div style={{height: '24px'}}>
                                                                <ThumbUp/>
                                                            </div>
                                                    </IconButton>
                                                </Tooltip>}
                                        </td>
                                        <td className={'action-cell'} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder}}
                                        >
                                            {(curId !== prevId && record.approved !== 0) &&
                                                <Tooltip title={'Deny'}>
                                                    <IconButton
                                                        aria-label="Deny"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'red',
                                                        }}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, null, 0, null, null)
                                                        }}
                                                        variant="contained">
                                                        <ThumbDown/>
                                                    </IconButton>
                                                </Tooltip>}
                                        </td>
                                        <td className={'action-cell crew-edit'} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder
                                        }}>
                                            {(curId !== prevId) &&
                                                <Tooltip title={'Edit Crew'}>
                                                    <IconButton
                                                        aria-label="Edit Crew"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'orange',
                                                        }}
                                                        //disabled={!row.original.can_approve && !row.original.can_complete}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            let scheduleRow = record.raw
                                                            setSelectedScheduleRows([scheduleRow])
                                                            console.log(scheduleRow)
                                                            setOpenCrew(true)
                                                        }}>
                                                        <div style={{height: '24px'}}>
                                                            <People/>
                                                        </div>
                                                    </IconButton>
                                                </Tooltip>}
                                        </td>
                                        <td className={'action-cell employee-approval'} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder
                                        }}>
                                            {(record.employee_count > 1 && (record.emp_approved === 1 || record.emp_approved === null)) &&
                                                <Tooltip title={'Deny'}>
                                                    <IconButton
                                                        aria-label="Deny Employee"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'red',
                                                        }}
                                                        //   disabled={!row.original.can_deny}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, 1, null,0, record.schedule_crew_employee_id)
                                                        }}
                                                        variant="standard"
                                                    >
                                                        <Clear/>
                                                    </IconButton>
                                                </Tooltip>}
                                            {(record.employee_count > 1 && (record.emp_approved === 0)) &&
                                                <Tooltip title={'Approve'}>
                                                    <IconButton
                                                        aria-label="Approve Employee"
                                                        tabIndex={-1}
                                                        sx={{
                                                            color: 'green',
                                                        }}
                                                        onClick={() => {
                                                            setPagination(table.getState().pagination);
                                                            setBypassPagination(true);
                                                            completeAndApprove(row.original, record.schedule_id, 1, null,1, record.schedule_crew_employee_id)
                                                        }}
                                                        variant="standard"
                                                    >
                                                        <Add/>
                                                    </IconButton>
                                                </Tooltip>}
                                        </td>
                                        <td key={`employee-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '350px'
                                        }}><span>{record.employee}</span></td>
                                        <td key={`date-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '100px'
                                        }}>{dayjs(record.schedule_date).format('MM/DD/YYYY')}</td>
                                        <td key={`project-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '200px'
                                        }}>{record.project_name}</td>
                                        <td key={`job-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '75px'
                                        }}>{record.job}</td>
                                        <td key={`lot-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '300px'
                                        }}>{record.lot}</td>
                                        <td key={`address-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '300px'
                                        }}>{record.address}</td>
                                        <td key={`task-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            width: '400px'
                                        }}>{record.task}</td>
                                        <td key={`notes-${curId}-${index}`} style={{
                                            backgroundColor: background,
                                            borderTop: topBorder,
                                            borderBottom: bottomBorder,
                                            borderRight: 'solid 1pt',
                                            width: '600px'
                                        }}>{record.notes}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            )
                        })
                    }
                    state={{pagination}}
                    onPaginationChange={(page) => {
                        if(!bypassPagination){
                            setPagination(page);
                        }
                        setBypassPagination(false);
                    }}
                    initialState={{
                        showColumnFilters: false,
                        pagination: pagination,
                        columnVisibility: {
                            "mrt-row-expand": false,
                        },
                        columnPinning: {
                            left: ['mrt-row-actions']
                        },
                        density: 'compact',
                        expanded: true,
                        showGlobalFilter: true,
                    }}
                />
            </div>

            <Dialog open={openCrew} onClose={handleCloseCrew} fullScreen={true}>
                <DialogTitle>Edit Crew {selectedScheduleCrew}</DialogTitle>
                <DialogContent>
                    <ReassignCrew
                        selectedRows={selectedScheduleRows}
                        crews={crewsForFilterDropDown}
                        employees={allEmployees}
                        setEmployees={setScheduleEmployees}
                        setSelectedEmployees={setSelectedScheduleEmployees}
                        selectedEmployees={selectedScheduleEmployees}
                        selectedEmployeesReassign={selectedScheduleEmployeesReassign}
                        setSelectedEmployeesReassign={setSelectedScheduleEmployeesReassign}
                        empsToRemove={scheduleEmpsToRemove}
                        setEmpsToRemove={setScheduleEmpsToRemove}
                        setOpen={setOpenCrew}
                        schedule={selectedScheduleRows}
                        setSchedule={setSelectedScheduleRows}
                     //   table={tableObj}
                        userId={userId}
                        showActions={false}
                        isUpdates={false}
                    ></ReassignCrew>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAssignmentCloseNoSave}>Cancel</Button>
                    <Button onClick={handleCloseAssignmentSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSchedule}
                fullWidth={true}
                maxWidth={'2000'}
            >
            </Dialog>
            {approvedSchedule && <PopupOk color={PopupOkWarningColor} message={`Confirm approval?`} handlePopup={markApproved}/>}
            {declinedSchedule && <PopupOk color={PopupOkWarningColor} message={`Confirm declining?`} handlePopup={markDeclined}/>}
            {completedSchedule && <PopupOk color={PopupOkWarningColor} message={`Confirm completion?`} handlePopup={markCompleted}/>}
            {warningNoLotsFromSelectedFilters && <Popup color={PopupWarningColor} message={"No records's match the filter options"} handlePopup={togglePopup}/>}
            {errorNoFiltersSelected && <Popup color={PopupWarningColor} message={'Please select and apply at least 1 filter.'} handlePopup={togglePopup}/>}
        </PayrollApprovalContainer>
    )
};

export default PayrollApproval;
