import styled from 'styled-components';

const  EvenflowGridContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 10px;
    
    .tab-job{
        border: 1pt solid;
    }
    
    .MuiTableCell-head{
        height: 200px;
        transform: skew(0deg);
        border-left: 1pt solid black;
        border-right: 1pt solid black;
        transform-origin: 0px 200px;

        .Mui-TableHeadCell-Content{
            height: 100%;
            width: 100%;
        }

        .Mui-TableHeadCell-Content-Labels{
            height: 100%;
            width: 100%;

        }

        .Mui-TableHeadCell-Content-Wrapper{
            height: 100%;
            width: 100%;
            align-content: center;
            transform: skew(0deg) rotate(-90deg);
            transform-origin: center;
            vertical-align: bottom;
            font-size: small;
        }

        .MuiTableSortLabel-root{
            margin-bottom: -120px;
        }
        .Mui-TableHeadCell-Content-Actions{
            margin-bottom: -120px;
        }
    }
    
    .lot-column{
         .Mui-TableHeadCell-Content-Wrapper{
            height: 100%;
            width: 100%;
            align-content: center;
            transform: skew(0deg) rotate(0deg) !important;
            transform-origin: center;
            vertical-align: bottom;
            font-size: x-small;
            text-align: center;
        }
    }
    
    .labels-grid{
        margin-top: -5px;
        margin-left: -9px;
        width: 119%;
        height: 129%;
        
        .schedule{
            border-bottom: 1q solid;
        }
        
        .actual{
        
        }
    }
    
    .dates-grid{
        margin-top: -5px;
        margin-left: -9px;
        width: 109%;
        height: 129%;
        
        .schedule{
            border-bottom: 1q solid;
        }
        
        .actual{
            content-align: 'center';
        }
        
        .interactive{
            cursor: pointer;
        }
        
        .actual-complete{
            padding-left: 20px !important;
        }
        .complete-indicator{
            color: green;
            float: right;
            padding-right: 10px;
        }
    }
    
    .calc-grid{
        margin-top: -5px;
        margin-left: -9px;
        width: 109%;
        height: 129%;
        
        .schedule{
            border-bottom: 1q solid;
        }
        
        .actual{
        
        }
    }
`;

export default EvenflowGridContainer;