import Auth from "../components/authentication/Auth";

class Phases {
    static async loadPhases(queryObj) {

        const limit = 50;
        let skip = 0;
        let data = [];
        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "phases", `selector=eight&jsonString=${jsonString}&skip=${skip}&limit=${limit}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "phases", `selector=seven&skip=${skip}&limit=${limit}`);
        }
        data = [...data, ...dbData];
        skip += limit;

        return data;
    }

    static async loadPhasesForScheduleFilter(queryObj) {

        const limit = 50;
        let skip = 0;
        let data = [];
        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "phases", `selector=eight&jsonString=${jsonString}&skip=${skip}&limit=${limit}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "phases", `selector=seven&skip=${skip}&limit=${limit}`);
        }
        data = [...data, ...dbData];
        skip += limit;

        let options = [{
            label: 'Assigned',
            options: []
        },
            {
                label: 'Other',
                options: []
            }]
        data.forEach(phase => {
            options[1].options.push({value: phase.id, label: phase.name, phase: phase})
        })

        return options;
    }

    static async saveNewPhase(phase) {
        const result = await Auth.hitEndpointNew("POST", "phases", "", phase);
        phase.id = result.id;

        return phase;
    }

    static async updatePhase(phase, user) {
        await Auth.hitEndpointNew("PATCH", "phases", "", phase);
        return phase;
    }

    static async deletePhase(phase) {
        await Auth.hitEndpointNew("DELETE", "phases", "", phase);
        return phase;
    }

    static createNewPhase(user){
        return {
            field_id: "",
            name: "",
            description: "",
            stats_order_by: "",
            stat_name: ""
        };
    }

    static async checkPhaseForErrors(phase){
        if(phase.field_id === ''){return "ERROR_NO_FIELD";}

        // Check for duplicate phases
        const jsonString = JSON.stringify({name: phase.name});
        let dupData = await Auth.hitEndpointNew("GET", "phases", `selector=nine&jsonString=${jsonString}`);
        if(phase.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id !== phase.id);
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_PHASE";}

        return "SUCCESS";
    }

    static async findPhaseByTask(phases, task){
        for(let p=0; p<phases.length;p++){
            if(phases[p].tasks.find(x => x.id === task.id) !== undefined){
                return phases[p]
            }
        }
    }
    static async findPhaseByTaskId(phases, taskId){
        for(let p=0; p<phases.length;p++){
            if(phases[p].tasks.find(x => x.id === taskId) !== undefined){
                return phases[p]
            }
        }
    }
    static async findPhaseById(phases, phaseId){
        let phase = phases.find(x => x.id === phaseId)
        return phase
    }
    static async getSelectedPhaseIds(phases){
        let phaseIds = []
        if (phases !== undefined && phases !== null && phases.length > 0) {
            phases.forEach(s => {
                phaseIds.push(s.value)
            })
        }
        return phaseIds
    }
}

export default Phases;
