import { useState, useEffect } from 'react';
import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import JobsFilter from './JobsFilter.js';
import JobsTable from './JobsTable.js';
import CreateJob from './CreateJob.js';
import EditJob from './EditJob.js';
import DeleteJob from './DeleteJob.js';
import Box from '@mui/material/Box';

const JobsPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        includeDeleted: false,
    });

    const [message, setMessage] = useState({
        color: '',
        message: ''
    });

    const [builders, setBuilders] = useState([]);
    const [jobs, setJobs] = useState([]);

    const [jobTypes, setJobTypes] = useState([]);
    const [scheduleTemplates, setScheduleTemplates] = useState([]);

    const [generals, setGenerals] = useState([]);
    const [supers, setSupers] = useState([]);

    const [createJob, setCreateJob] = useState(false);
    const [editJob, setEditJob] = useState(false);
    const [jobToEdit, setJobToEdit] = useState(null);
    const [deleteJob, setDeleteJob] = useState(false);
    const [jobToDelete, setJobToDelete] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);

            const tempBuilders = await Auth.hitEndpointNew('GET', 'builders');
            setBuilders(tempBuilders.filter(b => {
                return parseInt(b.active) === 1;
            }).map(b => {
                b.value = b.id;
                b.label = b.name;
                return b;
            }).sort((b1, b2) => {
                return b1.name < b2.name ? -1 : 1;
            }))

            const tempJobTypes = await Auth.hitEndpointNew("GET", "job/types")
            setJobTypes(tempJobTypes.map(jt => {
                jt.value = jt.id;
                jt.label = jt.description;
                return jt;
            }))

            const tempScheduleTemplates = await Auth.hitEndpointNew("GET", "schedule-templates");
            setScheduleTemplates(tempScheduleTemplates.map(st => {
                st.value = st.id;
                st.label = st.phase.stat_name + ' - ' + st.name;
                return st;
            }).sort((st1, st2) => {
                if(st1.phase.stat_name === st2.phase.stat_name){
                    return st1.name < st2.name ? -1 : 1;
                }
                return st1.phase.stat_name < st2.phase.stat_name ? -1 : 1;
            }))

            const tempUsers = await Auth.hitEndpointNew("GET", "users");
            setGenerals(tempUsers.filter(u => u.position.name === 'general').map(u => {
                u.value = u.id;
                u.label = `${u.field.name} - ${u.firstname} ${u.lastname}`;
                return u;
            }).sort((u1, u2) => u1.field.name < u2.field.name ? -1 : 1));
            setSupers(tempUsers.filter(u => u.position.name === 'super').map(u => {
                u.value = u.id;
                u.label = `${u.field.display} - ${u.firstname} ${u.lastname}`;
                return u;
            }).sort((u1, u2) => u1.field.name < u2.field.name ? -1 : 1));

            let tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
            if(!filters.includeDeleted){
                tempJobs = tempJobs.filter(j => parseInt(j.active) === 1);
            }
            setJobs(tempJobs.sort((j1, j2) => {
                return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;
            }))
        }

        if(!didLoadData){
            loadData();
        }

    }, [filters]);

    const updateJobsArray = async (jobNumber) => {
        let tempJobs = [...jobs];

        const tempJob = (await Auth.hitEndpointNew('GET', 'jobs-new', `queryStr=job.number=${jobNumber}`))[0];

        const index = tempJobs.findIndex(j => parseInt(j.id) === parseInt(tempJob.id));
        if(index >= 0){
            tempJobs[index] = {...tempJob}
        }
        else{
            tempJobs.push({...tempJob});
        }

        if(!filters.includeDeleted){
            tempJobs = tempJobs.filter(j => parseInt(j.active) === 1);
        }
        setJobs(tempJobs.sort((j1, j2) => {
            return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;  
        }))
    }

    const displayMessageWithTimer = (message, textColor, milliseconds) => {
        setMessage({
            color: textColor,
            message: message
        });
        setTimeout(() => {
            setMessage({
                color: '',
                message: ''
            });
        }, milliseconds)
    }

    return (
        <Box sx={{
                margin: '10px'
        }}>
            <JobsFilter
                filters={filters}
                setFilters={setFilters}
            />
            <JobsTable 
                message={message}
                jobs={jobs} 
                setJobs={setJobs}
                setCreateJob={setCreateJob}
                setEditJob={setEditJob}
                setJobToEdit={setJobToEdit}
                setDeleteJob={setDeleteJob}
                setJobToDelete={setJobToDelete}
            />
            {createJob ? 
            <CreateJob 
                displayMessageWithTimer={displayMessageWithTimer}
                updateJobsArray={updateJobsArray}
                builders={builders}
                jobTypes={jobTypes}
                scheduleTemplates={scheduleTemplates}
                generals={generals}
                supers={supers}
                createJob={createJob} 
                setCreateJob={setCreateJob}
            /> 
            : null}
            {editJob && jobToEdit ? 
            <EditJob 
                displayMessageWithTimer={displayMessageWithTimer}
                updateJobsArray={updateJobsArray}
                builders={builders}
                jobTypes={jobTypes}
                scheduleTemplates={scheduleTemplates}
                generals={generals}
                supers={supers}
                editJob={editJob} 
                setEditJob={setEditJob}
                jobToEdit={jobToEdit}
                setJobToEdit={setJobToEdit}
            /> 
            : null}
            {deleteJob && jobToDelete ? 
            <DeleteJob 
                displayMessageWithTimer={displayMessageWithTimer}
                updateJobsArray={updateJobsArray}
                deleteJob={deleteJob} 
                setDeleteJob={setDeleteJob}
                jobToDelete={jobToDelete}
                setJobToDelete={setJobToDelete}
            /> 
            : null}
        </Box>
    );
}

export default JobsPage;