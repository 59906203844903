import { useMemo, useState } from 'react';
import Auth from '../authentication/Auth';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Grid } from '@mui/material';
import { MaterialReactTable } from "material-react-table";
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

const Template = ({updateTable, createScheduleTemplate, setCreateScheduleTemplate, editScheduleTemplate,
                    setEditScheduleTemplate, scheduleTemplate, setScheduleTemplate, phases, phaseTasks}) => {
    const [addTask, setAddTask] = useState(false);

    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedPhaseTask, setSelectedPhaseTask] = useState(null);
    const [days, setDays] = useState(0);

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const columns = useMemo(() => [
        {
            id: 'task',
            accessorFn: (row) => row.task.code === null ? row.task.name : row.task.code + " " + row.task.name,
            header: 'Task',
            size: 300
        },
        {
            id: 'days',
            accessorKey: 'days',
            header: 'Days',
            size: 150
        },
    ], []);

    const removeTask = (index) => {
        const tempConfig = [...scheduleTemplate.config];
        tempConfig.splice(index, 1);
        for(let i = 0; i < tempConfig.length; i++){
            tempConfig[i].order_by = i + 1;
        }
        scheduleTemplate.config = tempConfig;
        setScheduleTemplate({...scheduleTemplate});
    }

    const resequenceTask = (dragged, hovered) => {
        if(!hovered && !dragged) {
            return;
        }

        const tempConfig = [...scheduleTemplate.config];
        tempConfig.splice(hovered.index, 0, tempConfig.splice(dragged.index, 1)[0]);
        for(let i = 0; i < tempConfig.length; i++){
            tempConfig[i].order_by = i + 1;
        }
        scheduleTemplate.config = tempConfig;
        setScheduleTemplate({...scheduleTemplate});
    }

    const addToTemplate = () => {
        const tempConfig = [...scheduleTemplate.config];
        tempConfig.push({
            days: days,
            order_by: tempConfig.length + 1,
            phase_task_id: selectedPhaseTask.id,
            schedule_template_id: scheduleTemplate.id,
            task: selectedPhaseTask
        });
        scheduleTemplate.config = tempConfig;
        setScheduleTemplate({...scheduleTemplate});
        setAddTask(false);
        setSelectedPhase(null);
        setSelectedPhaseTask(null)
        setDays(0);
    }

    const closeWithSave = async () => {
        if(scheduleTemplate.name === null){
            setError({
                type: 'NO NAME',
                message: 'Please enter a name'
            })
            return;
        }
        if(scheduleTemplate.name === null){
            setError({
                type: 'NO DESCRIPTION',
                message: 'Please enter a description'
            })
            return;
        }
        if(scheduleTemplate.phase === null){
            setError({
                type: 'NO PHASE',
                message: 'Please select a phase'
            })
            return;
        }
        for(let i = 0; i < scheduleTemplate.config.length; i++){
            if(!Number.isInteger(scheduleTemplate.config[i].days) || scheduleTemplate.config[i].days < 0){
                setError({
                    type: 'DAYS INCORRECT',
                    message: 'One of the task has an incorrect or empty day amount.'
                })
                return;
            }
        }

        let totalDays = 0;
        for(let i = 0; i < scheduleTemplate.config.length; i++){
            totalDays += scheduleTemplate.config[i].days;
        }
        const dbObj = {
            active: scheduleTemplate.active,
            phase_id: scheduleTemplate.phase.id,
            config: scheduleTemplate.config.map(c => {
                delete c.task;
                return c;
            }),
            created_at: dayjs(scheduleTemplate.created_at).format('YYYY-MM-DD'),
            created_by: scheduleTemplate.created_by,
            description: scheduleTemplate.description,
            modified_at: dayjs(new Date()).format('YYYY-MM-DD'),
            modified_by: await Auth.getUserId(),
            name: scheduleTemplate.name,
            total_days: totalDays
        }
        if(scheduleTemplate.id !== undefined){
            dbObj.id = scheduleTemplate.id;
        }

        await Auth.hitEndpointNew("POST", "schedule-template", "", dbObj);

        await updateTable();
        setScheduleTemplate(null);
        setCreateScheduleTemplate(false);
        setEditScheduleTemplate(false);
    }

    const closeNoSave = () => {
        setScheduleTemplate(null);
        setCreateScheduleTemplate(false);
        setEditScheduleTemplate(false);
    }

    return (

        <Dialog open={createScheduleTemplate || editScheduleTemplate} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Template</DialogTitle>
            <DialogContent sx={{width: '800px', paddingBottom: '220px'}}>
                <FormControlLabel 
                    sx={{
                        margin: '10px 0'
                    }}
                    control={<Checkbox 
                        defaultChecked={scheduleTemplate.active ? true : false} 
                        onChange={(e) => setScheduleTemplate({...scheduleTemplate, active: e.target.checked})}/>} 
                    label="Active" 
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                margin: '0px auto 20px',
                            }
                        },
                        container: styles => {
                            return {
                                ...styles,
                                zIndex: '100'
                            }
                        }
                    }} 
                    placeholder="Select Phase"
                    value={scheduleTemplate.phase}
                    onChange={(phase) => setScheduleTemplate({...scheduleTemplate, phase: phase})}
                    options={phases}
                    isClearable
                    isSearchable
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 20px',
                    }}
                    size='small'
                    label="Name"
                    placeholder="Enter Name"
                    value={scheduleTemplate.name}
                    onChange={(e) => setScheduleTemplate({...scheduleTemplate, name: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 20px',
                    }}
                    size='small'
                    label="Description"
                    placeholder="Enter Description"
                    value={scheduleTemplate.description}
                    onChange={(e) => setScheduleTemplate({...scheduleTemplate, description: e.target.value})}
                />
                <MaterialReactTable
                    columns={columns}
                    data={scheduleTemplate.config}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    enablePagination={false}
                    layoutMode='semantic'
                    muiTableHeadCellProps={({column}) => {
                        const align = column.id === 'task' || column.id === 'mrt-row-actions' ? 'left' : 'center';
                        return {
                            align: align,
                            sx: {
                                backgroundColor: 'rgb(0, 150, 255)',
                            }
                        }
                    }}
                    muiTableBodyCellProps={({row, cell}) => {
                        let align = cell.column.id === 'task' ? 'left' : 'center';
                        return {
                            align: align,
                            sx: {
                                padding: '0',
                                backgroundColor: row.index % 2 === 0 ? 'rgb(137, 207, 240)' : 'rgb(125, 249, 255)'
                            }
                        }
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return {
                            hover: false,
                        }
                    }}
                    enableRowOrdering
                    muiRowDragHandleProps={({table}) => {
                        return {
                            onDragEnd: () => {
                                const { draggingRow, hoveredRow } = table.getState();
                                resequenceTask(draggingRow, hoveredRow);
                            }
                        }
                    }}
                    enableRowActions={true}
                    renderRowActions={({ row }) => {
                        return (
                            <Box>
                                <Tooltip title="Delete">
                                    <IconButton color="error" onClick={() => {
                                        removeTask(row.index);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                    }}
                />
                <Tooltip title="Add New Task To Template">
                    <IconButton onClick={async () => setAddTask(true)}>
                        <AddBoxIcon />
                    </IconButton>
                </Tooltip>
                {addTask ?
                <Grid
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                        alignItems: 'center',
                        margin: '10px auto',
                    }}
                >
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    margin: '0 3px',
                                }
                            },
                            container: styles => {
                                return {
                                    ...styles,
                                    gridColumn: '1/9',
                                    zIndex: '100'
                                }
                            }
                        }} 
                        placeholder="Select Phase"
                        value={selectedPhase}
                        onChange={(phase) => setSelectedPhase(phase)}
                        options={phases}
                        isClearable
                        isSearchable
                    />
                    <Select 
                        styles={{
                            control: styles => {
                                return {
                                    ...styles,
                                    margin: '0 3px',
                                }
                            },
                            container: styles => {
                                return {
                                    ...styles,
                                    gridColumn: '9/16',
                                    zIndex: '99'
                                }
                            }
                        }} 
                        placeholder="Select Phase Task"
                        value={selectedPhaseTask}
                        onChange={(phaseTask) => setSelectedPhaseTask(phaseTask)}
                        options={selectedPhase !== null ? phaseTasks.filter(pt => pt.phases.filter(x => x.id === selectedPhase.id).length > 0) : []}
                        isClearable
                        isSearchable
                    />
                    <TextField
                        sx={{
                            gridColumn: '16/18',
                            width: '100%',
                            margin: '0 3px'
                        }}
                        size='small'
                        label="Days"
                        type="number"
                        placeholder="Enter Days"
                        value={days}
                        onChange={(e) => setDays(parseInt(e.target.value))}
                    />
                    <Button 
                        variant='contained' 
                        onClick={addToTemplate}
                        sx={{
                            gridColumn: '19/21'
                        }}>Add</Button>
                </Grid>: null}
                {error.type !== "" &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
};

export default Template;