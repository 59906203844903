import { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import Auth from '../authentication/Auth.js';
import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import {MaterialReactTable} from "material-react-table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box } from '@mui/material';
import Button  from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const Gates = () => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [cursor, setCursor] = useState('default');

    const [gates, setGates] = useState([]);
    const [editGate, setEditGate] = useState(null);
    const [deleteGate, setDeleteGate] = useState(null);

    const [loadParameters, setLoadParameters] = useState({
        startDate: (dayjs()).subtract(7, 'day'),
        endDate: (dayjs()).add(1, 'day'),
    });

    const columns = useMemo(() => [
        {
            id: 'job_number',
            accessorKey: 'lot.job.number',
            header: 'Job #',
            size: 150
        },
        {
            id: 'lot_number',
            accessorKey: 'lot.number',
            header: 'Lot #',
            size: 150
        },
        {
            id: 'lot_phase',
            accessorKey: 'lot.phase',
            header: 'Lot Phase',
            size: 150
        },
        {
            accessorKey: 'lot.job.project_name',
            header: 'Project'
        },
        {
            id: 'built',
            accessorFn: (row) => row.built ? 'X' : '',
            header: 'Built'
        },
        {
            id: 'shipped',
            accessorFn: (row) => row.shipped ? 'X' : '',
            header: 'Shipped',
        },
        {
            id: 'invoice_number',
            accessorKey: 'invoice_number',
            header: 'Invoice #'
        },
        {
            accessorFn: (row) => row.measurement_type !== null ? `${row.width} ${row.measurement_type.name}` : row.width,
            header: 'Width'
        },
        {
            accessorFn: (row) => row.handedness !== null ? row.handedness.label : '',
            header: 'Handedness'
        },
        {
            accessorFn: (row) => row.decorative_iron ? 'X' : '',
            header: 'Decorative Iron'
        },
        {
            accessorFn: (row) => row.level !== null ? row.level.label : '',
            header: 'Level'
        },
        {
            accessorFn: (row) => row.user !== null ? `${row.user.firstname} ${row.user.lastname}` : '',
            header: 'Created By'
        },
    ], []);

    const loadData = async () => {
        let whereStr = 'queryString=gate_information.width > 0';
        if(loadParameters.startDate !== null){
            whereStr += ` and gate_information.created_at >= '${dayjs(new Date(loadParameters.startDate)).format('YYYY-MM-DD')}'`;
        }
        if(loadParameters.endDate !== null){
            whereStr += ` and gate_information.created_at < '${dayjs(new Date(loadParameters.endDate)).format('YYYY-MM-DD')}'`;
        }
        const tempLots = await Auth.hitEndpointNew("GET", `lots/index-new`, whereStr);
        const tempGates = tempLots.map(l => {
            l.gate_information.forEach(g => g.lot = l);
            return l.gate_information;
        }).flat();
        setGates(tempGates);
    }

    if(!didLoadData){
        setDidLoadData(true);
        loadData();
    }

    const handleSaveGate = async () => {
        const tempGates = [...gates];
        const index = tempGates.findIndex(g => parseInt(g.id) === parseInt(editGate.gateId));
        if(index >= 0){
            if(editGate.editing === 'built'){
                tempGates[index].built = editGate.built;
            }
            if(editGate.editing === 'shipped'){
                tempGates[index].shipped = editGate.shipped;
            }
            if(editGate.editing === 'invoice_number'){
                tempGates[index].invoice_number = editGate.invoice_number;
            }
        }
        setGates([...tempGates])
        

        const dbObj = {
            id: editGate.lot.id,
            gate_information: editGate.lot.gate_information.map(g => {
                return {
                    id: g.id,
                    built: g.built,
                    shipped: g.shipped,
                    invoice_number: g.invoice_number
                }
            })
        }

        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', dbObj);

        setEditGate(null);
    }

    const handleDeleteGate = async () => {
        const tempGates = [...gates];
        const index = tempGates.findIndex(g => parseInt(g.id) === parseInt(deleteGate.id));
        if(index >= 0){
            tempGates[index].deleted_by = await Auth.getUserId();
            tempGates[index].deleted_at = dayjs(new Date(new Date())).format('YYYY-MM-DD');
        }
        setGates([...tempGates])
        
        const dbObj = {
            id: deleteGate.lot.id,
            gate_information: deleteGate.lot.gate_information.map(g => {
                return {
                    id: g.id,
                    deleted_at: g.deleted_at,
                    deleted_by: g.deleted_by
                }
            })
        }

        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', dbObj);

        setDeleteGate(null);
    }

    return (
        <Grid 
            sx={{
                display: 'grid',
                margin: '10px 10px 0 10px'
            }}
        >
            <Grid 
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: '10px',
                    rowGap: '10px',
                    width: '600px',
                    padding: '10px',
                    border: '2px solid rgb(220, 220, 220)',
                    borderRadius: '3px',
                    marginBottom: '10px'
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, startDate: date})}
                        label={"Start Date"}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {
                                    gridColumn: '1/2',
                                },
                            },
                        }}
                        defaultValue={loadParameters.startDate}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, endDate: date})}
                        label={"End Date"}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {
                                    gridColumn: '2/3',
                                },
                            },
                        }}
                        value={loadParameters.endDate}
                    />
                </LocalizationProvider>
                <Button 
                    variant='contained'
                    sx={{
                        gridColumn: '1/3',
                        justifySelf: 'center',
                        width: '180px'
                    }}
                    onClick={loadData}
                >Update Table</Button>
            </Grid>
            <MaterialReactTable
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                columns={columns}
                data={gates.filter(g => g.deleted_at === null)}
                enableSorting
                layoutMode="grid-no-grow"
                enableColumnResizing
                enableStickyHeader
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {left: ['mrt-row-actions', 'job_number', 'lot_number', 'lot_phase']}
                }}
                muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                        cursor: cursor,
                    },
                    onMouseOver: () => {
                        if(cell.column.id === 'built' || cell.column.id === 'shipped' || cell.column.id === 'invoice_number'){
                            setCursor('pointer');
                        }
                        else{
                            setCursor('default');
                        }
                    },
                    onClick: () => {
                        const tempEditGate = gates.find(g => parseInt(g.id) === parseInt(cell.row.original.id));
                        if(tempEditGate !== undefined){
                            const tempEditObj = {
                                lot: tempEditGate.lot,
                                gateId: tempEditGate.id
                            };
                            if(cell.column.id === 'built'){
                                tempEditObj.editing = 'built';
                                tempEditObj.built = tempEditGate.built;
                            }
                            if(cell.column.id === 'shipped'){
                                tempEditObj.editing = 'shipped';
                                tempEditObj.shipped = tempEditGate.shipped;
                            }
                            if(cell.column.id === 'invoice_number'){
                                tempEditObj.editing = 'invoice_number';
                                tempEditObj.invoice_number = tempEditGate.invoice_number !== null ? tempEditGate.invoice_number : '';
                            }
                            setEditGate(tempEditObj);
                        }
                    }
                })}
                enableRowActions={true}
                renderRowActions={({ row}) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => {
                                    const gate = row.original;
                                    if(gate.built || gate.shipped){
                                        return;
                                    }
                                    setDeleteGate(row.original)
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                }}
            />
            {editGate !== null && 
            <Dialog open={editGate.editing === 'built' || editGate.editing === 'shipped' || editGate.editing === 'invoice_number'}>
                {editGate.editing === 'built' ? 
                <DialogTitle style={{borderBottom: "2px solid black"}}>Mark Gate Built</DialogTitle> :
                editGate.editing === 'shipped' ? 
                <DialogTitle style={{borderBottom: "2px solid black"}}>Mark Gate Shipped</DialogTitle> :
                <DialogTitle style={{borderBottom: "2px solid black"}}>Gate Invoice Number</DialogTitle>}
                <DialogContent sx={{minWidth: '600px'}}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            columnGap: '3px',
                            rowGap: '5px',
                            margin: '10px auto',
                        }}
                    >
                        <Box
                            sx={{
                                gridColumn: '1/2',
                                justifySelf: 'end',
                                fontWeight: 'bold'
                            }}
                        >Job:</Box>
                        <Box
                            sx={{
                                gridColumn: '2/4',
                            }}
                        >
                            {`${editGate.lot.job.number} - ${editGate.lot.job.project_name}`}
                        </Box>
                        <Box
                            sx={{
                                gridColumn: '1/2',
                                justifySelf: 'end',
                                fontWeight: 'bold'
                            }}
                        >Lot:</Box>
                        <Box
                            sx={{
                                gridColumn: '2/4',
                            }}
                        >
                            {editGate.lot.phase !== '' ? `${editGate.lot.number} - ${editGate.lot.phase}` : editGate.lot.number}
                        </Box>
                        {editGate.editing === 'built' ? 
                        <FormControlLabel 
                            sx={{
                                gridColumn: '1/4',
                                justifySelf: 'center',
                            }}
                            control={<Checkbox 
                                defaultChecked={editGate.built} 
                                onChange={(e) => setEditGate({...editGate, built: e.target.checked})}/>} 
                            label="Built" 
                        /> :
                        editGate.editing === 'shipped' ?
                        <FormControlLabel 
                            sx={{
                                gridColumn: '1/4',
                                justifySelf: 'center',
                            }}
                            control={<Checkbox 
                                defaultChecked={editGate.shipped} 
                                onChange={(e) => setEditGate({...editGate, shipped: e.target.checked})}/>} 
                            label="Shipped" 
                        /> :
                        <TextField
                            sx={{
                                gridColumn: '1/4',
                                justifySelf: 'center',
                                marginTop: '10px'
                            }}
                            placeholder="Enter Invoice #"
                            value={editGate.invoice_number}
                            onChange={(e) => setEditGate({...editGate, invoice_number: e.target.value})}
                        />}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveGate}>Save</Button>
                    <Button onClick={() => setEditGate(null)}>Cancel</Button>
                </DialogActions>
            </Dialog>}
            {deleteGate !== null && 
            <Dialog open={deleteGate !== null}>
                <DialogTitle style={{borderBottom: "2px solid black"}}>Delete Gate</DialogTitle>
                <DialogContent sx={{minWidth: '600px'}}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            columnGap: '3px',
                            rowGap: '8px',
                            margin: '10px auto',
                        }}
                    >
                        <Box
                            sx={{
                                gridColumn: '1/2',
                                justifySelf: 'end',
                                fontWeight: 'bold'
                            }}
                        >Job:</Box>
                        <Box
                            sx={{
                                gridColumn: '2/4',
                            }}
                        >
                            {`${editGate.lot.job.number} - ${editGate.lot.job.project_name}`}
                        </Box>
                        <Box
                            sx={{
                                gridColumn: '1/2',
                                justifySelf: 'end',
                                fontWeight: 'bold'
                            }}
                        >Lot:</Box>
                        <Box
                            sx={{
                                gridColumn: '2/4',
                            }}
                        >
                            {editGate.lot.phase !== '' ? `${editGate.lot.number} - ${editGate.lot.phase}` : editGate.lot.number}
                        </Box>
                        <Box
                            sx={{
                                gridColumn: '2/4',
                            }}
                        >
                            Confirm Delete Gate?
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleDeleteGate}>Ok</Button>
                    <Button variant='contained' onClick={() => setDeleteGate(null)}>Cancel</Button>
                </DialogActions>
            </Dialog>}
        </Grid>
    );
}

export default Gates;