import { useEffect, useMemo, useState} from 'react';
import dayjs from "dayjs";
import Auth from '../authentication/Auth.js';
import {MaterialReactTable} from "material-react-table";
import Button  from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Switch } from '@mui/material';
import Select, { createFilter } from "react-select";
import { filterConfig } from '../utilities/FilterConfig.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Can from '../authentication/Can';

const LaborHours = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        date: null,
        crews: [],
        employees: [],
        includeDeletedEmployees: false
    });

    const [loadingButton, setLoadingButton] = useState({
        text: 'Get Employees',
        disabled: false
    })

    const [error, setError] = useState('');

    const [users, setUsers] = useState([]);
    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [editedEmployeeLaborHours, setEditedEmployeeLaborHours] = useState(null);

    const canEditLaborHours = Can.isAuthorized('laborHours', 'WRITE');


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.first_name} ${row.last_name}`,
                header: 'Employee',
                size: 400,
                enableEditing: false
            },
            {
                accessorFn: (row) => `${row.laborHours.day.format('MM-DD-YYYY')}`,
                header: 'Date',
                size: 150,
                enableEditing: false
            },
            {
                id: 'hours',
                accessorKey: 'laborHours.hours',
                header: 'Total Hours',
                size: 150,
                enableEditing: canEditLaborHours,
                muiEditTextFieldProps: ({cell, row}) => ({
                    type: 'text',
                    onBlur: (e) => {
                        setEditedEmployeeLaborHours({
                            ...row.original.laborHours,
                            hours: e.target.value
                        });
                    }
                })
            },
            {
                accessorFn: (row) => {
                    if(!row.laborHours.modified_by){
                        return '';
                    }
                    const modifiedBy = users.find(u => parseInt(u.id) === parseInt(row.laborHours.modified_by));
                    return modifiedBy ? modifiedBy.firstname + ' ' + modifiedBy.lastname : '';
                },
                header: 'Modified By',
                size: 400,
                enableEditing: false
            },
        ],
        [users, canEditLaborHours]
    );

    useEffect(() => {
        const updateLaborHours = async () => {
            // Update database
            const dbObj = {
                id: editedEmployeeLaborHours.id,
                employee_id: editedEmployeeLaborHours.employee_id,
                day: editedEmployeeLaborHours.day.format('YYYY-MM-DD H:mm:ss'),
                hours: editedEmployeeLaborHours.hours,
                modified_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss'),
                modified_by: await Auth.getUserId(),
            }
            await Auth.hitEndpointNew('POST', 'employee/labor-hours', '', dbObj);

            // Get updated employee from database
            const updatedEmployee = (await Auth.hitEndpointNew('GET', 'employees-new', `jsonString=employee.id=${editedEmployeeLaborHours.employee_id}`))[0];
            const start = filters.date.hour(0).minute(0).second(0);
            const end = filters.date.hour(23).minute(59).second(59);
            const tempLaborHours = updatedEmployee.labor_hours.find(lh => {
                const day = dayjs(lh.day);
                return day >= start && day <= end;
            });
            updatedEmployee.laborHours = {
                id: tempLaborHours ? tempLaborHours.id : null,
                employee_id: updatedEmployee.id,
                day: tempLaborHours ? dayjs(tempLaborHours.day) : filters.date,
                hours: tempLaborHours ? tempLaborHours.hours : 0,
                modified_by: tempLaborHours ? tempLaborHours.modified_by : 0,
                modified_at: tempLaborHours ? tempLaborHours.modified_at : null,
            }
            
            // Update employees list
            let index = employees.findIndex(e => parseInt(e.id) === parseInt(updatedEmployee.id));
            employees[index] = updatedEmployee;
            setEmployees([...employees]);
            
            // Update selectedEmployees list
            index = selectedEmployees.findIndex(se => parseInt(se.id) === parseInt(updatedEmployee.id));
            selectedEmployees[index] = updatedEmployee;
            setSelectedEmployees([...selectedEmployees]);

            setEditedEmployeeLaborHours(null);
        }
        
        if(editedEmployeeLaborHours){
            updateLaborHours();
        }
    }, [editedEmployeeLaborHours, selectedEmployees, employees, filters])

    const loadData = async () => {
        setDidLoadData(true);
        setLoadingButton({
            text: 'Loading Page',
            disabled: true
        });

        const tempUsers = await Auth.hitEndpointNew('GET', 'users');
        setUsers([...tempUsers]);

        const tempCrews = await Auth.hitEndpointNew("GET", "crews-new");
        setCrews(tempCrews.filter(c => parseInt(c.active) === 1).map(c => {
            const crewLeader = c.members && c.members.length > 0 ? c.members.find(m => m.position === 'Leader') : null;
            return {
                ...c,
                value: c.id,
                label: crewLeader && crewLeader.employee ? `${c.number} - ${crewLeader.employee.first_name} ${crewLeader.employee.last_name}` : c.number
            }
        }).sort((c1, c2) => {
            return parseInt(c1.number) < parseInt(c2.number) ? -1 : 1;
        }));

        const tempEmployees = await Auth.hitEndpointNew('GET', 'employees-new');
        setEmployees(tempEmployees.filter(e => {
            if(!filters.includeDeletedEmployees){
                return parseInt(e.active) === 1;
            }
            return true;
        }).map(e => {
            return {
                ...e,
                value: e.id,
                label: `${e.first_name} ${e.last_name}`
            }
        }))

        setLoadingButton({
            text: 'Get Employees',
            disabled: false
        });
    }

    if(!didLoadData){
        loadData();
    }

    const handleLoadEmployees = async () => {
        if(filters.date === null){
            setError('NO_DATE_SELECTED');
            return;
        }
        if(filters.crews.length === 0 && filters.employees.length === 0){
            setError('NO_CREW_OR_EMPLOYEE_SELECTED');
            return;
        }
        setError('');

        // Combine crew selected employees and employees selected employees into one array
        let tempCombinedEmployees = [...filters.employees];
        for(let i = 0; i < filters.crews.length; i++){
            for(let k = 0; k < filters.crews[i].members.length; k++){
                const crewMember = employees.find(e => parseInt(e.id) === parseInt(filters.crews[i].members[k].employee.id))
                if(crewMember){
                    tempCombinedEmployees.push(crewMember);
                }
            }
        }

        // Remove employees listed twice
        const repeatEmployeeIds = [];
        const tempSelectedEmployees = tempCombinedEmployees.filter(e => {
            if(repeatEmployeeIds.find(id => id === parseInt(e.id))){
                return false;
            }
            repeatEmployeeIds.push(parseInt(e.id));
            return true;
        });

        // Setup each employee with labor hours data for date selected
        for(let i = 0; i < tempSelectedEmployees.length; i++){
            const start = filters.date.hour(0).minute(0).second(0);
            const end = filters.date.hour(23).minute(59).second(59);
            const laborHoursData = tempSelectedEmployees[i].labor_hours.find(lh => {
                const day = dayjs(lh.day);
                return day >= start && day <= end;
            })
            tempSelectedEmployees[i].laborHours = {
                id: laborHoursData ? laborHoursData.id : null,
                employee_id: tempSelectedEmployees[i].id,
                day: laborHoursData ? dayjs(laborHoursData.day) : filters.date,
                hours: laborHoursData ? laborHoursData.hours : 0,
                modified_by: laborHoursData ? laborHoursData.modified_by : 0,
                modified_at: laborHoursData ? laborHoursData.modified_at : null,
            }
        }
        setSelectedEmployees(tempSelectedEmployees);
    }

    return (
        <Box
            sx={{
                display: 'grid',
                margin: '10px 0 0 10px'
            }}
        >
            <Box 
                sx={{ 
                    display: 'grid',
                    width: '800px',
                    border: '2px solid rgb(220, 220, 220)',
                    borderRadius: '3px',
                    marginBottom: '10px',
                    padding: '0 30px'
                }}
            >
                <Box sx={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    padding: '8px 0'}}
                >Labor Hours Required Fields</Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setFilters({...filters, date: date})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                size: 'small',
                                sx: {
                                    justifySelf: 'left',
                                    width: '300px', 
                                    backgroundColor: error === "NO_DATE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                },
                            },
                        }}
                        value={filters.date}
                    />
                </LocalizationProvider>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '46% 8% 46%',
                        margin: '10px 0',
                    }}
                >
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '100%',
                                    justifySelf: 'left',
                                    zIndex: '49',
                                }
                            },
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "NO_CREW_OR_EMPLOYEE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            }
                        }}
                        placeholder="Select Crew"
                        value={filters.crews}
                        onChange={(crews) => setFilters({...filters, crews: crews})}
                        options={crews}
                        isMulti
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig(true, true, true, true))}
                    />
                    <Box
                        sx={{
                            justifySelf: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        and/or
                    </Box>
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '100%',
                                    justifySelf: 'right',
                                    zIndex: '48',
                                }
                            },
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "NO_CREW_OR_EMPLOYEE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            }
                        }}
                        placeholder="Select Employee"
                        value={filters.employees}
                        onChange={(employees) => setFilters({...filters, employees: employees})}
                        options={employees}
                        isMulti
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig(false, true, true, true))}
                    />
                </Box>
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        value={filters.includeDeletedEmployees}
                        onChange={(e) => setFilters({...filters, includeDeletedEmployees: e.target.checked})}/>} 
                    label="Include Deleted Employees" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    disabled={loadingButton.disabled}
                    variant='contained'
                    onClick={handleLoadEmployees}
                >{loadingButton.text}</Button>
            </Box>
            <Box 
                sx={{
                    margin: '10px',
                    width: '1300px'
                }
            }>
                <MaterialReactTable
                    muiTableContainerProps={{ sx: { maxWidth: '1300px', maxHeight: '500px' } }}
                    columns={columns}
                    data={selectedEmployees}
                    enableSorting
                    layoutMode="grid-no-grow"
                    enableStickyHeader
                    editDisplayMode='cell'
                    enableEditing
                    enableCellActions
                    getRowId={(row) => row.id}
                />
            </Box>
        </Box>
    );
}

export default LaborHours;
