import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const PayweekContainer = styled.div`
     display: grid;
     box-sizing: border-box;

    .date-range-filter-box{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        margin: 3px 0;
        
        .job-filter-select,
        .company-filter-select,
        .phase-filter-select,
        .approval-status-filter-select{
            display: grid;
            grid-column: 1/11;
        }

        .lot-filter-select,
        .crew-filter-select,
        .employee-filter-select,
        .completion-status-filter-select{
            display: grid;
            grid-column: 11/21;
        }
        
        .date-search-type-filter-select{
            display: grid;
            grid-column: 4/16;
            grid-row: 5/6;
        }
        
        .start-date-filter{
            display: grid;
            grid-column: 2/11;
            grid-row: 1/4;
            width: 75%;
        }
        .end-date-filter{
            display: grid;
            grid-column: 12/21;
            grid-row: 1/4;
            width: 75%;
        }
        .date-connector{
            display: grid;
            grid-column: 10/21;
            grid-row: 1/4;
            margin-top: 40px;
        }
    } 
`

export const PayWeekPicker = ({start, end, setStart, setEnd}) => {

    return (
        <PayweekContainer>
            <div className='date-range-filter-box'>
                <div className='start-date-filter'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => {
                                if(date !== null) {
                                    const saturday = dayjs(date).weekday() === 6 ? dayjs(date) : dayjs(date).weekday(0).add(-1, 'd')
                                    let friday = saturday.add(6, 'd')
                                    setStart(saturday)
                                    setEnd(friday)
                                }
                            }}
                            slotProps={{
                                textField: {
                                    required: true,
                                    helperText: 'Start Date',
                                    sx: {minWidth: '120px', paddingTop: '20px'},
                                    variant: 'standard',
                                },
                                field: { clearable: true, onClear: () => {
                                        setStart(null)
                                        setEnd(null)
                                    }
                            }}}
                            required={false}
                            sx={{backgroundColor: 'white'}}
                            value={dayjs(start)}
                            selected={start}
                        />
                    </LocalizationProvider>
                </div>
                <div className={"date-connector"}>
                    <span> To </span>
                </div>
                <div className='end-date-filter'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            readOnly
                            slotProps={{
                                textField: {
                                    required: false,
                                    helperText: 'End Date',
                                    sx: {minWidth: '120px', paddingTop: '20px'},
                                    variant: 'standard',
                                },
                            }}
                            value={dayjs(end)}
                            selected={end}
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </PayweekContainer>
    );
}