import { useState } from 'react';
import dayjs from 'dayjs';
import Auth from '../authentication/Auth.js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button  from "@mui/material/Button";
import Select from "react-select";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {IconButton, Switch, Tooltip} from '@mui/material';
import TextField from "@mui/material/TextField";
import {Clear, Delete} from "@mui/icons-material";

const LotsFilter = ({displayMessageWithTimer, jobs, phases, phaseStatus, setLots}) => {
    const [filters, setFilters] = useState({
        job: null,
        phase: null,
        phaseStatus: null,
        address: '',
        startDate: null,
        endDate: null,
        includeDeleted: false,
        byBlueStake: false,
    })

    const [loading, setLoading] = useState({
        isLoading: false,
        buttonText: 'Get Lots'
    })

    const applyFilters = async () => {
        if(loading.isLoading){
            return;
        }

        if( filters.job === null  && filters.phase === null  && filters.phaseStatus === null && filters.address === ''){
            displayMessageWithTimer('No Filters Selected', 'red', 5000);
            return;
        }
        if(filters.phase !== null && filters.phaseStatus === null){
            displayMessageWithTimer('Phase With No Phase Status', 'red', 5000);
            return;
        }

        setLoading({
            isLoading: true,
            buttonText: 'Fetching Lots...'
        })

        let queryString = '';
        if(!filters.includeDeleted){
            queryString += `lot.active=1 and `;
        }
        if(filters.job !== null){
            queryString += `lot.job_id=${filters.job.value} and `;
        }
        if(filters.phase !== null){
            queryString += `job_attribute.attribute_id = ${filters.phase.job_attribute_id} and `
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value === "Not Released"){
            if(filters.phase !== null){
                queryString += `lot_phase.release_date is null and (lot_phase.phase_id=${filters.phase.value} or ` +
                `lot.id not in (select lot.id from lot join lot_phase on lot_phase.lot_id = lot.id where lot_phase.phase_id = ${filters.phase.value})) and `;
            }
            else{
                queryString += 'lot_phase.release_date is null and ';
            }
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value === "Released"){
            if(filters.startDate !== null){
                queryString += `lot_phase.release_date>'${dayjs(new Date(filters.startDate)).format('YYYY-MM-DD')}' and `;
            }
            if(filters.endDate !== null){
                queryString += `lot_phase.release_date<='${dayjs(new Date(filters.endDate)).format('YYYY-MM-DD')}' and `;
            }
            if(filters.startDate === null && filters.endDate === null){
                queryString += 'lot_phase.release_date is not null and ';
            }
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value !== "Not Released" && filters.phaseStatus.value !== "Released"){
            if(filters.phase !== null){
                queryString += `lot_phase.starts_status='${filters.phaseStatus.value}' and lot_phase.phase_id=${filters.phase.value} and `;
            }
            else{
                queryString += `lot_phase.starts_status='${filters.phaseStatus.value}' and `;
            }
        }
        if(filters.address !== ''){
            queryString += `lot.address like ${filters.address} and `
        }

        // Trim 'and' off end of whereStr
        if(queryString !== ''){
            queryString = queryString.slice(0, queryString.length - 5);
        }

        let tempLots = await Auth.hitEndpointNew('GET', `lots/index-new`, `queryString=${queryString}`);
        if(tempLots.length === 0){
            displayMessageWithTimer('No Lots Match Filter Parameters', 'red', 5000);
        }
        if(filters.byBlueStake){
            const withoutBluestake = tempLots.filter(l => l.bluestake.length === 0);
            const withBluestake = tempLots.filter(l => l.bluestake.length > 0);
            withoutBluestake.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
            withBluestake.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
            tempLots = [...withoutBluestake, ...withBluestake];
        }
        else{
            tempLots.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
        }

        setLots(tempLots);
        setLoading({
            isLoading: false,
            buttonText: 'Get Lots'
        })
    }

    const clearFilters = () => {
        setFilters({
            job: null,
            phase: null,
            phaseStatus: null,
            address: '',
            startDate: null,
            endDate: null,
            includeDeleted: false,
            byBlueStake: false,
        })
        setLots([]);
        setLoading({
            isLoading: false,
            buttonText: 'Get Lots'
        })
    }

    return (
        <Grid 
            sx={{
                display: 'grid',
                width: 600, 
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '5px',
                zIndex: '49'
            }}
        >
            <Grid
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '7fr 0.5fr',
                }}
            >
                <Grid
                    sx={{
                         gridColumn: '1/2',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        textAlign: 'right',
                        margin: '10px auto 0',
                        justifySelf: 'end'
                    }}
                >
                    Lot Filters
                </Grid>
                <Grid
                    sx={{
                        gridColumn: '2/2',
                        textAlign: 'right',
                        justifySelf: 'end'
                    }}
                >
                    <Tooltip title={'Reset Filters'} sx={{color: 'darkred'}}>
                        <IconButton onClick={clearFilters}>
                            <Clear/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid   sx={{
                display: 'grid',
                marginTop: '10px',
                width: '100%',
            }}>
                <Select
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                marginLeft: '24px',
                                marginRight: '24px',
                                marginTop: '10px',
                            }
                        }
                    }}
                    placeholder="Select A Job"
                    value={filters.job}
                    onChange={(job) => setFilters({...filters, job: job})}
                    options={jobs}
                    isClearable
                    isSearchable
                />
            </Grid>
            <Grid
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    marginTop: '10px',
                }}
            >
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: '250px',
                                margin: '10px auto 0',
                            }
                        }
                    }} 
                    placeholder="Select A Phase"
                    value={filters.phase}
                    onChange={(phase) => setFilters({...filters, phase: phase})}
                    options={phases}
                    isClearable
                    isSearchable
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: '250px',
                                margin: '10px auto 0',
                            }
                        }
                    }} 
                    placeholder="Select Phase Status"
                    value={filters.phaseStatus}
                    onChange={(phaseStatus) => setFilters({...filters, phaseStatus: phaseStatus})}
                    options={phaseStatus}
                    isClearable
                    isSearchable
                />
                <Grid
                    sx={{
                        display: 'grid',
                        margin: '10px auto 0',
                        width: '250px',
                        paddingTop: '10px',
                    }}
                >
                    Start Date
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setFilters({...filters, startDate: date})}
                            value={filters.startDate}
                            sx={{backgroundColor: 'white'}}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid
                    sx={{
                        display: 'grid',
                        margin: '10px auto 0',
                        width: '250px',
                        paddingTop: '10px',
                    }}
                >
                    End Date
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setFilters({...filters, endDate: date})}
                            value={filters.endDate}
                            sx={{backgroundColor: 'white'}}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid   sx={{
                display: 'grid',
                marginTop: '20px',
                width: '100%',
            }}>
                <TextField
                    id='search-address'
                    placeholder={"Search for an Address"}
                    sx={{
                        marginLeft: '24px',
                        marginRight: '24px',
                        backgroundColor: 'white',
                    }}
                    onChange={(e) => {
                        setFilters({...filters, address: e.target.value})
                    }}
                    value={filters.address}
                >
                </TextField>
            </Grid>
            <Grid
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                <Grid
                    sx={{
                        display: 'grid',
                        margin: '10px auto 0',
                        width: '250px',
                    }}
                >
                    <FormControlLabel
                        control={<Switch id={"include=deleted-lots"}
                                         checked={filters.includeDeleted}
                                         onChange={(e) => setFilters({...filters, includeDeleted: e.target.checked})}/>}
                        label="Included Deleted Lots"
                    />
                </Grid>
                <Grid
                    sx={{
                        display: 'grid',
                        margin: '10px auto 0',
                        width: '250px',
                    }}
                >
                    <FormControlLabel
                        control={<Switch id={"sort-by-bluestake"}
                                         checked={filters.byBlueStake}
                                         onChange={(e) => setFilters({...filters, byBlueStake: e.target.checked})}/>}
                        label="Sort By Bluestake"
                    />
                </Grid>
                <Grid
                    sx={{
                        display: 'grid',
                        margin: '10px auto 0',
                        width: '250px',
                        gridColumn: '2/2'
                    }}
                >
                    <Box
                        sx={{
                            justifySelf: 'end'
                        }}
                    >
                        <Button
                            sx={{
                                justifySelf: 'end',
                                margin: '10px 15px 10px 0',
                                width: '180px',
                            }}
                            disabled={loading.isLoading}
                            variant='contained'
                            onClick={applyFilters}

                        >{loading.buttonText}</Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LotsFilter;