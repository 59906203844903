
import styled from 'styled-components';
import {useState} from "react";
import {
    Block,
    CalendarMonth,
    DoNotDisturb,
    Error,
    Lock,
    NavigateBefore,
    PanTool, RemoveCircleOutline,
    Stop,
    Verified
} from "@mui/icons-material";
import Schedules from "../../classes/Schedules";
import PhaseTasks from "../../classes/PhaseTasks";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat.js';

dayjs.extend(advancedFormat);

export const ListBoxContainer = styled.div`
    .checklist{
        height: 100%;
    }
    .list-container {
        background-color: white;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .list-container-base {
        height: 475px;
    }
    .list-container-request {
        max-height: 475px;
    }
    .selected {
        background: skyblue;
    }
    tr{
        height: 35px;
    }
    table tr:nth-child(2) {background-color : '#E0E0E0';}
    table tr:nth-child(3) {display : none;}
    
    th, td {
        border: 1px solid black;
        padding: 4px 6px;
        vertical-align: middle;
        min-height: 12px;
    }
    
    th{
        text-transform: capitalize;
        text-align: left;
        background-color: #C8C8C8;
        position: sticky;
        top: 0px;
        cursor: default;
    }

`
export const AvailableLots = ({data, columns, disabled, selected, setSelected, scheduleDate, selectedPhaseId, selectedTasks, handleLoadSchedule, setAvailableLotsFiltered, isRequest=false}) => {

    const [lastSelectedIdx, setLastSelectedIdx] = useState(null)

    const handleSelected = async (e) => {
        let tr
        let id = e.target.id !== undefined && e.target.title !== undefined ? e.target.id.split('-')[1] : e.target.parentNode.id.split('-')[1]
        let index = e.target.id !== undefined && e.target.title !== undefined ? parseInt(e.target.title.split(' - ')[2]) : parseInt(e.target.parentNode.parentNode.title.split(' - ')[2])
        let clickedSelection = data.find(x => x.id == id)
        let selections = []

        if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
            for (let d = 0; d < data.length; d++) {
                if (data[d].id !== id) {
                    tr = document.getElementById(`ald-${data[d].id}`)
                    if (tr !== undefined && tr !== null) {
                        tr.className = ''
                        let findIndex = selections.findIndex(x => x.id === data[d].id)
                        if (findIndex > -1) {
                            selections.splice(findIndex, 1)
                        }
                    }
                }
            }
        }

        tr = document.getElementById(`ald-${id}`)
        if (tr !== undefined && tr !== null && clickedSelection.available === null) {
            if (tr.className.includes('selected')) {
                tr.className = ''
                let findIndex = selections.findIndex(x => x.id === id)
                if (findIndex > -1) {
                    selections.splice(findIndex, 1)
                }
            } else {
                tr.className = 'selected'
                selections.push(id)
            }
        }

        if (index === lastSelectedIdx) {
            tr.className = ''
            selections.splice(index, 1)
        }

        if (e.shiftKey && lastSelectedIdx !== null) {
            let start = index > lastSelectedIdx ? lastSelectedIdx : index
            let end = index > lastSelectedIdx ? index : lastSelectedIdx
            for (let i = start; i <= end; i++) {
                let loopId = data[i].id
                tr = document.getElementById(`ald-${loopId}`)
                if (tr !== undefined && tr !== null && (data[i].available === null || data[i].available === '')) {
                    if (tr.className.includes('selected')) {
                    } else {
                        tr.className = 'selected'
                    }
                    selections.push(loopId)
                }
            }
        }

        if ((e.ctrlKey || e.metaKey)) {
            for (let d = 0; d < data.length; d++) {
                tr = document.getElementById(`ald-${data[d].id}`)
                if (tr !== undefined && tr !== null && tr.className.includes('selected')) {
                    selections.push(data[d].id)
                }
            }
        }

        setLastSelectedIdx(index === lastSelectedIdx ? null : index)
        setSelected(selections)
    }

    const getSelectedTaskNames = () => {
        let flattened = ''
        for(let t=0; t<selectedTasks.length; t++){
            flattened += selectedTasks[t].label
            if(t !== selectedTasks.length - 1){
                flattened += ', '
            }
        }
        return flattened
    }
    const handleCannotSchedule = async (id, message) => {
        let lot = data.find(x => x.id === id)
        if(message.includes('Sequence')){
            alert(message)
        }
        else {
            if (message.includes('processed') || message.includes('status')) {
                let taskNames = getSelectedTaskNames()
                let lotPhase = lot.phase === null || lot.phase === '' ? '' : ` / ${lot.phase}`
                let emailSubject = `${message} - ${lot.job.number}-${lotPhase}${lot.number}`
                emailSubject = emailSubject.charAt(0).toUpperCase() + emailSubject.substring(1)
                let emailBody = `We are unable to schedule the following lot for ${taskNames} because the ${message} %0D%0A%0D%0AJob: ${lot.job.project_name} - ${lot.job.number} %0D%0ALot/Phase: ${lot.number}${lotPhase} %0D%0AAddress: ${lot.address} %0D%0ATasks: ${taskNames}`
                window.open(`mailto:startscoordinator@svcaz.com;kalani@svcaz.com?subject=${emailSubject}&body=${emailBody}`)
            }
            if (message.includes('active in FMS')) {
                let taskNames = getSelectedTaskNames()
                let lotPhase = lot.phase === null || lot.phase === '' ? '' : ` / ${lot.phase}`
                let emailSubject = `${message} - ${lot.job.number}-${lotPhase}${lot.number}`
                emailSubject = emailSubject.charAt(0).toUpperCase() + emailSubject.substring(1)
                let emailBody = `We are unable to schedule the following lot for ${taskNames} because the ${message} %0D%0A%0D%0AJob: ${lot.job.project_name} - ${lot.job.number} %0D%0ALot/Phase: ${lot.number}${lotPhase} %0D%0AAddress: ${lot.address} %0D%0ATasks: ${taskNames}`
                window.open(`mailto:startscoordinator@svcaz.com;kalani@svcaz.com?subject=${emailSubject}&body=${emailBody}`)
            }
            if (message.includes('complete')) {
                //do nothing but show the message
                alert(message)
            }
            if (message.includes('schedule')) {
                let existingScheduleDate = message.split('for')[1]
                let confirmMessage = message.split('for')[0] + ' ' + dayjs(existingScheduleDate).format('ddd, MMMM Do, YYYY')
                confirmMessage = confirmMessage.charAt(0).toUpperCase() + confirmMessage.substring(1)
                if (window.confirm(`${confirmMessage} \nWould you like to move it to ${scheduleDate.format('ddd, MMMM Do, YYYY')}?`)) {
                    let response = await Schedules.updateById({
                        id: lot.schedule_id,
                        schedule_date: scheduleDate.format('YYYY-MM-DD')
                    })
                    let phaseTasks = await PhaseTasks.getSelectedPhaseTaskIds(selectedTasks)
                    await handleLoadSchedule(scheduleDate, selectedPhaseId, phaseTasks)
                    lot.available = message.split('for')[0] + ' for ' + scheduleDate.format('MM/DD/YYYY')
                    setAvailableLotsFiltered(data)
                } else {

                }
            }
        }
    }


    return (
        <ListBoxContainer>
            <div className="checklist">
                <div className={`list-container ${isRequest ? 'list-container-request' : 'list-container-base'}`} value={'value'}>
                    <table>
                        <thead>
                            <tr key={'alth-header'}>
                                {columns.map((c, idx) => {return (<th id={`als-th-${idx}`} key={`als-th-${idx}`}>{c}</th>)})}
                                {data.length > 0 && data[0].available !== undefined && <th key={'als-th-available'} style={{textAlign: 'center'}}>Status</th>}
                            </tr>
                        </thead>
                        <tbody>
                    {data.map((d, index) => {
                        return (
                            <tr id={`ald-${d.id}`} key={index} value={d.id} title={`${d.address} - ${d.available === null ? '' : d.available} - ${index}`} style={d.available !== null ? {backgroundColor: 'gray', cursor: 'default'} : {cursor: 'pointer'}}
                                 onClick={!disabled ? handleSelected : ''}
                                 className={d.selected ? `selected ${d.id}` : ``}>
                                {columns.map((c, idx) => {
                                    return (
                                        <td id={`als${idx}-${d.id}`} key={`als${idx}-${d.id}`} value={d.id}
                                            title={`${d.address} - ${d.available === null ? '' : d.available} - ${index}`}
                                            style={{paddingRight: '10px'}}>{`${d[c]}`}</td>
                                    )
                                })
                                }
                                <td id={`alsico${columns.length + 1}-${d.id}`}
                                    key={`alsico${columns.length + 1}-${d.id}`}
                                    value={d.id} title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                    style={{textAlign: 'center', cursor: 'pointer'}}
                                    onClick={(e) => d.available !== null ? handleCannotSchedule(d.id, d.available) : ''}>
                                    {d.available !== undefined && d.available !== null && d.available.includes('active in FMS') &&
                                        <Block id={`aldico-${d.id}`}
                                                      data-id={`${d.id}`}
                                                      key={`status-icon-${d.id}`}
                                                      title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                                      style={{color: 'darkred'}}/>}
                                    {d.available !== undefined && d.available !== null && d.available.includes('processed') && !d.available.includes('Sequence') &&
                                        <RemoveCircleOutline id={`aldico-${d.id}`}
                                                      data-id={`${d.id}`}
                                                      key={`status-icon-${d.id}`}
                                                      title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                                      style={{color: 'darkred'}}/>}
                                    {d.available !== undefined && d.available !== null && d.available.includes('completed') && !d.available.includes('Sequence') &&
                                        <Verified id={`aldico-${d.id}`}
                                                  key={`status-icon-${d.id}`}
                                                  title={`${d.address} - ${d.available === null ? '' : d.available} - ${index}`}
                                                  style={{color: 'darkred'}}/>}
                                    {d.available !== undefined && d.available !== null && d.available.includes('schedule') && !d.available.includes('Sequence') &&
                                        <CalendarMonth id={`aldico-${d.id}`}
                                                       key={`status-icon-${d.id}`}
                                                       title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                                       style={{color: 'darkred'}}/>}
                                    {d.available !== undefined && d.available !== null && d.available.includes('status')  && !d.available.includes('Sequence')&&
                                        <Error id={`aldico-${d.id}`}
                                                       key={`status-icon-${d.id}`}
                                                       title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                                       style={{color: 'darkred'}}/>}
                                    {d.available !== undefined && d.available !== null && d.available.includes('Sequence') &&
                                        <PanTool id={`aldico-${d.id}`}
                                                      data-id={`${d.id}`}
                                                      key={`status-icon-${d.id}`}
                                                      title={`${d.address} - ${d.available === null ? '' : d.available}`}
                                                      style={{color: 'darkred'}}/>}
                                </td>
                            </tr>
                        )
                    })}
                        </tbody>
                    </table>
                </div>
            </div>
        </ListBoxContainer>
    );
}
