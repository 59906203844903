import dayjs from "dayjs";
import Auth from "../components/authentication/Auth";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class Field {

    static async loadEvenflow(builderIds, phaseIds, jobIds, lotIds, startDate) {
        let date = startDate === null ? null : startDate.format('YYYY-MM-DD')
        let json = {
            "builder.id": builderIds
        }

        let jsonString = JSON.stringify(json)

        let phases = phaseIds.length > 0 ? phaseIds.join(',') : null
        let jobs = jobIds !== undefined && jobIds !== null && jobIds.length > 0 ? jobIds.join(',') : null
        let lots = lotIds !== undefined && lotIds !== null && lotIds.length > 0 ? lotIds.join(',') : null
        let dbData = []
      //  if (lots !== null && lots.length > 0) {
            dbData = await Auth.hitEndpointNew("GET", `evenflow/${date}/${phases}/${jobs}/${lots}`, jsonString !== '' ? `jsonString=${jsonString}` : null)
        // }
        // else if(jobs !== null && jobs.length > 0){
        //     dbData = await Auth.hitEndpointNew("GET", `evenflow/jobs/${jobs.join(',')}/phases/${phaseIds.join(',')}`, jsonString !== '' ? `jsonString=${jsonString}` : null)
        // }
        // else {
        //     dbData = await Auth.hitEndpointNew("GET", `evenflow/${date}/${phaseIds.join(',')}`, jsonString !== '' ? `jsonString=${jsonString}` : null);
        // }

        return dbData;
    }
}

export default Field;