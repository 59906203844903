import {useEffect, useState} from 'react';
import Auth from "../authentication/Auth";

const PayrollUnlock = () => {

    const [didLoadData, setDidLoadData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [userId, setUserId] = useState(-1)


    useEffect(() => {
        const loadData = async () => {

            setIsLoading(true)
            setDidLoadData(true);
            const user = await Auth.getUserId()
            setUserId(user)
            setIsLoading(false)
        }

        if (!didLoadData) {
            loadData();
        }
    }, [didLoadData]);

    return (
        <div>
            Lock and Unlock Payroll Coming Soon
        </div>
    );
}
export default PayrollUnlock;
