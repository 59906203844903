import {useEffect, useMemo, useState} from 'react';

import EvenflowGrid from "./EvenflowGrid";
import EvenflowPageContainer from "./styles/EvenflowPageContainer";
import Select, {createFilter} from "react-select";
import Builders from "../../classes/Builders";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import Evenflow from "../../classes/Evenflow";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {IconButton, Tab, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

import * as XLSX from 'sheetjs-style';
import { utils } from "xlsx";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Phases from "../../classes/Phases";
import Jobs from "../../classes/Jobs";
import Lots from "../../classes/Lots";
import Crews from "../../classes/Crews";

const EvenflowPage = (props) => {

    const [selectedTab, setSelectedTab] = useState("0")
    const [selectedJobTab, setSelectedJobTab] = useState("0")
    const [builders, setBuilders] = useState([])
    const [selectedBuilders, setSelectedBuilders] = useState([])

    const [phases, setPhases] = useState([])
    const [selectedPhases, setSelectedPhases] = useState([])

    const [jobs, setJobs] = useState([])
    const [selectedJobs, setSelectedJobs] = useState([])

    const [lots, setLots] = useState([])
    const [selectedLots, setSelectedLots] = useState([])

    const [crews, setCrews] = useState([])

    const [didLoadData, setDidLoadData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [evenflow, setEvenflow] = useState({
        template: {
            config: []
        },
        data: [],
    })
    const [buttonText, setButtonText] = useState('Get Evenflow')
    const [filterDate, setFilterDate] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            if (Utilities.isLoggedOut()) {
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            } else {
                setIsLoading(true)
                setDidLoadData(true);
                setBuilders(await Builders.loadBuildersSelect(null))
                setPhases(await Phases.loadPhases(null))
                setCrews(await Crews.loadCrewsForDropdown(null))
                setIsLoading(false)
            }
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    const handleBuilderChanged = async (selections) => {
        if(selections !== null) {
            setSelectedBuilders([selections])
            let builderIds = await Builders.getSelectedBuilderIds([selections])
            setJobs(await Jobs.loadJobs({"builder_id": builderIds}))
        }
        else{
            setSelectedBuilders([])
            setJobs([])
        }
        if(evenflow.data.length > 0) {
            setSelectedTab("0")
        }
    }
    const handlePhaseChanged = async (selections) => {
        if(selections !== null) {
            setSelectedPhases(selections)
        }
        else{
            setSelectedPhases([])
        }
        // if(evenflow.data.length > 0) {
        //     setSelectedTab("0")
        // }
    }

    const handleJobChanged = async (selections) => {
        if(selections !== null && selections.length > 0) {
            setSelectedJobs(selections)
            let jobIds = await Jobs.getSelectedJobIds(selections)
            setLots(await Lots.loadLots({job_id: jobIds}))
        }
        else{
            setSelectedJobs([])
            setLots([])
        }
        if(evenflow.data.length > 0) {
            setSelectedTab("0")
        }
    }
    const handleLotChanged = async (selections) => {
        if(selections !== null && selections.length > 0) {
            setSelectedLots(selections)
        }
        else{
            setSelectedLots([])
        }
        if(evenflow.data.length > 0) {
            setSelectedTab("0")
        }
    }

    const handleGetEvenflowData = async() => {
        setIsLoading(true)
        setEvenflow({template: [], data: []})

        setSelectedJobTab("0")
        if (selectedBuilders.length === 0) {
            alert('Select a Builder First')
        }else if(selectedPhases.length === 0){
            alert("Select At Least 1 Phase First")
        }
        else {
            let tempEvenflow
            setButtonText('Fetching Report...');
            let builderIds = await Builders.getSelectedBuilderIds(selectedBuilders)
            let phaseIds = await Phases.getSelectedPhaseIds(selectedPhases)
            let jobIds = selectedJobs.length === 0 ? null : await Jobs.getSelectedJobIds(selectedJobs)
            let lotIds = selectedLots.length === 0 ? null : await Lots.getSelectedLotIds(selectedLots)
            let date = filterDate !== null ? filterDate.startOf('month') : null

            if (builderIds.length > 0 && phaseIds.length > 0) {
                tempEvenflow = await Evenflow.loadEvenflow(builderIds, phaseIds, jobIds, lotIds, date)
                setEvenflow(tempEvenflow)
            } else {
                setEvenflow({
                    template: {
                        config: []
                    },
                    data: [],
                })
            }
            setButtonText('Get Evenflow');
        }
        setIsLoading(false)
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleDownload = () => {

        let headers = []
        evenflow.template.config.forEach((item) => {
            let taskName = (`${item.task.code !== null ? item.task.code : ''} ${item.task.name}`).trim()
            headers.push(taskName)
            if(item.calc_days_stop)
            headers.push(item.calc_days_label)
        })

        let nonColumns = []
        const columnWidths = []
        evenflow.data.forEach((bldr) => {
            const workbook = utils.book_new();
            bldr.jobs.forEach((job) => {

                let lotDays = []
                let lotJson = []

                let nextMergeRow = 3
                let merges = [ {s: {r: 1, c: 0}, e: {r:  2, c: 0}}]
                let index = 0
                job.lots.forEach((lot) => {
                    let lotDayRow = {
                        lot: lot.lot,
                    }
                    let scheduleRow = {
                        Lot: lot.lot,
                        Start: lot.start,
                        Status: 'schedule',
                    }
                    let actualRow = {...scheduleRow}
                    actualRow.Status = 'actual'

                    headers.forEach((header, headerIndex) => {
                        let sVal = ' '
                        let aVal = ' '
                        let sDays = 0
                        let aDays = 0
                        let dDays = 0
                        let isAvg = false
                        let isTotal = header.includes('Total')
                        if (lot[header] !== undefined) {
                            sVal = lot[header].schedule
                            aVal = lot[header].actual
                            isAvg = lot[header].is_avg
                            sDays = lot[header].schedule_days
                            aDays = lot[header].actual_days
                            dDays = lot[header].delay_days
                        } else {
                            if (index === 0) {
                                nonColumns.push(header)
                            }
                        }

                        scheduleRow[header] = isTotal && !isAvg ? sDays === null || sDays === '' ? ' ' : sDays : sVal === null || sVal === '' ? ' ' : sVal
                        actualRow[header] = isTotal ? aDays === null || aDays === '' ? ' ' : aDays : aVal === null || aVal === '' ? ' ' : aVal
                        lotDayRow[header] = {lot: lot.lot, schedule: sDays, actual: aDays}

                        if (isAvg && sVal !== null) {
                            scheduleRow[`${header} delay`] = `${sVal} Delay`
                            actualRow[`${header} delay`] = dDays
                        }
                        else if(isTotal && sVal !== null) {
                            scheduleRow[`${header} delay`] = ' '
                            actualRow[`${header} delay`] = ' '
                        }
                    })

                    lotDays.push(lotDayRow)
                    lotJson.push(scheduleRow)
                    lotJson.push(actualRow)

                    merges.push(
                        {s: {r: nextMergeRow, c: 0}, e: {r: nextMergeRow + 1, c: 0}},
                        {s: {r: nextMergeRow, c: 1}, e: {r: nextMergeRow + 1, c: 1}})
                    nextMergeRow += 2
                    index += 1
                })

                const worksheet = utils.json_to_sheet(lotJson, {
                    FreezePanes: { xSplit: "3", ySplit: "1" },
                    freezepanes: { xSplit: "3", ySplit: "1" },
                    "!freeze": { xSplit: "3", ySplit: "1" },
                    sheetView: { xSplit: "3", ySplit: "1" },
                });

                const range = utils.decode_range(worksheet["!ref"] ?? "")
                const rowCount = range.e.r
                const columnCount = range.e.c

                //Add merges
                for (let row = 0; row <= rowCount; row++) {
                    for (let col = 0; col <= columnCount; col++) {
                        let headerRef = utils.encode_cell({r: 0, c: col})
                        if(worksheet[headerRef] !== undefined) {
                            let headerVal = worksheet[headerRef].v
                            if (headerVal.includes('Total') && !headerVal.includes('delay') && (row === 0 || row > 2)) {
                                merges.push({s: {r: row, c: col}, e: {r: row, c: col + 1}})
                            }
                        }
                    }
                }


                let start = 1
                let row = 0
                job.lots.forEach((lot, index) => {

                    for (let col = 0; col <= columnCount; col++) {

                        let headerRef = utils.encode_cell({r: 0, c: col})
                        let headerVal = worksheet[headerRef].v
                        const scheduleRef = utils.encode_cell({r: start, c: col})
                        const actualRef = utils.encode_cell({r: start + 1, c: col})
                        const sLabelRef = utils.encode_cell({r: start, c: 2})
                        let nonCol = nonColumns.find(x => x === headerVal)

                        let sLabel = worksheet[sLabelRef].v

                        let lotVals = lot[headerVal]
                        let fillColor = 'FFFFFF'
                        if(lotVals !== undefined) {
                             if (lotVals.actual !== null && lotVals.actual_days !== 0 && lotVals.actual_days < lotVals.schedule_days) {
                                if(lotVals.actual_days !== null) {fillColor = '71D9E2'}
                            } else if (lotVals.actual !== null && lotVals.actual_days !== 0 && lotVals.actual_days > lotVals.schedule_days) {
                                 if(lotVals.actual_days !== null){fillColor = 'FFC0CB'}
                            }
                        }

                        let top = {style: 'thick', color: {auto: 1}}
                        let left = col === 0 || col > 2 ? {style: 'thin', color: {auto: 1}} : ''
                        let right = col === columnCount - 1 || col > 2 ? {style: 'thin', color: {auto: 1}} : ''
                        let bottom = {style: 'thick', color: {auto: 1}}

                        if(start === 1){
                            worksheet[headerRef].s = setCellStyle(worksheet[headerRef], 0, col, nonCol === undefined ? 'D3D3D3' : '808080' , top, left, right, bottom)
                        }
                        if(scheduleRef !== undefined && worksheet[scheduleRef] !== undefined) {
                            worksheet[scheduleRef].s = setCellStyle(worksheet[scheduleRef], start, col, col > 2 && (nonCol === undefined && sLabel === 'schedule') ? 'D3D3D3' : col > 2 ? '808080' : 'FFFFFF', top, left, right, '')
                        }
                        if(actualRef !== undefined && worksheet[actualRef] !== undefined) {
                            worksheet[actualRef].s = setCellStyle(worksheet[actualRef], start, col, nonCol === undefined ? fillColor : '808080', '', left, right, bottom)
                        }
                    }

                    row += 1
                    start += 2
                })

                worksheet['!merges'] = merges;
                worksheet["!cols"] = columnWidths.map(width => ({ wch: width }))
                worksheet['freezepanes'] = { xSplit: "3", ySplit: "1" };
                worksheet['!freeze'] = { xSplit: "3", ySplit: "1" };
                worksheet['sheetView'] ={ xSplit: "3", ySplit: "1" };
                const sheetName = job.job.length > 31 ? job.job.substring(0, 31) : job.job;
                utils.book_append_sheet(workbook, worksheet, sheetName);
            })

            XLSX.writeFile(workbook,`${bldr.builder} Evenflow.xlsx`);
        })
    }

    const setCellStyle = (cell, row, col, fill, top, left, right, bottom) => {
       return {
           ...cell.s,
           width: 9,
           wch: 9,
           fill: {fgColor: {rgb: fill}, patternType: 'solid'},
           border: {
               top: top,
               left: left,
               right: right,
               bottom: bottom,
           },
           alignment: {
               horizontal: "center",
               vertical: "center",
               wrapText: false,
               textRotation: row === 0 ? 90 : 0,
               width: 9,
               wch: 9,
           },
       }
    }

    const handleFilterDateChanged = (selection) => {
        setFilterDate(selection)
    }

    return (
        <EvenflowPageContainer>
            <div className='top-boxes'>
                <div className='filter'>
                    <div className='filter-header'>Filters</div>
                    <div className='filter-options'>
                        <div className={'phase-builder-filter-box'}>
                            <Select
                                id="phase-filter-select-id"
                                className={"phase-filter-select"}
                                label="Select A Phase"
                                required={true}
                                onChange={async (e) => await handlePhaseChanged(e)}
                                options={phases.map((phase) => {
                                    return{
                                        value: phase.id,
                                        label: phase.name,
                                        phase: phase,
                                    }
                                })}
                                isClearable
                                isSearchable
                                isMulti={true}
                                value={selectedPhases}
                                filterOption={createFilter(filterConfig)}
                                placeholder={"Select a Phase"}
                            />
                            <Select
                                id="builder-filter-select-id"
                                className={"builder-filter-select"}
                                label="Select A Builder"
                                required={true}
                                onChange={async (e) => await handleBuilderChanged(e)}
                                options={builders}
                                isClearable
                                isSearchable
                                isMulti={false}
                                value={selectedBuilders}
                                filterOption={createFilter(filterConfig)}
                                placeholder={"Select a Builder"}
                            />
                        </div>
                        <div className={"job-lot-filter-box"}>
                            <Select
                                id="job-filter-select-id"
                                label="Select A Job"
                                className={"job-filter-select"}
                                required={true}
                                onChange={async (e) => await handleJobChanged(e)}
                                options={jobs.map((job) => {
                                    return{
                                        value: job.id,
                                        label: `${job.number} ${job.project_name}`,
                                        job: job,
                                    }
                                })}
                                isClearable
                                isSearchable
                                isMulti={true}
                                value={selectedJobs}
                                filterOption={createFilter(filterConfig)}
                                placeholder={"Select a Job"}
                            />
                            <Select
                                id="lot-filter-select-id"
                                className={"lot-filter-select"}
                                label="Select A Lot"
                                required={true}
                                onChange={async (e) => await handleLotChanged(e)}
                                options={lots.map((lot) => {
                                    return{
                                        value: lot.id,
                                        label: `${lot.phase !== null && lot.phase !== '' ? lot.phase + ' - ' : ''}${lot.number}`,
                                        lot: lot,
                                    }
                                })}
                                isClearable
                                isSearchable
                                isMulti={true}
                                value={selectedLots}
                                filterOption={createFilter(filterConfig)}
                                placeholder={"Select a Lot"}
                            />
                        </div>
                    </div>
                </div>
                    <div className='filter'>
                        <div className='filter-header'>Release Date Filter</div>
                        <div className='filter-options'>
                            <div className={'date-filter-box'}>
                                    <div className='date-filter'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker className={"filter-date"}
                                                        views={['year', 'month']}
                                                        onChange={(e) => handleFilterDateChanged(e)}
                                                        defaultValue={dayjs(filterDate)}
                                                        selected={filterDate}
                                                        slotProps={{
                                                            field: { clearable: true, placeholder: 'Release Date'},
                                                            actionBar: {
                                                                actions: ['clear'],
                                                            },
                                                        }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className='filter-actions'>
                <Button
                    className="filter-button"
                    variant="contained"
                    // disabled={isLoading}
                    disabled={false}
                    onClick={(e) => handleGetEvenflowData()}
                >
                    {buttonText}
                </Button>
            </div>
            <div className={'evenflow-data'}>
                <TabContext value={selectedTab}>
                    <TabList variant={"scrollable"} onChange={handleTabChange}>
                        {selectedBuilders.map((builder, index) => {
                            return (
                                <Tab key={`builder-tab-${index}`} className={'tab-active tab-builder'} label={builder.name} value={index.toString()}/>
                            )
                        })}
                    </TabList>
                    {selectedBuilders.map((builder, index) => {
                        return (
                             <TabPanel key={`builder-panel-${index}`} value={index.toString()}>
                                <div key={`builder-panel-${index}-averages`} className={"average-table"}>
                                </div>
                                 <div className={"export-div"}>
                                     {evenflow.data.length > 0 &&
                                         <Tooltip title="Download Excel">
                                             <span>
                                                 <IconButton
                                                     color="success"
                                                     disabled={(evenflow.data.length === 0) || isLoading}
                                                     onClick={handleDownload}
                                                     variant="contained"
                                                 >
                                                     <span>Export Evenflow</span><FontAwesomeIcon icon={faFileExcel} style={{marginLeft: "15px"}} />
                                                 </IconButton>
                                             </span>
                                         </Tooltip>}
                                 </div>
                                <EvenflowGrid
                                    selectedBuilder={builder}
                                    crews={crews}
                                    template={evenflow.template.config}
                                    evenflow={evenflow.data.find(x => x.builder_id === builder.id)}
                                    selectedTab={selectedJobTab}
                                    setSelectedTab={setSelectedJobTab}
                                />
                            </TabPanel>
                        )
                    })}
                </TabContext>
            </div>
        </EvenflowPageContainer>
    )
}

export default EvenflowPage;
