import Auth from "../components/authentication/Auth";
import Globals from "../components/utilities/Globals";
import Jobs from './Jobs';
// import Employees from "./Employees";
import moment from "moment/moment";

// TODO This class will evolve as more functionality is added

// TODO write function to return this
/*
Camera Question Object
{
    result: false,
    editedBy: {
        name: "",
        date: new Date()
    }
}
*/

// TODO Lot data from database goal
// TODO Current lot data will match what is in database
// {
//     id: "",
//     job_id: "",
//     active: true,
//     number: "",           (!!! On Hold !!!) ADD TO DATABASE     !!! AFTER FIRST COMBINING ALL LOT DATA
//     number: "",        (!!! On Hold !!!) MARK FOR DELETION   !!! AFTER FIRST COMBINING ALL LOT DATA
//     phase_id: "",
//     address: "",
//     plan_id: "",           (!!! On Hold !!!) ADD TO DATABASE     !!! AFTER FIRST COMBINING ALL LOT DATA
//     plan_number: "",       (!!! On Hold !!!) MARK FOR DELETION   !!! AFTER FIRST COMBINING ALL LOT DATA
//     notesTemp: [],        (!!! On Hold !!!) ADD TO DATABASE     !!! AFTER FIRST COMBINING ALL LOT DATA
//     notes: "",            (!!! On Hold !!!) MARK FOR DELETION   !!! AFTER FIRST COMBINING ALL LOT DATA
//     editedBy: {
//         name: "",         (!!! On Hold !!!) ADD TO DATABASE     !!! AFTER FIRST COMBINING ALL LOT DATA
//         date: new Date()  (!!! On Hold !!!) ADD TO DATABASE     !!! AFTER FIRST COMBINING ALL LOT DATA
//     }
// }

class Lots{
    static async loadLots(queryObj, params=""){
        const limit = 50;
        let skip = 0;
        let data = [];
      //  while(true){
            let dbData;
            if(queryObj){
                if(queryObj.phase === null || queryObj.phase === ''){
                    delete queryObj.phase
                }
                const jsonString = JSON.stringify(queryObj);
                dbData = await Auth.hitEndpoint("GET", "LOTS", `selector=sixteen&jsonString=${jsonString}&skip=${skip}&limit=${limit}`, null, params);
            }
            else{
                dbData = await Auth.hitEndpoint("GET", "LOTS", `selector=seven&skip=${skip}&limit=${limit}`, null, params);
            }
            data = [...data, ...dbData];
            if(dbData.length === 0){
        //        break;
            }
            skip += limit;
    //    }

        // TODO needed only for setting hasPavers and hasPlumbingGas
        // TODO remove once this data is changed
        const jobs = await Jobs.loadJobs(null);

        // Convert all dates from strings to date objects
        data.forEach(lot => {
            // TODO Delete this once data is set properly #############################
            const currentJob = jobs.find(jobToFind => jobToFind.id === lot.job_id);
            if(lot.hasPavers === ''){
                if(currentJob.hasPavers){
                    lot.hasPavers = 'true';
                }
                else{
                    lot.hasPavers = 'false';
                }
            }
            if(lot.hasGas === ''){
                if(currentJob.hasPlumbingGas){
                    lot.hasGas = 'true';
                }
                else{
                    lot.hasGas = 'false';
                }
            }
            // ########################################################################

            for(let i = 0; i < lot.phases.length; i++){
            //    lot.phases[i].releaseDate = lot.phases[i].releaseDate ? new Date(lot.phases[i].releaseDate) : null;
               // lot.phases[i].modified_at = lot.phases[i].modified_at.date ? new Date(lot.phases[i].modified_at) : null;
            }

         //   lot.measurements.concrete.date = lot.measurements.concrete.date ? new Date(lot.measurements.concrete.date) : null;
          //  lot.measurements.pavers.date = lot.measurements.pavers.date ? new Date(lot.measurements.pavers.date) : null;
         //   this.convertEWASDates(lot.ewas);

            // for(let i = 0; i < lot.payouts.length; i++){
            //     lot.payouts[i].editedBy.date = new Date(lot.payouts[i].editedBy.date);
            // }
            // TODO May need to convert all dates to date objects
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q1);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q2);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q3);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q4);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q5);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q6);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q7);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q8);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q9);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q10);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q11);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q12);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q13);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q14);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q15);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q16);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.q17);
            // this.convertCameraQAQuestionOrCommentsDates(lot.plumbing.cameraQAForm.comments);
        })

        return data;
    }

    static async loadLotsForDropDown(queryObj, jobId){

        const data = await Lots.loadLots(queryObj, jobId);

        // Add data members needed for drop down functionality
        data.forEach(lot => {
            lot.display = `${lot.number} - ${lot.phase} - ${lot.address}`
        })

        data.sort((l1, l2) => {
            return Number.parseInt(l1.number) < Number.parseInt(l2.number) ? -1 : 1;
        })

        return data.filter(lotToFilter => lotToFilter.active);
    }

    static async saveNewLot(lot){
        const result = await Auth.hitEndpoint("POST", "LOT", "", lot);
        lot.id = result.id;
        return lot;
    }

    static async saveNewLots(lots){
        return await Auth.hitEndpoint("POST", "LOT", "", lots); 
    }

    static createLotDBObject(lot){
        const dbObject = {};

        if(lot.id !== undefined){ dbObject.id = lot.id; }
        if(lot.active !== undefined){ dbObject.active = lot.active; }
        if(lot.address !== undefined){ dbObject.address = lot.address; }
        if(lot.hasPavers !== undefined){ dbObject.hasPavers = lot.hasPavers; }
        if(lot.job_id !== undefined){ dbObject.job_id = lot.job_id; }
        if(lot.notes !== undefined){ dbObject.notes = lot.notes; }
        if(lot.number !== undefined){ dbObject.number = lot.number; }
        if(lot.phase !== undefined){ dbObject.phase = lot.phase; }
        if(lot.plan_number !== undefined){ dbObject.plan_number = lot.plan_number; }
        if(lot.version !== undefined){ dbObject.version = lot.version; }
        if(lot.attributes !== undefined){
            const attributes = [];
            for(let i = 0; i < lot.attributes.length; i++){
                const attribute = {};
                if(lot.attributes[i].id !== undefined){ attribute.id = lot.attributes[i].id; }
                if(lot.attributes[i].lot_id !== undefined){ attribute.lot_id = lot.attributes[i].lot_id; }
                if(lot.attributes[i].attribute_id !== undefined){ attribute.attribute_id = lot.attributes[i].attribute_id; }
                if(lot.attributes[i].attribute !== undefined){
                    const subAttribute = {};
                    if(lot.attributes[i].attribute.id !== undefined){ subAttribute.id = lot.attributes[i].attribute.id; }
                    if(lot.attributes[i].attribute.name !== undefined){ subAttribute.name = lot.attributes[i].attribute.name; }
                    if(lot.attributes[i].attribute.description !== undefined){ subAttribute.description = lot.attributes[i].attribute.description; }
                    if(lot.attributes[i].attribute.group !== undefined){ subAttribute.group = lot.attributes[i].attribute.group; }
                    if(lot.attributes[i].attribute.has_option !== undefined){ subAttribute.has_option = lot.attributes[i].attribute.has_option; }
                    attribute.attribute = subAttribute;
                }
                attributes.push(attribute);
            }
            dbObject.attributes = attributes;
        }
        if(lot.bluestake !== undefined){
            const bluestakeArray = [];
            for(let i = 0; i < lot.bluestake.length; i++){
                const bluestake = {};
                if(lot.bluestake[i].id !== undefined){ bluestake.id = lot.bluestake[i].id; }
                if(lot.bluestake[i].lot_id !== undefined){ bluestake.lot_id = lot.bluestake[i].lot_id; }
                if(lot.bluestake[i].processed !== undefined){ bluestake.processed = lot.bluestake[i].processed; }
                if(lot.bluestake[i].date !== undefined){ bluestake.date = lot.bluestake[i].date && lot.bluestake[i].date !== null ? moment(new Date(lot.bluestake[i].date)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.bluestake[i].ticket !== undefined){ bluestake.ticket = lot.bluestake[i].ticket; }
                if(lot.bluestake[i].user_id !== undefined){ bluestake.user_id = lot.bluestake[i].user_id; }
                if(lot.bluestake[i].comment !== undefined){ bluestake.comment = lot.bluestake[i].comment; }
                if(lot.bluestake[i].history !== undefined){
                    const bluestakeHistoryArray = [];
                    for(let j = 0; j < lot.bluestake[i].history.length; j++){
                        const bluestakeHistory = {};
                        if(lot.bluestake[i].history[j].id !== undefined){ bluestakeHistory.id = lot.bluestake[i].history[j].id; }
                        if(lot.bluestake[i].history[j].lot_bluestake_id !== undefined){ bluestakeHistory.lot_bluestake_id = lot.bluestake[i].history[j].lot_bluestake_id; }
                        if(lot.bluestake[i].history[j].history !== undefined){ bluestakeHistory.history = lot.bluestake[i].history[j].history; }
                        bluestakeHistoryArray.push(bluestakeHistory);
                    }
                    bluestake.history = bluestakeHistoryArray;
                }
                bluestakeArray.push(bluestake);
            }
            dbObject.bluestake = bluestakeArray;
        }
        if(lot.measurements !== undefined){
            const measurements = {};
            if(lot.measurements.concrete !== undefined){
                const concreteMeasurements = {};
                if(lot.measurements.concrete.approach !== undefined){ concreteMeasurements.approach = lot.measurements.concrete.approach; }
                if(lot.measurements.concrete.courtyard !== undefined){ concreteMeasurements.courtyard = lot.measurements.concrete.courtyard; }
                if(lot.measurements.concrete.date !== undefined){ concreteMeasurements.date = lot.measurements.concrete.date && lot.measurements.concrete.date !== null ? moment(new Date(lot.measurements.concrete.date)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.measurements.concrete.driveway !== undefined){ concreteMeasurements.driveway = lot.measurements.concrete.driveway; }
                if(lot.measurements.concrete.entry !== undefined){ concreteMeasurements.entry = lot.measurements.concrete.entry; }
                if(lot.measurements.concrete.patio !== undefined){ concreteMeasurements.patio = lot.measurements.concrete.patio; }
                if(lot.measurements.concrete.user !== undefined){ concreteMeasurements.user = lot.measurements.concrete.user; }
                if(lot.measurements.concrete.walkway !== undefined){ concreteMeasurements.walkway = lot.measurements.concrete.walkway; }
                measurements.concrete = concreteMeasurements;
            }
            if(lot.measurements.pavers !== undefined){
                const paverMeasurements = {};
                if(lot.measurements.pavers.approach !== undefined){ paverMeasurements.approach = lot.measurements.pavers.approach; }
                if(lot.measurements.pavers.color !== undefined){ paverMeasurements.color = lot.measurements.pavers.color; }
                if(lot.measurements.pavers.courtyard !== undefined){ paverMeasurements.courtyard = lot.measurements.pavers.courtyard; }
                if(lot.measurements.pavers.date !== undefined){ paverMeasurements.date = lot.measurements.pavers.date && lot.measurements.pavers.date !== null ? moment(new Date(lot.measurements.pavers.date)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.measurements.pavers.driveway !== undefined){ paverMeasurements.driveway = lot.measurements.pavers.driveway; }
                if(lot.measurements.pavers.entry !== undefined){ paverMeasurements.entry = lot.measurements.pavers.entry; }
                if(lot.measurements.pavers.patio !== undefined){ paverMeasurements.patio = lot.measurements.pavers.patio; }
                if(lot.measurements.pavers.po !== undefined){ paverMeasurements.po = lot.measurements.pavers.po; }
                if(lot.measurements.pavers.style !== undefined){ paverMeasurements.style = lot.measurements.pavers.style; }
                if(lot.measurements.pavers.user !== undefined){ paverMeasurements.user = lot.measurements.pavers.user; }
                if(lot.measurements.pavers.walkway !== undefined){ paverMeasurements.walkway = lot.measurements.pavers.walkway; }
                measurements.pavers = paverMeasurements;
            }
            dbObject.measurements = measurements;
        }
        if(lot.phases !== undefined){
            const phases = [];
            for(let i = 0; i < lot.phases.length; i++){
                const phase = {};
                if(lot.phases[i].id !== undefined){ phase.id = lot.phases[i].id; }
                if(lot.phases[i].lot_id !== undefined){ phase.lot_id = lot.phases[i].lot_id; }
                if(lot.phases[i].modified_at !== undefined){ phase.modified_at = moment(new Date(lot.phases[i].modified_at)).format('YYYY-MM-DD H:mm:ss'); }
                if(lot.phases[i].modified_by !== undefined){ phase.modified_by = lot.phases[i].modified_by; }
                if(lot.phases[i].phase_id !== undefined){ phase.phase_id = lot.phases[i].phase_id; }
                if(lot.phases[i].release_date !== undefined){ phase.release_date = lot.phases[i].release_date && lot.phases[i].release_date !== null ? moment(new Date(lot.phases[i].release_date)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.phases[i].start_status_id !== undefined){ phase.start_status_id = lot.phases[i].start_status_id; }
                if(lot.phases[i].starts_status !== undefined){ phase.starts_status = lot.phases[i].starts_status; }
                if(lot.phases[i].phase !== undefined){
                    const subPhase = {};
                    if(lot.phases[i].phase.id !== undefined){ subPhase.id = lot.phases[i].phase.id; }
                    if(lot.phases[i].phase.field_id !== undefined){ subPhase.field_id = lot.phases[i].phase.field_id; }
                    if(lot.phases[i].phase.name !== undefined){ subPhase.name = lot.phases[i].phase.name; }
                    if(lot.phases[i].phase.description !== undefined){ subPhase.description = lot.phases[i].phase.description; }
                    if(lot.phases[i].phase.stat_name !== undefined){ subPhase.stat_name = lot.phases[i].phase.stat_name; }
                    if(lot.phases[i].phase.stats_order_by !== undefined){ subPhase.stats_order_by = lot.phases[i].phase.stats_order_by; }
                    phase.phase = subPhase;
                }
                phases.push(phase);
            }
            dbObject.phases = phases;
        }
        if(lot.qa !== undefined){
            const qaArray = [];
            for(let i = 0; i < lot.qa.length; i++){
                const qa = {};
                if(lot.qa[i].id !== undefined){ qa.id = lot.qa[i].id; }
                if(lot.qa[i].lot_id !== undefined){ qa.lot_id = lot.qa[i].lot_id; }
                if(lot.qa[i].qa_id !== undefined){ qa.qa_id = lot.qa[i].qa_id; }
                if(lot.qa[i].field_id !== undefined){ qa.field_id = lot.qa[i].field_id; }
                if(lot.qa[i].user_id !== undefined){ qa.user_id = lot.qa[i].user_id; }
                if(lot.qa[i].value !== undefined){ qa.value = lot.qa[i].value; }
                if(lot.qa[i].date !== undefined){ qa.date = lot.qa[i].date && lot.qa[i].date !== null ? moment(new Date(lot.qa[i].date)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.qa[i].qa !== undefined){
                    const subQA = {};
                    if(lot.qa[i].qa.id !== undefined){ subQA.id = lot.qa[i].qa.id; }
                    if(lot.qa[i].qa.field_id !== undefined){ subQA.field_id = lot.qa[i].qa.field_id; }
                    if(lot.qa[i].qa.name !== undefined){ subQA.name = lot.qa[i].qa.name; }
                    if(lot.qa[i].qa.display !== undefined){ subQA.display = lot.qa[i].qa.display; }
                    if(lot.qa[i].qa.qa_type_id !== undefined){ subQA.qa_type_id = lot.qa[i].qa.qa_type_id; }
                    if(lot.qa[i].qa.order_by !== undefined){ subQA.order_by = lot.qa[i].qa.order_by; }
                    if(lot.qa[i].qa.field !== undefined){
                        const field = {};
                        if(lot.qa[i].qa.field.id !== undefined){ field.id = lot.qa[i].qa.field.id; }
                        if(lot.qa[i].qa.field.name !== undefined){ field.name = lot.qa[i].qa.field.name; }
                        if(lot.qa[i].qa.field.display !== undefined){ field.display = lot.qa[i].qa.field.display; }
                        subQA.field = field;
                    }
                    if(lot.qa[i].qa.type !== undefined){
                        const type = {};
                        if(lot.qa[i].qa.type.id !== undefined){ type.id = lot.qa[i].qa.type.id; }
                        if(lot.qa[i].qa.type.name !== undefined){ type.name = lot.qa[i].qa.type.name; }
                        if(lot.qa[i].qa.type.field_id !== undefined){ type.field_id = lot.qa[i].qa.type.field_id; }
                        subQA.type = type;
                    }
                    qa.qa = subQA;
                }
                qaArray.push(qa);
            }
            dbObject.qa = qaArray;
        }
        if(lot.ewas !== undefined){
            const ewas = [];
            for(let i = 0; i < lot.ewas.length; i++){
                const ewa = {};
                if(lot.ewas[i].id !== undefined){ ewa.id = lot.ewas[i].id; }
                if(lot.ewas[i].lot_id !== undefined){ ewa.lot_id = lot.ewas[i].lot_id; }
                if(lot.ewas[i].approval_at !== undefined){ ewa.approval_at = lot.ewas[i].approval_at && lot.ewas[i].approval_at !== null ? moment(new Date(lot.ewas[i].approval_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.ewas[i].approval_by !== undefined){ ewa.approval_by = lot.ewas[i].approval_by; }
                if(lot.ewas[i].approved !== undefined){ ewa.approved = lot.ewas[i].approved; }
                if(lot.ewas[i].created_at !== undefined){ ewa.created_at = lot.ewas[i].created_at && lot.ewas[i].created_at !== null ? moment(new Date(lot.ewas[i].created_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.ewas[i].created_by !== undefined){ ewa.created_by = lot.ewas[i].created_by; }
                if(lot.ewas[i].modified_at !== undefined){ ewa.modified_at = moment(new Date(lot.ewas[i].modified_at)).format('YYYY-MM-DD H:mm:ss'); }
                if(lot.ewas[i].modified_by !== undefined){ ewa.modified_by = lot.ewas[i].modified_by; }
                if(lot.ewas[i].number !== undefined){ ewa.number = lot.ewas[i].number; }
                if(lot.ewas[i].printed !== undefined){ ewa.printed = lot.ewas[i].printed; }
                if(lot.ewas[i].printed_at !== undefined){ ewa.printed_at = lot.ewas[i].printed_at && lot.ewas[i].printed_at !== null ? moment(new Date(lot.ewas[i].printed_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                if(lot.ewas[i].printed_by !== undefined){ ewa.printed_by = lot.ewas[i].printed_by; }
                if(lot.ewas[i].employees !== undefined){
                    const employees = [];
                    for(let j = 0; j < lot.ewas[i].employees.length; j++){
                        const employee = {};
                        if(lot.ewas[i].employees[j].id !== undefined){ employee.id = lot.ewas[i].employees[j].id; }
                        if(lot.ewas[i].employees[j].ewa_id !== undefined){ employee.ewa_id = lot.ewas[i].employees[j].ewa_id; }
                        if(lot.ewas[i].employees[j].employee_id !== undefined){ employee.employee_id = lot.ewas[i].employees[j].employee_id; }
                        if(lot.ewas[i].employees[j].approved !== undefined){ employee.approved = lot.ewas[i].employees[j].approved; }
                        if(lot.ewas[i].employees[j].approval_at !== undefined){ employee.approval_at = lot.ewas[i].employees[j].approval_at && lot.ewas[i].employees[j].approval_at !== null ? moment(new Date(lot.ewas[i].employees[j].approval_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                        if(lot.ewas[i].employees[j].approval_by !== undefined){ employee.approval_by = lot.ewas[i].employees[j].approval_by; }
                        employees.push(employee);
                    }
                    ewa.employees = employees
                }
                if(lot.ewas[i].line_items !== undefined){
                    const line_items = [];
                    for(let j = 0; j < lot.ewas[i].line_items.length; j++){
                        const line_item = {};
                        if(lot.ewas[i].line_items[j].id !== undefined){ line_item.id = lot.ewas[i].line_items[j].id; }
                        if(lot.ewas[i].line_items[j].amount !== undefined){ line_item.amount = lot.ewas[i].line_items[j].amount; }
                        if(lot.ewas[i].line_items[j].approved !== undefined){ line_item.approved = lot.ewas[i].line_items[j].approved; }
                        if(lot.ewas[i].line_items[j].approval_at !== undefined){ line_item.approval_at = lot.ewas[i].line_items[j].approval_at && lot.ewas[i].line_items[j].approval_at !== null ? moment(new Date(lot.ewas[i].line_items[j].approval_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                        if(lot.ewas[i].line_items[j].approval_by !== undefined){ line_item.approval_by = lot.ewas[i].line_items[j].approval_by; }
                        if(lot.ewas[i].line_items[j].completed !== undefined){ line_item.completed = lot.ewas[i].line_items[j].completed; }
                        if(lot.ewas[i].line_items[j].completed_at !== undefined){ line_item.completed_at = lot.ewas[i].line_items[j].completed_at && lot.ewas[i].line_items[j].completed_at !== null ? moment(new Date(lot.ewas[i].line_items[j].completed_at)).format('YYYY-MM-DD H:mm:ss') : null; }
                        if(lot.ewas[i].line_items[j].completed_by !== undefined){ line_item.completed_by = lot.ewas[i].line_items[j].completed_by; }
                        if(lot.ewas[i].line_items[j].ewa_code_id !== undefined){ line_item.ewa_code_id = lot.ewas[i].line_items[j].ewa_code_id; }
                        if(lot.ewas[i].line_items[j].ewa_id !== undefined){ line_item.ewa_id = lot.ewas[i].line_items[j].ewa_id; }
                        if(lot.ewas[i].line_items[j].note !== undefined){ line_item.note = lot.ewas[i].line_items[j].note; }
                        if(lot.ewas[i].line_items[j].schedule_date !== undefined){ line_item.schedule_date = lot.ewas[i].line_items[j].schedule_date && lot.ewas[i].line_items[j].schedule_date !== null ? moment(new Date(lot.ewas[i].line_items[j].schedule_date)).format('YYYY-MM-DD H:mm:ss') : null; }
                        line_items.push(line_item);
                    }
                    ewa.line_items = line_items
                }
                ewas.push(ewa);
            }
            dbObject.ewas = ewas;
        }

        return dbObject;
    }

    static async updateLot(lot, user){

        delete lot.bluestake
        if(lot.bluestake && lot.bluestake.length > 0){
            lot.bluestake[0].date = moment(lot.bluestake[0].date).format('YYYY-MM-DD H:mm:ss');
        }

        delete lot.ewas
        delete lot.value
        delete lot.label
        delete lot.lot
        // if(lot.ewas){
        //     for(let i = 0; i < lot.ewas.length; i++){
        //         lot.ewas[i].approval_at = lot.ewas[i].approval_at ? moment(lot.ewas[i].approval_at).format('YYYY-MM-DD H:mm:ss') : null;
        //         lot.ewas[i].created_at = lot.ewas[i].created_at ? moment(lot.ewas[i].created_at).format('YYYY-MM-DD H:mm:ss') : null;
        //         lot.ewas[i].modified_at = lot.ewas[i].modified_at ? moment(lot.ewas[i].modified_at).format('YYYY-MM-DD H:mm:ss') : null;
        //         lot.ewas[i].printed_at = lot.ewas[i].printed_at ? moment(lot.ewas[i].printed_at).format('YYYY-MM-DD H:mm:ss') : null;
        //
        //         for(let l = 0; l < lot.ewas[i].line_items.length; l++){
        //
        //             for(let e = 0; e < lot.ewas[i].line_items[l].crew.employees.length; e++){
        //                 lot.ewas[i].line_items[l].crew.employees[e].approval_date = lot.ewas[i].line_items[l].crew.employees[e].approval_date ? moment(lot.ewas[i].line_items[l].crew.employees[e].approval_date).format('YYYY-MM-DD H:mm:ss') : null;
        //                 delete lot.ewas[i].line_items[l].crew.crew
        //                 delete lot.ewas[i].line_items[l].crew.employees[e].employee
        //             }
        //             lot.ewas[i].line_items[l].approval_date = lot.ewas[i].line_items[l].approval_date ? moment(lot.ewas[i].line_items[l].approval_date).format('YYYY-MM-DD H:mm:ss') : null;
        //             lot.ewas[i].line_items[l].completed_date = lot.ewas[i].line_items[l].completed_date ? moment(lot.ewas[i].line_items[l].completed_date).format('YYYY-MM-DD H:mm:ss') : null;
        //             lot.ewas[i].line_items[l].schedule_date = lot.ewas[i].line_items[l].schedule_date ? moment(lot.ewas[i].line_items[l].schedule_date).format('YYYY-MM-DD H:mm:ss') : null;
        //         }
        //     }
        // }

        const updatedLot = await Auth.hitEndpoint("PATCH", "LOTS", "", lot); 

        // if(lot.ewas){
        //     for(let i = 0; i < lot.ewas.length; i++){
        //         for(let j = 0; j < lot.ewas[i].line_items.length; j++){
        //             lot.ewas[i].line_items[j].schedule_date = lot.ewas[i].line_items[j].schedule_date ? new Date(lot.ewas[i].line_items[j].schedule_date) : null;
        //         }
        //     }
        // }

        return updatedLot;
    }

    static async deleteLot(lot){
        await Auth.hitEndpoint("DELETE", "LOTS", "", lot); 
        return lot;
    }

    static sortLots(sortBy, order, lots, jobs = null, employees = null){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        if(sortBy === 'jobNumber' || sortBy === 'number') {
            return lots
        }
        else{
            return lots.sort((l1, l2) => {
                if (sortBy === "number") {
                    //     return Number.parseInt(l1.lotNumber) < Number.parseInt(l2.lotNumber) ? firstValue : secondValue;
                }
                if (sortBy === "jobNumber") {
                    // const j1 = jobs.find(jobToFind => jobToFind.id === l1.job_id);
                    // const j2 = jobs.find(jobToFind => jobToFind.id === l2.job_id);
                    //
                    // const j1Number = Number.parseInt(j1 ? j1.number : "0");
                    // const j2Number = Number.parseInt(j2 ? j2.number : "0");
                    //
                    // const l1Number = Number.parseInt(l1.number);
                    // const l2Number = Number.parseInt(l2.number);
                    //
                    // if(j1Number === j2Number){
                    //     return l1Number < l2Number ? firstValue : secondValue;
                    // }
                    //
                    // return j1Number < j2Number ? firstValue : secondValue;
                }
                if (sortBy === "ewaEmployee") {// Lots that use this should be from Lots.getExpandedLotsEWAArray(lots)
                    const l1Emp = employees.find(emp => emp.id === l1.ewas[l1.currentEWAIndex].employees[0].employee_id).first_name;
                    const l2Emp = employees.find(emp => emp.id === l2.ewas[l2.currentEWAIndex].employees[0].employee_id).first_name;
                    return l1Emp < l2Emp ? firstValue : secondValue;
                }
                if (sortBy === "ewaScheduledDate") {// Lots that use this should be from Lots.getExpandedLotsEWAArray(lots)
                    return l1.ewas[l1.currentEWAIndex].line_items[0].schedule_date < l2.ewas[l2.currentEWAIndex].line_items[0].schedule_date ? firstValue : secondValue;
                }
                if (sortBy === "ewaCreatedDate") {// Lots that use this should be from Lots.getExpandedLotsEWAArray(lots)
                    return l1.ewas[l1.currentEWAIndex].created_at < l2.ewas[l2.currentEWAIndex].created_at ? firstValue : secondValue;
                }

                return firstValue;
            });
        }
    }

    static createNewLot(){
        return {
            job_id: '',
            number: '',
            phase: '',
            address: '',
            plan_number: '',
            active: 1,
            notes: '',
            phases: [
                {phase_id: 1, starts_status: ''},
                {phase_id: 2, starts_status: ''},
                {phase_id: 3, starts_status: ''},
                {phase_id: 4, starts_status: ''},
                {phase_id: 5, starts_status: ''},
                {phase_id: 6, starts_status: ''},
                {phase_id: 7, starts_status: ''},
                {phase_id: 8, starts_status: ''}
            ]
        };
    }

    // Below is edit functions for individual lot data *******************************
    static async checkLotForErrors(lot, lots = []){
        if(lot.job_id === null){return "ERROR_NO_JOB_NUMBER";}
        const currentJob = await Auth.hitEndpointNew("GET", `jobs/${lot.job_id}`);
        if(!currentJob){return "ERROR_JOB_DOES_NOT_EXIST";}

        if(lot.number === ''){return "ERROR_NO_LOT_NUMBER";}

        const dupLotsFromDB = (await Lots.loadLots({job_id: lot.job_id, "lot.number": lot.number})).filter(lotToFilter => lotToFilter.phase === lot.phase);
        const dupLotsFromLots = lots.filter(lotToFilter => lotToFilter.job_id === lot.job_id && lotToFilter.number === lot.number && lotToFilter.phase === lot.phase);
        const dupLots = [...dupLotsFromDB, ...dupLotsFromLots];
        if((lot.id && dupLots.length > 1) || (!lot.id && dupLots.length > 0)){return "ERROR_DUPLICATE_LOT_NUMBER";}

        return "SUCCESS";
    }
    // *******************************************************************************

    // Functions that return constant values *****************************************
    static getReleasePhasePhases(){
        // id is the index number or none phase name
        return [
            {id: "5", display: "RFGT"},
            {id: "0", display: "Concrete Foundation"},
            {id: "2", display: "Plumbing Soil"},
            {id: "3", display: "Plumbing Top Out"},
            {id: "6", display: "Masonry"},
            {id: "1", display: "Concrete Driveway"},
            {id: "7", display: "Paver"},
            {id: "4", display: "Plumbing Trim"}
        ]
    }

    static getReleasePhaseStatuses(){
        return [
            {id: "Not Released", display: "Not Released"},
            {id: "Released", display: "Released"},
            {id: "Received", display: "Received"},
            {id: "Need Measurements", display: "Need Measurements"},
            {id: "Verification Needed", display: "Verification Needed"},
            {id: "Need Information", display: "Need Information"},
            {id: "Save Documents", display: "Save Documents"},
            {id: "Processed", display: "Processed"},
        ]
    }

    static getReleasePhasePhasesWithBlueStake(){
        // id is the index number or none phase name
        return [
            {id: "Blue Stake", display: "Blue Stake"},
            {id: "6", display: "RFGT"},
            {id: "1", display: "Concrete Foundation"},
            {id: "3", display: "Plumbing Soil"},
            {id: "4", display: "Plumbing Top Out"},
            {id: "7", display: "Masonry"},
            {id: "2", display: "Concrete Driveway"},
            {id: "8", display: "Paver"},
            {id: "5", display: "Plumbing Trim"}
        ]
    }

    static getBlueStakeStatuses(){
        return [
            {id: "Not Processed", display: "Not Processed"},
            {id: "Released/Not Processed", display: "Released/Not Processed"},
            {id: "Processed", display: "Processed"},
        ]
    }

    static getNumberOfQAQuestions(){
        return 17;
    }
    // *******************************************************************************





    static didPassCameraQA(lot){
        for(let i = 1; i <= this.getNumberOfQAQuestions(); i++){
            if(lot.plumbing.cameraQAForm[`q${i}`].length === 0){
                return false;
            }
            if(!lot.plumbing.cameraQAForm[`q${i}`].at(-1).result){
                return false;
            }
        }
        return true;
    }


    // ******************** Payouts ********************
    static addPayoutObjectToLots(code, lots){
        for(let i = 0; i < lots.length; i++){
            switch(code){
                case "PL4.2":
                    if(!lots[i].payouts.find(payout => payout.code === code)){
                        lots[i].payouts.push({
                            code: "PL4.2",
                            status: false,
                            crewNumber: "",
                            crewMembers: [],
                            editedBy: {
                                name: "",
                                date: null
                            }
                        })
                    }
                    break;
                default: break;
            }
        }

        return lots;
    }


    // TODO Camera QA
    static convertCameraQAQuestionOrCommentsDates(questionOrCommets){
        questionOrCommets.forEach(answer => {
            answer.date = new Date(answer.date);
        })
    }

    // Release Phase - Phase Tasks
    static createNewPhaseTask(user){
        return {
            scheduledDate: "",
            completedDate: "",
            crew: "",
            status: "",
            notes: "",
            editedBy: user,
        }
    }

    static createPhaseTaskFromDataString(dataString){
        const task = dataString.split("^");
        return {
            scheduledDate: task[0] ? new Date(task[0]) : '',
            completedDate: task[3] ? new Date(task[3]) : '',
            crew: task[1],
            status: task[2],
            notes: task[4],
            editedBy: task[5],
        }
    }

    static taskArrayToStringArrays(taskArray){
        const stringArray = [];
        for(let i = 0; i < taskArray.length; i++){
            if(!taskArray[i].scheduledDate && !taskArray[i].crew && !taskArray[i].status && !taskArray[i].completedDate){continue;}
            const scheduledDate = Globals.formatDateToDisplay(taskArray[i].scheduledDate);
            const completedDate = Globals.formatDateToDisplay(taskArray[i].completedDate);
            stringArray.push(`${scheduledDate}^${taskArray[i].crew}^${taskArray[i].status}^${completedDate}^${taskArray[i].notes}^${taskArray[i].editedBy}`)
        }
        return stringArray;
    }

    static async getSelectedLotIds(lots){
        let lotIds = []
        if (lots !== undefined && lots !== null && lots.length > 0) {
            lots.forEach(s => {
                lotIds.push(s.value)
            })
        }
        return lotIds
    }
}

export default Lots;
