import { useEffect, useMemo, useState} from 'react';
import dayjs from "dayjs";
import Auth from '../authentication/Auth.js';
import { PayWeekPicker } from '../utilities/PayWeekPicker.js';
import {MaterialReactTable} from "material-react-table";
import Button  from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Switch } from '@mui/material';
import Select, { createFilter } from "react-select";
import { filterConfig } from '../utilities/FilterConfig.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Can from '../authentication/Can';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddchartIcon from '@mui/icons-material/Addchart';
import XLSX from 'xlsx';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const PayrollExport = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [startDate, setStartDate] = useState(dayjs(new Date()).weekday() === 6 ? dayjs(new Date()) : dayjs(new Date()).weekday(0).add(-1, 'd'));
    const [endDate, setEndDate] = useState(dayjs(new Date()).weekday() === 6 ? dayjs(new Date()).add(6, 'd') : dayjs(new Date()).weekday(0).add(5, 'd'));

    const [filters, setFilters] = useState({
        phases: [],
        crews: [],
        employees: [],
        includeDeletedEmployees: false
    });

    const [loadingButton, setLoadingButton] = useState({
        text: 'Get Employees',
        disabled: false
    })

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const [phases, setPhases] = useState([]);

    const [users, setUsers] = useState([]);
    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([]);

    // const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [payrollExport, setPayrollExport] = useState([]);

    const canExportPayroll = Can.isAuthorized('payrollExport', 'WRITE');

    const columns = useMemo(
        () => [
            {
                accessorKey: 'bankAccount',
                header: 'Bank Account',
                size: 200,
                enableEditing: false
            },
            {
                accessorKey: 'checkNumber',
                header: 'Check #',
                size: 150,
                enableEditing: false
            },
            {
                accessorKey: 'transactionDate',
                header: 'Transaction Date',
                size: 200,
                enableEditing: false
            },
            {
                accessorKey: 'payee',
                header: 'Payee',
                size: 300,
                enableEditing: false
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                size: 150,
                enableEditing: false
            },
            {
                accessorKey: 'memoEmployee',
                header: 'Memo Employee',
                size: 400,
                enableEditing: false
            },
            {
                accessorKey: 'item',
                header: 'Item',
                size: 250,
                enableEditing: false
            },
            {
                accessorKey: 'customerJob',
                header: 'Customer Job',
                size: 400,
                enableEditing: false
            },
            {
                accessorKey: 'billable',
                header: 'Billable',
                size: 400,
                enableEditing: false
            },
            {
                accessorKey: 'pay',
                header: 'Pay',
                size: 400,
                enableEditing: false
            },
        ],
        []
    );

//     useEffect(() => {
//         const updateLaborHours = async () => {
//             // Update database
//             const dbObj = {
//                 id: editedEmployeeLaborHours.id,
//                 employee_id: editedEmployeeLaborHours.employee_id,
//                 day: editedEmployeeLaborHours.day.format('YYYY-MM-DD H:mm:ss'),
//                 hours: editedEmployeeLaborHours.hours,
//                 modified_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss'),
//                 modified_by: await Auth.getUserId(),
//             }
//             await Auth.hitEndpointNew('POST', 'employee/labor-hours', '', dbObj);

//             // Get updated employee from database
//             const updatedEmployee = (await Auth.hitEndpointNew('GET', 'employees-new', `jsonString=employee.id=${editedEmployeeLaborHours.employee_id}`))[0];
//             const start = filters.date.hour(0).minute(0).second(0);
//             const end = filters.date.hour(23).minute(59).second(59);
//             const tempLaborHours = updatedEmployee.labor_hours.find(lh => {
//                 const day = dayjs(lh.day);
//                 return day >= start && day <= end;
//             });
//             updatedEmployee.laborHours = {
//                 id: tempLaborHours ? tempLaborHours.id : null,
//                 employee_id: updatedEmployee.id,
//                 day: tempLaborHours ? dayjs(tempLaborHours.day) : filters.date,
//                 hours: tempLaborHours ? tempLaborHours.hours : 0,
//                 modified_by: tempLaborHours ? tempLaborHours.modified_by : 0,
//                 modified_at: tempLaborHours ? tempLaborHours.modified_at : null,
//             }
            
//             // Update employees list
//             let index = employees.findIndex(e => parseInt(e.id) === parseInt(updatedEmployee.id));
//             employees[index] = updatedEmployee;
//             setEmployees([...employees]);
            
//             // Update selectedEmployees list
//             index = selectedEmployees.findIndex(se => parseInt(se.id) === parseInt(updatedEmployee.id));
//             selectedEmployees[index] = updatedEmployee;
//             setSelectedEmployees([...selectedEmployees]);

//             setEditedEmployeeLaborHours(null);
//         }
        
//         if(editedEmployeeLaborHours){
//             updateLaborHours();
//         }
//     }, [editedEmployeeLaborHours, selectedEmployees, employees, filters])

    const loadData = async () => {
        setDidLoadData(true);
        setLoadingButton({
            text: 'Loading Page...',
            disabled: true
        });

        const tempPhases = await Auth.hitEndpointNew('GET', 'phases');
        setPhases(tempPhases.map(p => {
            return {
                ...p,
                value: p.id,
                label: p.stat_name
            }
        }).sort((p1, p2) => parseInt(p1.stats_order_by) - parseInt(p2.stats_order_by)));

        const tempUsers = await Auth.hitEndpointNew('GET', 'users');
        setUsers([...tempUsers]);

        const tempCrews = await Auth.hitEndpointNew("GET", "crews-new");
        setCrews(tempCrews.filter(c => parseInt(c.active) === 1).map(c => {
            const crewLeader = c.members && c.members.length > 0 ? c.members.find(m => m.position === 'Leader') : null;
            return {
                ...c,
                value: c.id,
                label: crewLeader && crewLeader.employee ? `${c.number} - ${crewLeader.employee.first_name} ${crewLeader.employee.last_name}` : c.number
            }
        }).sort((c1, c2) => {
            return parseInt(c1.number) < parseInt(c2.number) ? -1 : 1;
        }));

        const tempEmployees = await Auth.hitEndpointNew('GET', 'employees-new');
        setEmployees(tempEmployees.filter(e => {
            if(!filters.includeDeletedEmployees){
                return parseInt(e.active) === 1;
            }
            return true;
        }).map(e => {
            return {
                ...e,
                value: e.id,
                label: `${e.first_name} ${e.last_name}`
            }
        }))

        setLoadingButton({
            text: 'Get Payroll Export',
            disabled: false
        });
    }

    if(!didLoadData){
        loadData();
    }

    const handleLoadPayrollExport = async () => {
        if(startDate === null){
            setError({
                type: 'NO_START_DATE_SELECTED',
                message: 'Please select a start date.'
            })
            return;
        }
        if(filters.phases.length === 0){
            setError({
                type: 'NO_PHASES_SELECTED',
                message: 'Please select at least 1 phase'
            })
            return;
        }
        setError({
            type: '',
            message: ''
        })

        let queryStr = `queryString=schedule.completed_date between '${startDate.format('YYYY-MM-DD')}' and '${endDate.format('YYYY-MM-DD')}'`;
        for(let i = 0; i < filters.phases.length; i++){
            if(i === 0){
                queryStr += ' and (';
            }
            queryStr += `phase.id=${filters.phases[i].id}`
            if(i + 1 === filters.phases.length){
                queryStr += ')';
            }
            else{
                queryStr += ' or ';
            }
        }
        const tempPayroll = await Auth.hitEndpointNew('GET', 'payroll/export', queryStr);
        console.log(tempPayroll);

        // TODO create payroll object for both MRT and xlsx export
        const errorValue = '#ERR';
        const tempPayrollExport = [];
        for(let i = 0; i < tempPayroll.length; i++){
            // TODO Switch this to payroll
            // TODO Payroll is divided into individual employees
            for(let k = 0; k < tempPayroll[i].crew.employees.length; k++){
                tempPayrollExport.push({
                    bankAccount: 'JOB COST HOLD', // Always this value
                    checkNumber: tempPayroll[i].crew.crew.number + k.toString().padStart(2, '0'),
                    transactionDate: endDate.format('MM-DD-YYYY'),
                    payee: `zPay-${tempPayroll[i].crew.employees[k].employee.first_name} ${tempPayroll[i].crew.employees[k].employee.last_name}`,
                    amount: errorValue,
                    memoEmployee: `${tempPayroll[i].crew.employees[k].employee.first_name} ${tempPayroll[i].crew.employees[k].employee.last_name}`,
                    item: tempPayroll[i].task.payroll_code ? tempPayroll[i].task.payroll_code : errorValue,
                    customerJob: `NEEDS MAPPING - ${tempPayroll[i].lot.job.builder.name}:${tempPayroll[i].lot.job.number}-${tempPayroll[i].lot.job.project_name}:${tempPayroll[i].lot.job.number}-${tempPayroll[i].lot.number}`,
                    billable: tempPayroll[i].task.billable === 1 ? 'Y' : 'N',
                    pay: errorValue
                })
            }
        }
        setPayrollExport(tempPayrollExport);
    }

    const exportPayrollToXLSX = () => {
        for(let i = 0; i < payrollExport.length; i++){
            if(payrollExport[i].bankAccount === '#ERR' || payrollExport[i].checkNumber === '#ERR' || payrollExport[i].transactionDate === '#ERR'
                || payrollExport[i].payee === '#ERR' || payrollExport[i].amount === '#ERR' || payrollExport[i].memoEmployee === '#ERR'
                || payrollExport[i].item === '#ERR' || payrollExport[i].customerJob === '#ERR' || payrollExport[i].billable === '#ERR'
                || payrollExport[i].pay === '#ERR'){
                    setError({
                        type: 'PAYROLL_ERRORS',
                        message: 'Please fix the errors in payroll before exporting the payroll xlsx file'
                    });
                    return;
                }
        }

        const exportData = [];
        for(let i = 0; i < payrollExport.length; i++){
            exportData.push({
                "Bank Account": payrollExport.bankAccount,
                "Chk No.": payrollExport.checkNumber,
                "Transaction Date": payrollExport.transactionDate,
                "Payee": payrollExport.payee,
                "Amount": payrollExport.amount,
                "Memo Employee": payrollExport.memoEmployee,
                "Item": payrollExport.item,
                "Customer Job": payrollExport.customerJob,
                "Billable": payrollExport.billable,
                "Pay": payrollExport.pay,
            });
        }
        
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lots');
        XLSX.writeFile(workbook, './PayrollExport.xlsx'); // TODO is there a special name convention
    }

    return (
        <Box
            sx={{
                display: 'grid',
                margin: '10px 0 0 10px'
            }}
        >
            <Box 
                sx={{ 
                    display: 'grid',
                    width: '800px',
                    border: '2px solid rgb(220, 220, 220)',
                    borderRadius: '3px',
                    marginBottom: '10px',
                    padding: '0 30px'
                }}
            >
                <Box sx={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    padding: '8px 0'}}
                >Payroll Exporting Fields</Box>
                <PayWeekPicker
                    start={startDate}
                    end={endDate}
                    setStart={setStartDate}
                    setEnd={setEndDate}
                />
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '100%',
                                justifySelf: 'left',
                                zIndex: '49',
                            }
                        },
                        control: styles => {
                            return {
                                ...styles,
                                backgroundColor: error === "NO_PHASES_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                            }
                        }
                    }}
                    placeholder="Select Phases"
                    value={filters.phases}
                    onChange={(phases) => setFilters({...filters, phases: phases})}
                    options={phases}
                    isMulti
                    isClearable
                    isSearchable
                    filterOption={createFilter(filterConfig(true, true, true, true))}
                />
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '46% 8% 46%',
                        margin: '10px 0',
                    }}
                >
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '100%',
                                    justifySelf: 'left',
                                    zIndex: '48',
                                }
                            },
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "NO_CREW_OR_EMPLOYEE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            }
                        }}
                        placeholder="Select Crew"
                        value={filters.crews}
                        onChange={(crews) => setFilters({...filters, crews: crews})}
                        options={crews}
                        isMulti
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig(true, true, true, true))}
                    />
                    <Box
                        sx={{
                            justifySelf: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        and/or
                    </Box>
                    <Select
                        styles={{
                            container: styles => {
                                return {
                                    ...styles,
                                    width: '100%',
                                    justifySelf: 'right',
                                    zIndex: '47',
                                }
                            },
                            control: styles => {
                                return {
                                    ...styles,
                                    backgroundColor: error === "NO_CREW_OR_EMPLOYEE_SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                }
                            }
                        }}
                        placeholder="Select Employee"
                        value={filters.employees}
                        onChange={(employees) => setFilters({...filters, employees: employees})}
                        options={employees}
                        isMulti
                        isClearable
                        isSearchable
                        filterOption={createFilter(filterConfig(false, true, true, true))}
                    />
                </Box>
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        value={filters.includeDeletedEmployees}
                        onChange={(e) => setFilters({...filters, includeDeletedEmployees: e.target.checked})}/>} 
                    label="Include Deleted Employees" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    disabled={loadingButton.disabled}
                    variant='contained'
                    onClick={handleLoadPayrollExport}
                >{loadingButton.text}</Button>
            </Box>
            <Box 
                sx={{
                    margin: '10px',
                    width: '1900px'
                }
            }>
                <MaterialReactTable
                    muiTableContainerProps={{ sx: { maxWidth: '1900px', maxHeight: '500px' } }}
                    columns={columns}
                    data={payrollExport}
                    enableSorting
                    layoutMode="grid-no-grow"
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <Box>
                                <Tooltip title="Export To XLSX">
                                    <IconButton onClick={() => exportPayrollToXLSX()}>
                                        <AddchartIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        );
                    }}
                    muiTableBodyCellProps={({ cell, row, table }) => {
                        return {
                            sx: {
                                backgroundColor: cell.getValue() !== '#ERR' ? 'white' : 'rgb(255, 150, 150)'
                            },
                        }
                    }}
                    initialState={{
                        pagination: {pageSize: 100, pageIndex: 0},
                    }}
                    enableStickyHeader
                    // editDisplayMode='cell'
                    // enableEditing
                    // enableCellActions
                    // getRowId={(row) => row.id}
                />
            </Box>
            {error.type !== '' &&
            <Dialog open={error.type !== ''}>
                <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                <DialogContent 
                    sx={{backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                    <Button 
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => {
                            setError({
                                type: '',
                                message: ''
                            })
                        }}
                    >OK</Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
}

export default PayrollExport;
