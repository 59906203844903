import { useState, useMemo } from 'react';
import XLSX from 'xlsx';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {MaterialReactTable} from "material-react-table";

const LoadLotsFromXLSX = ({displayMessageWithTimer, jobs, loadLotsFromXLSX, setLoadLotsFromXLSX}) => {
    const [newLots, setNewLots] = useState([]);
    const [dragNDropText, setDragNDropText] = useState('Drag File Here');
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row.job !== null ? row.job.number : '',
            header: 'Job #',
            size: 80
        },
        {
            accessorKey: 'number',
            header: 'Lot #',
            size: 80
        },
        {
            accessorKey: 'phase',
            header: 'Lot Phase',
            size: 80
        },
        {
            accessorKey: 'address',
            header: 'Address',
            size: 200
        },
        {
            accessorKey: 'plan_number',
            header: 'Plan ID',
            size: 150
        },
        {
            accessorKey: 'errors',
            header: 'Errors',
            size: 400,
            Cell: ({renderedCellValue}) => {
                return (
                    <Grid>
                        {renderedCellValue.map(m => {
                            return (
                                <Box>{`${m.type} - ${m.message}`}</Box>
                            )
                        })}
                    </Grid>
                )
            }
        },
    ], []);

    const processLoadedLots = async (lotData) => {
        const tempNewLots = lotData.map(ld => {
            const job = jobs.find(j => j.number === ld.JobNumber);
            return {
                job: job !== undefined ? job : null,
                number: ld.LotNumber !== undefined ? formatLotNumber(ld.LotNumber) : '',
                phase: ld.PhaseID !== undefined ? ld.PhaseID : '',
                address: ld.Address !== undefined ? ld.Address : '',
                plan_number: ld.PlanNumber !== undefined ? ld.PlanNumber : '',
                active: true,
                notes: '',
                errors: []
            }
        })
        for(let i = 0; i < tempNewLots.length; i++){
            if(tempNewLots[i].job === null){
                tempNewLots[i].errors.push({
                    type: 'JOB DOES NOT EXIST',
                    message: 'Please check that JobNumber on the worksheet is a valid job number.'
                });
                setDisableSaveButton(true);
            }
            if(tempNewLots[i].number === ''){
                tempNewLots[i].errors.push({
                    type: 'NO LOT NUMBER ENTERED',
                    message: 'Please check that LotNumber on the worksheet is not blank.'
                });
                setDisableSaveButton(true);
            }
            if(tempNewLots[i].job !== null && tempNewLots[i].number !== ''){
                for(let j = 0; j < tempNewLots.length; j++){
                    if(tempNewLots[j].job === null){
                        continue;
                    }
                    if(j !== i){
                        if(parseInt(tempNewLots[i].job.id) === parseInt(tempNewLots[j].job.id)
                            && tempNewLots[i].number === tempNewLots[j].number
                            && tempNewLots[i].phase === tempNewLots[j].phase){
                            tempNewLots[i].errors.push({
                                type: 'REPEAT LOT ON WORKSHEET',
                                message: 'Please check that lots on the worksheet are not included multiple times.'
                            }); 
                            setDisableSaveButton(true);
                        }
                    }
                }
                const queryString = `lot.job_id = ${tempNewLots[i].job.id} and lot.number = '${tempNewLots[i].number}' and lot.phase = '${tempNewLots[i].phase}'`;
                const dupLotsFromDB = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=${queryString}`);
                if(dupLotsFromDB.length > 0){
                    tempNewLots[i].errors.push({
                        type: 'REPEAT LOT IN DATABASE',
                        message: 'Please check that LotNumber on the worksheet is not already in database.'
                    });
                    setDisableSaveButton(true);
                }
            }
        }

        setNewLots([...tempNewLots]);
    }

    const formatLotNumber = (lot) => {
        let rtn = ''
        switch(lot.toString().length){
            case 1:
                rtn = `000${lot}`
                break;
            case 2:
                rtn = `00${lot}`
                break;
            case 3:
                rtn = `0${lot}`
                break;
            default:
                rtn = lot
                break;
        }
        return rtn
    }

    const handleOnDrop = (e) => {
        e.preventDefault();
        setDragNDropText('Processing Lots...');
        const file = e.dataTransfer.files[0];
        const fileType = file.type;
        const validFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if(validFileType === fileType){
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "array"});
                workbook.SheetNames.forEach((sheetName) => {
                    const lotData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    processLoadedLots(lotData);
                });
            }
            fileReader.readAsArrayBuffer(file);
            setDragNDropText('Drag File Here');
        }
        else{
            setDragNDropText('Only .XLSX files in valid format.');
            setTimeout(() => {
                setDragNDropText('Drag File Here');
            }, 5000);
        }
        setDisableSaveButton(false);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragNDropText('Drop File');
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragNDropText('Drag File Here');
    }

    const closeWithSave = async () => {
        if(newLots.length === 0){
            closeNoSave();
            return;
        }
        const dbObjects = newLots.map(l => {
            return {
                job_id: l.job.id,
                number: l.number,
                phase: l.phase,
                address: l.address,
                plan_number: l.plan_number,
                active: true,
                notes: '',
            }
        });
        await Auth.hitEndpointNew("POST", "lots", "", dbObjects);

        let lotsAddedMessage = dbObjects.length === 1  ? '1 Lot Created.  Refresh filters to see added lots.' 
            : `${dbObjects.length} Lots Created. Refresh filters to see added lots`;
        displayMessageWithTimer(lotsAddedMessage, 'green', 5000);
        setLoadLotsFromXLSX(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Lots Created', 'green', 5000);
        setLoadLotsFromXLSX(false);
    }

    return (
        <Dialog open={loadLotsFromXLSX} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle style={{fontWeight: 'bold', borderBottom: "2px solid black"}}>Drag N Drop Lots</DialogTitle>
            <DialogContent>
                <Box 
                    sx={{
                        display: 'grid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '500px',
                        height: '100px',
                        margin: '10px auto',
                        border: '4px dashed rgb(180, 180, 180)',
                        fontWeight: 'bold'
                    }}
                    onDrop={handleOnDrop} 
                    onDragLeave={handleDragLeave} 
                    onDragOver={handleDragOver}
                >{dragNDropText}</Box>
                <MaterialReactTable
                    columns={columns}
                    data={newLots}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={true}
                    layoutMode='semantic'
                    muiTableHeadCellProps={() => {
                        return {
                            align: 'center',
                            sx: {
                                padding: '0',
                                height: '35px',
                                backgroundColor: 'rgb(0, 150, 255)',
                            }
                        }
                    }}
                    muiTableBodyCellProps={({row}) => {
                        let bgColor = row.index % 2 === 0 ? 'rgb(137, 207, 240)' : 'rgb(125, 249, 255)';
                        bgColor = row.original.errors.length > 0 ? 'rgb(255, 150, 150)': bgColor;
                        return {
                            align: 'center',
                            sx: {
                                height: '30px',
                                padding: '0',
                                backgroundColor: bgColor
                            }
                        }
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return {
                            hover: false,
                        }
                    }}
                    state={{pagination}}
                    onPaginationChange={(page) => {
                        if(!bypassPagination){
                            setPagination(page);
                        }
                        setBypassPagination(false);
                    }}
                    initialState={{
                        showColumnFilters: false,
                        pagination: pagination,
                        density: 'compact',
                        expanded: false,
                        showGlobalFilter: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' disabled={disableSaveButton} onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default LoadLotsFromXLSX;
