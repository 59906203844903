
import {useMemo, useState} from "react";

import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {MaterialReactTable} from "material-react-table";
import {Switch, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import EvenflowGridContainer from "./styles/EvenflowGridContainer";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Check} from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, {createFilter} from "react-select";
import {filterConfig} from "../utilities/FilterConfig";
import Button from "@mui/material/Button";

const EvenflowGrid = ({selectedBuilder, crews, template, evenflow, selectedTab, setSelectedTab}) => {


    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })

    const [open, setOpen] = useState (false)
    const [actualDate, setActualDate] = useState(null)
    const [isComplete, setIsComplete] = useState(false)
    const [company, setCompany] = useState(null)
    const [crew, setCrew] = []
    const [selectedCrew, setSelectedCrew] = useState(null)

    const handleOpen = (row, taskId, taskName) => {

        let actual = null
        let scheduleId = null

        if(row[taskName] !== undefined){
            actual = row[taskName].actual
            scheduleId = row[taskName].schedule_id
            setCompany(row[taskName].company)
        }
        let lot = row.lot

        let scheduleRow = {}
        if(scheduleId !== null){
        }

        setActualDate(dayjs(actual))
        setOpen(true)
    }
    const handleClose = () => {
        setActualDate(null)
        setOpen(false)
    }
    const handleDateChanged = (date, row) => {

    }

    const Item = styled(Paper)(({ thm, bgcolor}) => {
    return(
        {
            width: '100%',
            fontSize: '10pt',
            backgroundColor: bgcolor,
            textAlign: 'center',
            color: '#1A2027',
            borderRadius: '0',
            padding: '0pt'
        })})

    const AvgItem = styled(Paper)(({ thm, bgcolor}) => {
        return(
            {
                width: '50%',
                fontSize: '10pt',
                backgroundColor: bgcolor,
                textAlign: 'center',
                color: '#1A2027',
                borderRadius: '0',
                padding: '0pt'
            })})

    const evenflowColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => {
                        return(row.lot)
                    },
                    header: 'Lot',
                    id: 'lot',
                    size: 150,
                },
                {
                    accessorFn: (row) => {
                        return (
                            <Grid key={`lot-cell-grid-${row.index}-${row.index}-schedule-actual`} container columns={{xs: 4, md: 12}} className={`labels-grid`}>
                                <Item key={`lot-cell-grid-item-due-${row.index}-${row.index}-schedule`} bgcolor={'#FFFFFF'} className={'schedule'}>{'Sched'}</Item>
                                <Item key={`lot-cell-grid-item-scheduled-${row.index}-${row.index}`} bgcolor={'#FFFFFF'} className={'actual'}>{'Actual'}</Item>
                            </Grid>
                        )
                    },
                    header: '',
                    id: 'schedule_actual',
                    size: 100,
                },
            ]
            if(template !== undefined){
                for (let t = 0; t < template.length; t++) {
                    let item = template[t]
                    let taskName = (`${item.task.code !== null ? item.task.code : ''} ${item.task.name}`).trim()
                    let taskId = item.task.id
                    let calcDaysStop = item.calc_days_stop
                    let calcDaysReset = item.calc_days_reset
                    let interactive = item.interactive === 1
                    columns.push(
                        {
                            accessorFn: (row) => {
                                let schedule = row[taskName] === undefined || row[taskName].schedule === null ? '' : row[taskName].schedule
                                let actual = row[taskName] === undefined || row[taskName].actual === null ? schedule === '' ? '' : ' - ' : row[taskName].actual
                                let isComplete = row[taskName] === undefined || row[taskName].is_complete === null ? false : row[taskName].is_complete
                                let bgColor = getBgColor(row[taskName], false, taskName)
                                let isAvg = row[taskName] === undefined ? false : row[taskName].is_avg
                                return (
                                    <Grid key={`lot-cell-grid-${row.index}-${row.index}`} container columns={{xs: 4, md: 12}} className={row[taskName] === undefined ? '' : `dates-grid`}>
                                        <Item key={`lot-cell-grid-item-due-${row.index}-${row.index}`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{schedule}</Item>
                                        <Item key={`lot-cell-grid-item-scheduled-${row.index}-${row.index}`} bgcolor={bgColor} className={`actual ${isComplete ? 'actual-complete' : ''} ${interactive ? 'interactive' : ''}`}
                                              onClick={(e) => {
                                                  if (interactive) {
                                                      handleOpen(row, taskId, taskName)
                                                  }
                                              }}>{actual} {isComplete ? <span className={'complete-indicator'}>&#x2713;</span> : ''}</Item>
                                    </Grid>
                                )
                            },
                            id: taskName,
                            header: taskName,
                            enableEditing: false,
                            size: 200,
                        })
                    if(calcDaysStop) {
                        columns.push(
                            {
                                accessorFn: (row) => {
                                    let bgColor = getBgColor(row[item.calc_days_label], true, item.calc_days_label)
                                    let sDays = row[item.calc_days_label] !== undefined ? row[item.calc_days_label].schedule_days : ' - '
                                    let sLabel = row[item.calc_days_label] !== undefined ? row[item.calc_days_label].schedule : ' - '
                                    let aDays = row[item.calc_days_label] !== undefined ? row[item.calc_days_label].actual_days === null ? ' - ' : row[item.calc_days_label].actual_days : ' - '
                                    let isAvg = row[item.calc_days_label] === undefined ? false : row[item.calc_days_label].is_avg
                                    let dDays = isAvg ? row[item.calc_days_label] !== undefined && row[item.calc_days_label].delay_days > 0 ? row[item.calc_days_label].delay_days : '' : ''
                                    return (
                                        <Grid key={`lot-cell-grid-${row.index}-${row.index}-totals`} container columns={{xs: 4, md: 12}} className={row[item.calc_days_label] === undefined ? '' : `calc-grid`}>
                                            <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-schedule`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{sLabel === 'Avg' ? sLabel : sDays}</AvgItem>
                                            <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-schedule-delay`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{sLabel === 'Avg' && dDays !== '' ? 'Avg Delay' : ''}</AvgItem>
                                            <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-actual`} bgcolor={bgColor} className={'actual'}>{aDays}</AvgItem>
                                            <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-actual-delay`} bgcolor={bgColor} sx={{color: 'red'}} className={'actual'}>{dDays}</AvgItem>
                                        </Grid>
                                    )
                                },
                                id: item.calc_days_label,
                                header: item.calc_days_label,
                                enableEditing: false,
                                size: 200,
                                hidden: true,
                            })
                    }
                }
            }
            return columns
        }
    );


    const getBgColor = (item, isTotal=false, label = '') => {
        let bgColor = '#FFFFFF'
        if (item === undefined || (item.schedule === null && !item.is_avg)){
            bgColor = '#808080'
        }
        else if (!item.is_avg && (item.actual !== null || isTotal)) {
            if(item.actual_days > 0) {
                if (item.actual_days <= item.schedule_days) {
                    bgColor = '#71D9E2'
                } else {
                    bgColor = '#FFC0CB'
                }
            }
        }
        return bgColor
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleCrewChange = (selection) =>{
        setSelectedCrew(selection)
    }

    const handleSaveEdit = (e) =>{
        setOpen(false)
    }

    return (
        <EvenflowGridContainer>
            {(evenflow === undefined || evenflow.jobs.length === 0) &&
                <span>No Data To Display</span>
            }
            {evenflow !== undefined &&
                <TabContext value={selectedTab}>
                    <TabList
                        scrollButtons
                        allowScrollButtonsMobile
                        onChange={handleTabChange}
                        variant="scrollable"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {evenflow.jobs.map((row, index) => {
                            return (
                                <Tab key={`job-tab-${index}`} className={'tab-active tab-job'} label={row.job}
                                     value={index.toString()}
                                />
                            )
                        })}
                    </TabList>
                    {evenflow.jobs.map((row, index) => {
                        return (
                            <TabPanel key={`job-panel-${index}`} value={index.toString()}>
                                <div key={`job-panel-div-${index}`} style={{marginTop: "0px"}}>
                                    <MaterialReactTable
                                        data={row.lots}
                                        columns={evenflowColumns}
                                        enableStickyHeader
                                        rowPinningDisplayMode={'top'}
                                        keepPinnedRows={false}
                                        enableColumnFilters={false}
                                        state={{pagination}}
                                        onPaginationChange={(page) => {
                                            if(!bypassPagination){
                                                setPagination(page);
                                            }
                                            setBypassPagination(false);
                                        }}
                                        muiTableContainerProps={{ sx: { maxHeight: '700px', maxWidth: '100vw' } }}
                                        muiTableHeadCellProps={({column, table}) => {
                                           let len = table.getRowModel().rows.filter(x => x.original[`has_${column.id}`]).length
                                            return({
                                                sx: {
                                                    backgroundColor: column.id === 'lot' || column.id === 'schedule_actual' || column.id.includes('Total') || len > 0 ? '#FFFFFF' : '#808080',
                                                },
                                                className: column.id === 'lot' ? 'lot-column' : ''
                                            })}}
                                        muiTableBodyCellProps={({cell, row}) => {
                                            let bgColor = cell.column.id === 'lot' || cell.column.id === 'schedule_actual' || cell.column.id.includes('Total') ?
                                                '#FFFFFF' : getBgColor(row.original[cell.column.id], false, cell.column.id)
                                            return({
                                            sx: {
                                                height: 10,
                                                fontStyle: 'italic',
                                                backgroundColor: bgColor,
                                                borderBottom: 'solid 2q',
                                                borderTop: 'solid 2q',
                                                borderLeft: 'solid 1q',
                                                borderRight: 'solid 1q',
                                                paddingBottom: '1px',
                                                contentAlign: 'center',
                                                textAlign: 'center',
                                            }
                                        })}}
                                        initialState={{
                                            showColumnFilters: false,
                                            pagination: pagination,
                                            columnPinning: {
                                                left: ['lot', 'schedule_actual']
                                            },
                                            density: 'compact',
                                            expanded: false,
                                            showGlobalFilter: true,
                                        }}
                                    />
                                </div>
                            </TabPanel>
                        )
                    })}
                </TabContext>
            }

            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>Change The Date</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className={"actual-date"}
                                    onChange={(e) => handleDateChanged(e)}
                                    defaultValue={actualDate}
                                    selected={actualDate}

                        />
                    </LocalizationProvider>
                    <FormControlLabel
                        control={<Switch id={"include=deleted-lots"}
                                         checked={isComplete}
                                         onChange={(e) => setIsComplete(e.target.checked)}/>}
                        label="Mark as Complete"
                    />
                    <Select
                        id="crew-select"
                        className={"crew-select"}
                        label="Select A Crew"
                        required={true}
                        onChange={async (e) => await handleCrewChange(e)}
                        options={crews.filter(x => x.scope !== null && x.scope.company === company)}
                        isClearable
                        isSearchable
                        isMulti={true}
                        value={selectedCrew}
                        filterOption={createFilter(filterConfig)}
                        placeholder={"Select a Crew"}
                    />
                    <Button onClick={(e) => handleSaveEdit(e)}>Save</Button>
                </DialogContent>
            </Dialog>

        </EvenflowGridContainer>
    )
}

export default EvenflowGrid;
